import { useQuery } from "@tanstack/react-query";

import { getSignableDocuments } from "../services/firestore";

export const useGetSignableDocuments = (clinicId?: string) => {
  // Parent Portal doesn't use TS-Rest, so we'll just wrap the query in a useQuery hook
  const { data, ...rest } = useQuery({
    queryKey: ["signableDocuments", clinicId],
    queryFn: async () => (clinicId ? getSignableDocuments(clinicId) : undefined),
    enabled: Boolean(clinicId),
  });

  return { signableDocuments: data?.signableDocuments || [], ...rest };
};
