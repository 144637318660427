import { isEmpty } from "lodash";

import {
  BackgroundStatus,
  ConsentStatus,
  CredentialingStatus,
  I9Status,
  IUser,
  NpiStatus,
  OnboardingStepDefinition,
  OnboardingSteps,
  ProviderType,
  QuestionnaireStatus,
  StaffFileType,
} from "../types";

export const onboardingStatus = {
  // Using colors from antd for tags
  PENDING: { color: "red", label: "Invite Pending" },
  IN_PROGRESS: { color: "blue", label: "Onboarding" },
  VERIFYING: { color: "orange", label: "Verifying" },
  COMPLETE: { color: "green", label: "Ready to Complete" },
};

export const onboardingFileTypes = [
  StaffFileType.DIPLOMAS,
  StaffFileType.SIGNATURE,
  StaffFileType.DRIVERS_LICENSE_FRONT,
  StaffFileType.DRIVERS_LICENSE_BACK,
  StaffFileType.RESUME,
];

export const defaultOnboardingSteps: OnboardingSteps = {
  background: { status: BackgroundStatus.TODO },
  questionnaire: { status: QuestionnaireStatus.TODO },
  npi: { status: NpiStatus.TODO },
  consents: { status: ConsentStatus.TODO },
  i9: { status: I9Status.TODO },
  credentialing: { status: CredentialingStatus.TODO },
};

export const getIncompleteSteps = (steps?: OnboardingSteps): OnboardingStepDefinition[] => {
  const onboardingSteps: OnboardingStepDefinition[] = [
    { key: "questionnaire", label: "Questionnaire", requiredStatus: QuestionnaireStatus.COMPLETED },
    { key: "npi", label: "NPI", requiredStatus: NpiStatus.COMPLETED },
    { key: "background", label: "Background Check", requiredStatus: BackgroundStatus.CLEARED },
    { key: "consents", label: "Consents", requiredStatus: ConsentStatus.SIGNED },
    { key: "i9", label: "I-9", requiredStatus: I9Status.VERIFIED },
    { key: "credentialing", label: "Credentialing", requiredStatus: CredentialingStatus.VERIFIED },
  ];

  if (!steps || isEmpty(steps)) {
    return onboardingSteps;
  }

  return onboardingSteps.filter(({ key, requiredStatus }) => steps[key]?.status !== requiredStatus);
};

export const filterOnboardingUsers = (users: IUser[], searchString: string) => {
  return users.filter((user) => {
    if (user.hasSignedUp && user.hasCompletedOnboarding) return false;

    if (!searchString) return true;

    const searchTerms = searchString
      .toLowerCase()
      .split(",")
      .map((term) => term.trim())
      .filter((term) => term);

    return searchTerms.some(
      (term) =>
        user.firstName.toLowerCase().includes(term) || user.lastName.toLowerCase().includes(term)
    );
  });
};

export const getOnboardingStatus = (user: IUser) => {
  const incompleteSteps = getIncompleteSteps(user.onboardingSteps).map((step) => step.key);

  if (isEmpty(incompleteSteps)) {
    return { label: onboardingStatus.COMPLETE.label, color: onboardingStatus.COMPLETE.color };
  }

  if (user.onboardingSteps?.questionnaire.status === QuestionnaireStatus.SENT) {
    return { label: onboardingStatus.PENDING.label, color: onboardingStatus.PENDING.color };
  }

  if (
    incompleteSteps.includes("questionnaire") ||
    incompleteSteps.includes("background") ||
    incompleteSteps.includes("npi") ||
    incompleteSteps.includes("consents")
  ) {
    return { label: onboardingStatus.IN_PROGRESS.label, color: onboardingStatus.IN_PROGRESS.color };
  }

  if (incompleteSteps.includes("i9") || incompleteSteps.includes("credentialing")) {
    return { label: onboardingStatus.VERIFYING.label, color: onboardingStatus.VERIFYING.color };
  }

  return { label: onboardingStatus.PENDING.label, color: onboardingStatus.PENDING.color }; // Default case
};

export const onboardingStatusFilters = [
  { text: "Invite Pending", value: "Invite Pending" },
  { text: "Onboarding", value: "Onboarding" },
  { text: "Verifying", value: "Verifying" },
  { text: "Ready to Complete", value: "Ready to Complete" },
];

export const filterByStatus = (value: string | boolean | number, user: IUser) => {
  const { label } = getOnboardingStatus(user);
  return label === value;
};

export const isCompleteOnboardingEnabled = (user: IUser): boolean => {
  if (!user.hasSignedUp) {
    return false;
  }

  // Users who are not providers can complete onboarding without completing all steps
  if (user.providerType === ProviderType.NOT_PROVIDER) {
    return true;
  }

  const incompleteSteps = getIncompleteSteps(user.onboardingSteps);
  return isEmpty(incompleteSteps);
};
