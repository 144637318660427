import {
  CaretRightOutlined,
  FileOutlined,
  FilePdfOutlined,
  MedicineBoxOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  AnalyticsEvent,
  AppTitle,
  DropboxDocumentType,
  getLatestSignableDocument,
  IClientFile,
  isGeneralConsentFormSigned,
  WorkflowParentPortal,
} from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Card, Col, Divider, Row, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  getRemainingPreapprovalStepsCount,
  isClientDetailsComplete,
  isDocumentsComplete,
} from "../../helpers/client-file-status";
import { useGetSignableDocuments } from "../../hooks/useGetSignableDocuments";
import { tsr } from "../../services/tsr";
import { AuthContext } from "../AuthProvider";
import { useMixpanel } from "../MixpanelProvider";
import { TasksCardTask } from "./TasksCardTask";

const { Title } = Typography;

interface IProps {
  clientFile: IClientFile;
}

export const PreapprovalTasksCard: React.FC<IProps> = ({ clientFile }: IProps) => {
  const { isMobile } = useResponsive();
  const { trackEvent } = useMixpanel();
  const { client } = useContext(AuthContext);

  const { signableDocuments } = useGetSignableDocuments(client.clinicId);
  const latestConsentForm = getLatestSignableDocument({
    signableDocuments,
    documentType: DropboxDocumentType.CAREGIVER_CONSENT_FORM,
  });

  const insurancePlansQuery = tsr.clients.getActiveInsurancePlans.useQuery({
    queryData: { params: { clientId: client?.id ?? "" } },
    queryKey: ["clients.getActiveInsurancePlans", client?.id],
    enabled: Boolean(client?.id),
  });

  const legacyRemainingSteps = getRemainingPreapprovalStepsCount(client, clientFile);
  const remainingSteps =
    legacyRemainingSteps + (insurancePlansQuery.data?.body.primaryInsurancePlan ? 0 : 1);

  useEffect(() => {
    trackEvent({
      eventName: AnalyticsEvent.TASKS_TO_BE_COMPELETED,
      additionalProperties: {
        app: AppTitle.PARENT_PORTAL,
        workflow: WorkflowParentPortal.INTAKE_FUNNEL,
      },
    });
  }, []);

  return (
    <Card
      style={{ width: "100%" }}
      title={
        <Row align="middle" justify="space-between">
          <Col span={isMobile ? 24 : undefined}>
            <Title level={4} style={{ marginTop: 5, marginBottom: isMobile ? 10 : 5 }}>
              You have {remainingSteps} quick task
              {remainingSteps > 1 && "s"} {remainingSteps !== 3 && "left"}
            </Title>
          </Col>
          <Col>
            <Link to="/preapproval">
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  trackEvent({
                    eventName: AnalyticsEvent.WORKFLOW_NEXT_STEP,
                    additionalProperties: {
                      app: AppTitle.PARENT_PORTAL,
                      workflow: WorkflowParentPortal.INTAKE_FUNNEL,
                      step: "Start Tasks",
                    },
                  });
                }}
              >
                {`Let's go`}
                <CaretRightOutlined />
              </Button>
            </Link>
          </Col>
        </Row>
      }
    >
      <TasksCardTask
        icon={<SmileOutlined />}
        isComplete={isClientDetailsComplete(client)}
        title={"Child Details"}
        subtitle={"Your child's name, date of birth, and birth sex"}
      />
      <Divider />
      <TasksCardTask
        icon={<MedicineBoxOutlined />}
        isComplete={isDocumentsComplete(clientFile?.documents)}
        title={"Care Documents"}
        subtitle={"Previous diagnosis information, recommendation of ABA, etc."}
      />
      <Divider />
      <TasksCardTask
        icon={<FileOutlined />}
        isComplete={Boolean(insurancePlansQuery.data?.body.primaryInsurancePlan?.payerId)}
        title={"Insurance"}
        subtitle={"Insurance details and photo of card"}
      />
      <Divider />
      {latestConsentForm && (
        <TasksCardTask
          icon={<FilePdfOutlined />}
          isComplete={isGeneralConsentFormSigned(clientFile.signedDocuments)}
          title={latestConsentForm.title}
          subtitle={"Required handbooks and consent forms"}
        />
      )}
    </Card>
  );
};
