import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  createNoteEndpointRequestSchema,
  updateNoteEndpointRequestSchema,
} from "../endpoints/notes";
import { noteSchema } from "../types";

const c = initContract();
export const notesContract = c.router({
  get: {
    method: "GET",
    path: "/notes/get",
    query: z.object({
      id: z.string(),
      clinicId: z.string(),
    }),
    responses: {
      200: noteSchema,
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  create: {
    method: "POST",
    path: "/notes/create",
    body: c.type<z.infer<typeof createNoteEndpointRequestSchema>>(),
    responses: {
      200: z.string(),
      400: z.object({
        message: z.string(),
        context: z
          .object({
            appointmentId: z.string().optional(),
          })
          .optional(),
      }),
      409: z.object({
        message: z.string(),
        context: z
          .object({
            appointmentId: z.string().optional(),
          })
          .optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  update: {
    method: "POST",
    path: "/notes/update",
    body: c.type<z.infer<typeof updateNoteEndpointRequestSchema>>(),
    responses: {
      200: z.string(),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  delete: {
    method: "POST",
    path: "/notes/delete",
    body: z.object({
      id: z.string(),
    }),
    responses: {
      200: z.object({
        message: z.string(),
      }),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
