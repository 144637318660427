import { DocumentReference, FieldValue, Timestamp } from "firebase/firestore";
import cloneDeep from "lodash/cloneDeep";
import { Moment } from "moment";
import { z } from "zod";

import { TransportMode } from "../endpoints";
import { ISignedDropboxDocument } from "../services/dropboxSignService";
import {
  AppointmentType,
  AttendeeStatus,
  IAppointment,
  ICompletedAppointment,
  IIndirect,
} from "./calendar";
import {
  addressSchema,
  CountryCode,
  firestoreTimestampSchema,
  IAddress,
  IPhoneNumber,
  phoneNumberSchema,
  USStateCode,
} from "./common";
import { IInsurancePlan } from "./insurancePlans";
import { INote } from "./notes";
import { OnboardingSteps } from "./onboarding";
/** ENUMS */

/**
 * Status of Authorizations
 */
export enum AuthStatus {
  ACTIVE,
  EMPTY,
  ERROR,
  EXPIRED,
  INCOMPLETE,
  NOT_STARTED,
}

/**
 * Billing codes for appointments. Enum values use billing codes as
 * regulated by the CPT Code standard.
 */
export enum BillingCode {
  CODE_97151 = "97151",
  CODE_97152 = "97152",
  CODE_97153 = "97153",
  CODE_97154 = "97154",
  CODE_97155 = "97155",
  CODE_97156 = "97156",
  CODE_97157 = "97157",
  CODE_97158 = "97158",
  CODE_T1026 = "T1026",
  CODE_0362T = "0362T",
  CODE_0373T = "0373T",
  CODE_H0031 = "H0031",
  CODE_H0032 = "H0032",
  CODE_H0036 = "H0036",
  CODE_H0046 = "H0046",
  CODE_H2014 = "H2014",
  CODE_H2019 = "H2019",
  CODE_90889 = "90889",
}

/**
 * Different types of locations that an appointment can take place.
 * Enum values use location codes as regulated by insurance companies.
 */
export enum AppointmentLocation {
  HOME = "12",
  OFFICE = "11",
  TELEHEALTH = "2",
  SCHOOL = "3",
  OTHER = "99",
}

/**
 * Billing codes for shadow sessions. Appointments with these codes are billed as indirects
 */
export const SHADOW_SESSION_CODES = [BillingCode.CODE_T1026, BillingCode.CODE_97155];

/**
 * Default modifier placeholder for appointments. If a billing code has no modifier,
 * this placeholder will be used.
 */
export const DEFAULT_MODIFIER_PLACEHOLDER = "default" as const;

/**
 * Modifier codes for appointments. Enum values use modifier codes as defined by
 * insurance companies. These modifiers are used to indicate additional information
 * about the appointment and can affect billing rates.
 */
export enum Modifier {
  U1 = "U1",
  U2 = "U2",
  U3 = "U3",
  U4 = "U4",
  U5 = "U5",
  U6 = "U6",
  U9 = "U9",
  UC = "UC",
  UD = "UD",
  U7 = "U7",
  TJ = "TJ",
  TS = "TS",
  UN = "UN",
  UP = "UP",
  UQ = "UQ",
  UR = "UR",
  US = "US",
  HM = "HM",
  HN = "HN",
  HO = "HO",
  HR = "HR",
  HS = "HS",
  HP = "HP",
  GT = "GT",
  MOD_95 = "95",
  MOD_99 = "99",
}

/**
 * NPPES defined taxonomy codes for provider types.
 */
export enum ProviderTaxonomy {
  BCBA = "103K00000X",
  BCABA = "106E00000X",
  RBT = "106S00000X",
}

/**
 * Represents a provider type enumeration for behavior therapy services.
 *
 * ## **This order matters the first provider type takes the highest precedence**
 *
 */
export enum ProviderType {
  /**
   * Board Certified Behavior Analyst - Doctorate
   */
  BCBAD = "BCBA-D",
  /**
   * Board Certified Behavior Analyst.
   */
  BCBA = "BCBA",
  /**
   * The Board Certified Assistant Behavior Analyst® (BCaBA®) is an undergraduate-level certification in behavior analysis.
   */
  BCABA = "BCaBA",
  /**
   * In NM, there are BA-Candidates
   */
  BAC = "BA-Candidate",
  /**
   * In Michigan, there are QBHP (Qualified Behavioral Health Professional)
   */
  QBHP = "QBHP",
  /**
   * In WA, there are LABA (Licensed Assistant Behavior Analyst)
   */
  LABA = "LABA",
  /**
   * In WA, there are CBT (Certified Behavior Technician)
   */
  CBT = "CBT",
  /**
   * Registered Behavior Technician.
   */
  RBT = "RBT",
  /**
   * Behavior Technician.
   */
  BT = "BT",
  /**
   * Admin or none of the above
   */
  NOT_PROVIDER = "NOT_PROVIDER",
}

/**
 * Provider types that by default should not be displayed in the provider type dropdown
 */
export const SecondaryProviderTypes: ProviderType[] = [
  ProviderType.BAC,
  ProviderType.QBHP,
  ProviderType.LABA,
  ProviderType.CBT,
  ProviderType.NOT_PROVIDER, // Admin
];

/**
 * ICD-10 codes for diagnoses.
 *
 * @see https://www.icd10data.com/ICD10CM/Codes
 *
 * F84.0 - Autistic disorder
 * Z13.42.1 - Encounter for screening for global developmental delays (milestones)
 * F34.81 - Disruptive mood dysregulation disorder
 * F43.9 - Reaction to severe stress, unspecified
 * F90.2 - Attention-deficit hyperactivity disorder, combined type
 */
export enum DiagnosisCode {
  F84_0 = "F84.0",
  Z13_42_1 = "Z13.42.1",
  F34_81 = "F34.81",
  F43_9 = "F43.9",
  F90_2 = "F90.2",
}

/**
 * Permission levels for users in Finni.
 *
 * ### **This order matters the first permission takes the highest precedence**
 */
export enum UserPermission {
  SUPERADMIN = "SUPERADMIN",
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  AUDITOR = "AUDITOR",
}

export enum OrbitPermission {
  EDIT_PROGRAMS = "EDIT_PROGRAMS",
  COMPLETE_APPOINTMENTS = "COMPLETE_APPOINTMENTS",
  EDIT_SESSIONS = "EDIT_SESSIONS",
  DELETE_SESSIONS = "DELETE_SESSIONS",
  MANAGE_EVENTS = "MANAGE_EVENTS",
  VIEW_ALL_APPOINTMENTS = "VIEW_ALL_APPOINTMENTS",
}

/**
 * Days of the week.
 */
export enum Weekday {
  SUNDAY = "Sunday",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
}

/**
 * Types of documents that can be uploaded to a client file.
 */
export enum ClientDocumentType {
  AUTHORIZATION = "AUTHORIZATION",
  ASSESSMENT = "ASSESSMENT",
  DIAGNOSIS_REPORT = "DIAGNOSIS_REPORT",
  OFFICIAL_DIAGNOSIS_REPORT = "OFFICIAL_DIAGNOSIS_REPORT",
  TREATMENT_PLAN = "TREATMENT_PLAN",
  INSURANCE_APPROVAL = "INSURANCE_APPROVAL",
  INCIDENT_REPORT = "INCIDENT_REPORT",
  MISCELLANEOUS = "MISCELLANEOUS",
}

/**
 * Types of documents that can be uploaded to a staff file.
 */
export enum StaffFileType {
  CPR_CERT = "CPR_CERTIFICATE",
  RBT_CERT = "RBT_CERTIFICATE",
  BCBA_CERT = "BCBA_CERTIFICATE",
  STATE_RBT_CERT = "STATE_RBT_CERTIFICATE",
  STATE_BCBA_CERT = "STATE_BCBA_CERTIFICATE",
  STATE_LBA_CERT = "STATE_LBA_CERTIFICATE",
  DIPLOMAS = "DIPLOMAS",
  ID = "IDS",
  SIGNATURE = "SIGNATURE",
  DRIVERS_LICENSE_FRONT = "DRIVERS_LICENSE_FRONT",
  DRIVERS_LICENSE_BACK = "DRIVERS_LICENSE_BACK",
  BG_CHECK = "BG_CHECK",
  CONSENTS = "CONSENTS",
  EMPLOYMENT_AGREEMENT = "EMPLOYMENT_AGREEMENT",
  JOB_DESCRIPTION = "JOB_DESCRIPTION",
  REFERENCE_CHECK = "REFERENCE_CHECK",
  RESUME = "RESUME",
  INCIDENT_REPORT = "INCIDENT_REPORT",
}

/**
 * Used for parent onboarding. Different types of therapy that a client can receive.
 */
export enum MedicalServices {
  ABA_THERAPY = "ABA_THERAPY",
  PSYCHOLOGICAL_SERVICES = "PSYCHOLOGICAL_SERVICES",
  SPEECH_THERAPY = "SPEECH_THERAPY",
  OCCUPATIONAL_THERAPY = "OCCUPATIONAL_THERAPY",
  PHYSICAL_THERAPY = "PHYSICAL_THERAPY",
  PSYCHIATRIC_SERVICES = "PSYCHIATRIC_SERVICES",
  NEUROLOGICAL_SERVICES = "NEUROLOGICAL_SERVICES",
  SPECIAL_EDUCATION = "SPECIAL_EDUCATION",
}

/**
 * Inquiry status. Used to track the status of a client inquiry.
 */
export enum InquiryStatus {
  UNDISCOVERED = "UNDISCOVERED",
  REFERRAL = "REFERRAL",
  IN_PROCESS = "IN_PROCESS",
  AWAITING_RESPONSE = "AWAITING_RESPONSE",
  AWAITING_INSURANCE = "AWAITING_INSURANCE",
  AWAITING_DIAGNOSIS = "AWAITING_DIAGNOSIS",
  UNQUALIFIED = "UNQUALIFIED",
  NO_RESPONSE = "NO_RESPONSE",
}

/**
 * Stutus of a client file. Used to track the status of a client file during intake process
 */
export enum IntakeStatus {
  UNDISCOVERED = "UNDISCOVERED",
  IN_PROCESS = "IN_PROCESS",
  CONFIRMING_INSURANCE = "CONFIRMING_INSURANCE",
  PENDING_AUTHORIZATION = "PENDING_AUTHORIZATION",
  AWAITING_RESPONSE = "AWAITING_RESPONSE",
  AWAITING_DIAGNOSIS = "AWAITING_DIAGNOSIS",
  AWAITING_CONTRACTING = "AWAITING_CONTRACTING",

  INTAKE_ASSESSMENT = "INTAKE_ASSESSMENT",
  IN_PERSON_ASSESSMENT = "IN_PERSON_ASSESSMENT",
  ISP_IN_PROCESS = "ISP_IN_PROCESS",

  ISP_SUBMITTED = "ISP_SUBMITTED",
  REAUTHORIZATION_SUBMITTED = "REAUTHORIZATION_SUBMITTED",

  ACTIVE = "ACTIVE",
  ON_HOLD = "ON_HOLD",
  UNQUALIFIED = "UNQUALIFIED",
  CHURNED = "CHURNED",
  PENDING_DISCHARGE = "PENDING_DISCHARGE",
}

/**
 * Subsection of IntakeStatus. Used to track the status of a client file during the prequalified intake process
 */
export enum PrequalifiedStatus {
  UNDISCOVERED = IntakeStatus.UNDISCOVERED,
  IN_PROCESS = IntakeStatus.IN_PROCESS,
  CONFIRMING_INSURANCE = IntakeStatus.CONFIRMING_INSURANCE,
  PENDING_AUTHORIZATION = IntakeStatus.PENDING_AUTHORIZATION,
  AWAITING_RESPONSE = IntakeStatus.AWAITING_RESPONSE,
  AWAITING_DIAGNOSIS = IntakeStatus.AWAITING_DIAGNOSIS,
  AWAITING_CONTRACTING = IntakeStatus.AWAITING_CONTRACTING,
  UNQUALIFIED = IntakeStatus.UNQUALIFIED,
}

/**
 * Subsection of IntakeStatus. Used to track the status of a client file during the active intake process
 */
export enum ActiveStatus {
  INTAKE_ASSESSMENT = IntakeStatus.INTAKE_ASSESSMENT,
  IN_PERSON_ASSESSMENT = IntakeStatus.IN_PERSON_ASSESSMENT,
  ISP_IN_PROCESS = IntakeStatus.ISP_IN_PROCESS,
  ISP_SUBMITTED = IntakeStatus.ISP_SUBMITTED,
  REAUTHORIZATION_SUBMITTED = IntakeStatus.REAUTHORIZATION_SUBMITTED,
  ACTIVE = IntakeStatus.ACTIVE,
  ON_HOLD = IntakeStatus.ON_HOLD,
  PENDING_DISCHARGE = IntakeStatus.PENDING_DISCHARGE,
  // Make sure CHURNED is always the last status
  CHURNED = IntakeStatus.CHURNED,
}

/**
 * Credential types that a user can have. These are official credentials from certification bodies.
 */
export enum Credential {
  CPR_TRAINING = "CPR Training",
  RBT_CERTIFICATION = "RBT Certification",
  BCBA_CERTIFICATION = "BCBA Certification",
  STATE_RBT_CERTIFICATION = "State RBT Certification",
  STATE_BCBA_CERTIFICATION = "State BCBA Certification",
  STATE_LBA_CERTIFICATION = "State LBA Certification",
}

/**
 * Education levels for staff.
 */
export enum EducationLevel {
  HIGH_SCHOOL = "High School",
  ASSOCIATES = "Associates",
  BACHELORS = "Bachelors",
  MASTERS = "Masters",
  DOCTORATE = "Doctorate",
}

/**
 * Policy holder of an insurance policy.
 * SELF = the kiddo
 * CHILD = the kiddo's parent/guardian
 */
export enum PolicyHolderRelationship {
  SELF = "SELF",
  CHILD = "CHILD",
}

export enum Sex {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNKNOWN = "UNKNOWN",
}

/**
 * Block scheduling blocks.
 */
export enum ScheduleBlock {
  MORNING = "MORNING",
  MIDDAY = "MIDDAY",
  AFTERNOON = "AFTERNOON",
  EVENING = "EVENING",
}

/**
 * Tracking of the status of a client file's RBT hiring.
 */
export enum HiringStatus {
  NONE = "NONE",
  SEARCHING = "SEARCHING",
  INTERVIEWING = "INTERVIEWING",
  OFFERED = "OFFERED",
  HIRED = "HIRED",
}

/**
 * Used in parent portal as a part of the intake form
 */
export enum IGoals {
  COMMUNICATION_SKILLS = "COMMUNICATION_SKILLS",
  CHALLENGING_BEHAVIOR = "CHALLENGING_BEHAVIOR",
  INDEPENDENCE = "INDEPENDENCE",
  SOCIAL_SKILLS = "SOCIAL_SKILLS",
  FOLLOW_INSTRUCTIONS = "FOLLOW_INSTRUCTIONS",
}

/**
 * Used in parent portal as a part of the intake form
 */
export enum TherapistGender {
  ANY = "ANY",
  FEMALE = "FEMALE",
  MALE = "MALE",
  NON_BINARY = "NON_BINARY",
}

/**
 * Used in parent portal as a part of the intake form
 */
export enum WeekBlock {
  MON_WED_FRI = "monWedFri",
  TUES_THURS = "tuesThurs",
}

/**
 * Used in parent portal as a part of the intake form
 */
export enum DayBlock {
  MORNING = "MORNING",
  MIDDAY = "MIDDAY",
  EVENING = "EVENING",
}

/**
 * Used in parent portal as a part of the intake form
 */
export enum BehaviorSeverity {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  EXTREME = "EXTREME",
}

/**
 * Used in parent portal as a part of the intake form
 */
export enum BehaviorFrequency {
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  DAILY = "DAILY",
  HOURLY = "HOURLY",
  CONSTANTLY = "CONSTANTLY",
}

/**
 * States that require EVV
 */
export enum EvvStateCode {
  CO = USStateCode.CO,
}

export const getProviderTypesForState = (state: USStateCode): ProviderType[] => {
  return (
    stateProviderTypeMap[state] ||
    Object.values(ProviderType).filter((type) => !SecondaryProviderTypes.includes(type))
  );
};

// Have a mapping of the states and their specific provider types
export const stateProviderTypeMap: Partial<Record<USStateCode, ProviderType[]>> = {
  // New Mexico specific provider types
  [USStateCode.NM]: [
    ...Object.values(ProviderType).filter((type) => !SecondaryProviderTypes.includes(type)),
    ProviderType.BAC,
  ],
};

export interface IIncident {
  dateOfIncident: string;
  typeOfIncident: string;
  incidentFormLink: string;
  clinicDisplayName: string;
  clinicId: string;
  hasBeenSubmittedBefore: boolean;
}

export enum IncidentType {
  EMPLOYEE = "Employee",
  CLIENT = "Client",
}

export type IncidentReportKey = `${IncidentType}_${string}_${string}`;

export interface IIncidentReport {
  [key: IncidentReportKey]: IncidentFormData;
}

export interface IIncidentNotificationRecipient {
  firstName: string;
  lastName: string;
  email: string;
}

export interface IncidentFormData {
  id: string;
  dateOfIncident: string;
  timeOfIncident: string;
  typeOfIncident: string | null;
  address: {
    line1: string;
    line2?: string;
    city: string;
    state: USStateCode;
    zipCode: string;
    country: CountryCode;
  };
  firstName?: string;
  lastName?: string;
  incidentDescription: string;
  supervisorNotified: boolean;
  incidentTypes: string | string[];
  dateOfReport: string;
  completedBy: string;
  signatureAcknowledgment: boolean;
  witnesses: string;
  actionsTaken: string;
  acknowledgmentOfDecliningMedicalTreatment: boolean;
  employeeJobTitle?: string;
  incidentPreventionSuggestions?: string;
  medicalTreatmentDeclaration?: string;
  additionalNotesOrComments?: string;
  createdAt: string;
  updatedAt: string;
}
/** DB Tables */
/**
 * A user object in Firestore. These are internal users who have access to
 * Mission Control and Data Collection (i.e. Finns, BCBAs, RBTs, Admin staff).
 *
 * @param id The user's ID
 * @param motivityUUID The user's Motivity UUID
 * @param sandataStaffId The user's Sandata EVV Staff ID
 * @param slackId The user's Slack ID
 * @param clinicId The ID of the clinic that the user belongs to
 * @param allowedClinicIds The IDs of the clinics that the user has access to. Index 0 is the primary clinic.
 * @param firstName The user's first name
 * @param middleName The user's middle name
 * @param lastName The user's last name
 * @param email The user's email address
 * @param phoneNumber The user's phone number
 * @param sex The user's birth sex
 * @param dateOfBirth The user's date of birth
 * @param isFullTime Whether the user is full time
 * @param permissions The user's permissions
 * @param npi The user's NPI number
 * @param credentials A list of the user's credential objects {@link ICredential}
 * @param hasSetSchedule Whether the user has set their availability
 * @param hasSignedUp Whether the user has finished the signup flow at /new-practice (for new clinic signup) or /signup (new staff signup) or not.
 * @param schedule The user's {@link ISchedule} object
 * @param stats The user's {@link IUserStats} object used for Impact Scores
 * @param address The user's address {@link IAddress}
 * @param addressNotes Notes about the user's address
 * @param preferredTransport The user's preferred mode of transportation {@link TransportMode}
 * @param offerSignedMs The timestamp when the user signed the offer letter from rippling
 * @param hasCompletedOnboarding Whether the user has completed the onboarding flow and has become active
 * @param onboardingSteps The user's {@link OnboardingSteps} object used for onboarding
 * @param incidentReports The user's {@link IncidentFormData} objects
 */
export interface IUser {
  id: string;
  motivityUUID?: string;
  sandataStaffId?: string;
  slackId?: string;
  ripplingId?: string;

  manager?: string;
  clinicId: string;
  allowedClinicIds?: string[]; // index 0 is the primary clinic

  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;

  phoneNumber: string;
  dialpadNumber?: string;
  faxNumber?: string;
  sex: Sex;
  dateOfBirth: string;
  countryOfBirth?: string;
  stateOfBirth?: USStateCode;
  isFullTime: boolean;

  providerType: ProviderType;
  secondaryProviderType: ProviderType | null;

  orbitPermissions: OrbitPermission[] | undefined;
  permissions: UserPermission[];
  npi: string;
  credentials: ICredential[];
  documents: IStaffFile[];
  caqh?: ICaqh;
  isOnHold?: boolean;

  educationLevel?: EducationLevel;
  criminalRecord?: boolean;
  criminalRecordDetails?: string;
  socialSecurityNumber?: string;

  offerSignedMs?: number;
  startDateMs: number;
  hasSetSchedule?: boolean;
  hasSignedUp?: boolean;
  schedule: ISchedule;
  stats?: IUserStats;

  hasCompletedOnboarding?: boolean;
  onboardingSteps?: OnboardingSteps;

  address: IAddress;
  locations?: string[];
  addressNotes: string;
  preferredTransport: TransportMode;

  notes?: string;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;

  billingHoldPayerIds?: string[];

  npsSurveyCompletedAt?: string | null;

  incidentReports?: IIncidentReport[];
}

/**
 * User compensation rates for a given user. A document on the user-pay-rates sub-collection on an IUser
 * path: users/{userId}/user-pay-rates/{userPayRateId}
 *
 * @param id The user's pay rate ID
 * @param userId The user's ID
 * @param clinicId The ID of the clinic that the user belongs to
 * @param startMs The start time (effective time) of the pay rate
 * @param directRateCents The user's direct rate (time spent with kiddos) in cents
 * @param indirectRateCents The user's indirect rate (time spent without kiddos) in cents
 * @param salaryRateCents The user's salary rate in cents (if not hourly)
 */
export type IUserPayRate = {
  id: string;
  userId: string;
  clinicId: string;

  startMs: number;

  directRateCents: number;
  indirectRateCents: number;
  driveTimeRateCents: number;
  salaryRateCents: number;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
};

/**
 * Availability for a given user. A document on the user-availability sub-collection on an IUser.
 * path: users/{userId}/user-availability/{userAvailabilityId}
 *
 * @param id The user's availability ID
 * @param clinicId The ID of the clinic that the user belongs to
 * @param approved Whether the user's availability has been approved by a BCBA (not used)
 * @param availability The user's {@link IAvailability} object
 */
export interface IUserAvailability {
  id: string;
  clinicId: string;

  approved: boolean;

  availability: IAvailability;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IClientPin {
  clientId: string;
}

/**
 * Deprecated
 */
export interface IWeekdaySchedule {
  enabled: boolean;
  intervals: {
    startMs: Timestamp;
    endMs: Timestamp;
  }[];
}

/**
 * Deprecated
 */
export type ISchedule = {
  [key in Weekday]: IWeekdaySchedule;
};

export const WeekdayScheduleSchema = z.object({
  enabled: z.boolean(),
  intervals: z.array(
    z.object({
      startMs: z.any(), // Timestamp
      endMs: z.any(), // Timestamp
    })
  ),
});

export const ScheduleSchema = z
  .object({
    Monday: WeekdayScheduleSchema,
    Tuesday: WeekdayScheduleSchema,
    Wednesday: WeekdayScheduleSchema,
    Thursday: WeekdayScheduleSchema,
    Friday: WeekdayScheduleSchema,
    Saturday: WeekdayScheduleSchema,
    Sunday: WeekdayScheduleSchema,
  })
  .refine((value) => {
    // Custom validation logic for the deprecated ISchedule
    const weekdays = Object.keys(value);
    const validWeekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const isValid = weekdays.every((weekday) => validWeekdays.includes(weekday));
    return isValid ? value : { error: "Invalid weekday in schedule" };
  });

/**
 * Deprecated
 */
export interface IUpdateWorkingHoursRequest {
  id: string;
  userId: string;
  clinicId: string;
  schedule: ISchedule;
  approved?: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deletedAt?: Timestamp;
}

/**
 * A clinic (i.e. a Finni practice owner's practice).
 *
 * @param id The clinic's ID
 * @param name The clinic's name. Must be unique (i.e. a slug)
 * @param displayName The clinic's display name used for display purposes
 * @param phoneNumber The clinic's phone number
 * @param email The clinic's email address
 * @param address The clinic's address {@link IAddress}
 * @param addressNotes Notes about the clinic's address
 * @param serviceAreas A list of zipcode strings that the clinic services
 * @param lookerReportURL The URL of the clinic's Looker report for the dashboard page
 * @param slackId The clinic's Slack ID
 *
 */
export interface IClinic {
  id: string;

  name: string;
  displayName: string;
  phoneNumber?: string;
  email?: string;
  address: IAddress;
  addressNotes?: string;
  logoPath?: string;

  serviceAreas: string[];

  lookerReportURL: string;
  slackId: string;

  silnaProviderId: string | null;
  silnaServiceLocationId: string | null;

  dropboxTemplateId: string;
  handbookPreviewUrl: string;

  isAdonisEnabled?: boolean; // Whether we are creating claims on appointment completion

  calendarSettings?: {
    minimumAppointmentLength: number;
  };
  // User provided locations. These can be cities, counties, or a general area
  locations?: string[];

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export enum AuthMethod {
  SILNA = "silna",
  MANUAL = "manual",
  PHONE = "phone",
  TEMP = "temp",
}

export enum InsuranceCheckStatus {
  PENDING = "Pending",
  INFO_NEEDED = "Info Needed",
  DENIED = "Denied",
  CLEARED = "Cleared",
}

/**
 * A client.
 *
 * @param id The client's ID
 * @param motivityUUID The client's Motivity UUID from the motivity integration
 * @param slackId The client's Slack ID
 * @param clinicId The ID of the clinic that the client belongs to
 * @param guardianId The ID of the client's guardian
 * @param firstName The client's first name
 * @param middleName The client's middle name
 * @param lastName The client's last name
 * @param preferredName The client's preferred name
 * @param alias The client's alias typically built using the first 2 letters of their first name and last name
 * @param dateOfBirth The client's date of birth
 * @param sex The client's birth sex
 * @param stats The client's {@link IClientStats} object used for auth utilization calulcations
 * @param assignedBcbaId The ID of the BCBA that the client file is assigned to
 * @param assignedRbtIds The IDs of the RBTs that the client file is assigned to
 * @param address The client's address {@link IAddress}
 * @param addressNotes Notes about the client's address
 * @param intakeStatus The client's {@link IntakeStatus}
 * @param intakeStatusNotes Notes about the client's intake status
 * @param hiringStatus The client's {@link HiringStatus}
 * @param isHot Whether the client file is high priority
 * @param dx The client's diagnosis ICD-10 code {@link DiagnosisCode}
 * @param payers The client's {@link IClientPayer} objects, Primary and Secondary
 * @param scheduleNotes Notes about the client's schedule
 * @param scheduleBlocks The client's {@link ScheduleBlock} objects for the clients page in Mission Control. Only for tracking purposes
 * @param documents The client's uploaded {@link IClientDocument}
 * @param therapyPreferences The client's {@link ITherapyPreferences} object used in the onboarding flow
 * @param behaviors The client's {@link IBehaviors} object used in the onboarding flow
 * @param medicalHistory The client's {@link IMedicalHistory} object used in the onboarding flow
 * @param payerVerificationIds The most recent ids used to verify the Client file's payers
 * @param patientId The patientId from the verification provider
 * @param allowSchedulingWithoutAuthorization Whether the client can be scheduled without authorization
 */
export interface IClient {
  id: string;
  motivityUUID: string | null;
  slackId: string | null;

  clinicId: string;
  guardianId: string;

  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  alias: string;

  dateOfBirth: string;
  sex: Sex;

  stats?: IClientStats;

  assignedUserId?: string;
  assignedBcbaId?: string;
  assignedRbtIds?: string[];

  address: IAddress;
  addressNotes?: string;

  intakeStatus: IntakeStatus;
  intakeStatusNotes?: string;
  hiringStatus: HiringStatus;
  isHot: boolean;

  dx: DiagnosisCode | null;

  referringProvider?: {
    firstName: string;
    lastName: string;
    npi: number;
  };

  isOnHold?: boolean;

  scheduleNotes?: string;
  scheduleBlocks: ScheduleBlock[];

  documents: IClientDocument[];
  signedDocuments?: ISignedDropboxDocument[];

  therapyPreferences: ITherapyPreferences;
  behaviors: IBehaviors;
  medicalHistory: IMedicalHistory;

  payerVerificationPatientId: string | null;

  progressNote: string | null;

  insuranceCheckStatus?: InsuranceCheckStatus;
  authCheckStatus?: InsuranceCheckStatus;

  allowSchedulingWithoutAuthorization?: boolean;

  locations?: string[];

  verifiedBy: string | null;
  verifiedMs: number | null;
  verificationNotes: string;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
  incidentReports?: IIncidentReport[];
}

export interface IClientWithPayers extends IClient {
  payers: {
    primary?: IClientPayer | null;
    upcomingPrimary?: IClientPayer | null;
    secondary?: IClientPayer | null;
  };
}

export const guardianSchema = z.object({
  id: z.string(),
  clinicId: z.string(),
  firstName: z.string(),
  middleName: z.string().optional(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: phoneNumberSchema,
  tempPassword: z.boolean().optional(),
  createdAt: firestoreTimestampSchema,
  updatedAt: firestoreTimestampSchema,
  deletedAt: firestoreTimestampSchema.optional(),
});

/**
 * A guardian of a client. Has an associated Firebase Auth user for logging into Parent Portal
 *
 * @param id The guardian's ID
 * @param clinicId The ID of the clinic that the guardian belongs to
 * @param firstName The guardian's first name
 * @param middleName The guardian's middle name
 * @param lastName The guardian's last name
 * @param email The guardian's email address
 * @param phoneNumber The guardian's phone number
 * @param tempPassword Whether the guardian has a temporary password. Used for first time login.
 */
export type IGuardian = z.input<typeof guardianSchema>;

/**
 * An inquiry. These are potential clients who have reached out to Finni and do not have an account in Parent Portal.
 *
 * @param id The inquiry's ID
 * @param clinicId The ID of the clinic that the inquiry belongs to
 * @param assignedUserId The ID of the user that the inquiry is assigned to (not used)
 * @param firstName The inquiry's first name
 * @param lastName The inquiry's last name
 * @param email The inquiry's email address
 * @param phoneNumber The inquiry's phone number
 * @param address The inquiry's address {@link IAddress}
 * @param intakeStatus The inquiry's {@link InquiryStatus}
 * @param intakeStatusNotes Notes about the inquiry's intake status
 * @param isHot Whether the inquiry is high priority
 */
export interface IInquiry {
  id: string;

  clinicId: string;
  assignedUserId?: string;

  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: IPhoneNumber;
  address: IAddress;

  intakeStatus: InquiryStatus;
  intakeStatusNotes?: string;
  isHot: boolean;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

/**
 * The central data structure that houses a client family's details.
 * 1 - 1 with {@link IClient}
 * 1 - 1 with {@link IGuardian}
 *
 * @param id The client file's ID
 * @param clinicId The ID of the clinic that the client file belongs to
 * @param clientId The ID of the client that the client file belongs to
 * @param guardianId The ID of the guardian that the client file belongs to
 * @param assignedUserId Deprecated
 * @param assignedBcbaId The ID of the BCBA that the client file is assigned to
 * @param assignedRbtIds The IDs of the RBTs that the client file is assigned to
 * @param address The client file's address {@link IAddress}
 * @param addressNotes Notes about the client file's address
 * @param intakeStatus The client file's {@link IntakeStatus}
 * @param intakeStatusNotes Notes about the client file's intake status
 * @param hiringStatus The client file's {@link HiringStatus}
 * @param isHot Whether the client file is high priority
 * @param payers The client file's {@link IClientPayer} objects, Primary and Secondary
 * @param scheduleNotes Notes about the client file's schedule
 * @param scheduleBlocks The client file's {@link ScheduleBlock} objects for the clients page in Mission Control. Only for tracking purposes
 * @param documents The client file's uploaded {@link IClientDocument}
 * @param therapyPreferences The client file's {@link ITherapyPreferences} object used in the onboarding flow
 * @param behaviors The client file's {@link IBehaviors} object used in the onboarding flow
 * @param medicalHistory The client file's {@link IMedicalHistory} object used in the onboarding flow
 * @param patientId The patientId from the verification provider
 */

/**
 * @deprecated
 * we are deprecating IClientFile and clientFile methods. use IClient and client methods instead.
 */
export interface IClientFile {
  id: string;

  clinicId: string;
  clientId: string;
  guardianId: string;

  assignedUserId?: string;
  assignedBcbaId?: string;
  assignedRbtIds?: string[];

  address: IAddress;
  addressNotes?: string;

  intakeStatus: IntakeStatus;
  intakeStatusNotes?: string;
  hiringStatus: HiringStatus;
  isHot: boolean;

  // NOTE: This structure is used only to store state in the parent portal.
  // It SHOULD NOT be used as a source of truth for payers.
  payers: {
    primary?: IClientPayer | null;
    upcomingPrimary?: IClientPayer | null;
    secondary?: IClientPayer | null;
    updatedAt?: FieldValue | Timestamp;
  };

  scheduleNotes?: string;
  scheduleBlocks: ScheduleBlock[];

  documents: IClientDocument[];
  signedDocuments?: ISignedDropboxDocument[];

  therapyPreferences: ITherapyPreferences;
  behaviors: IBehaviors;
  medicalHistory: IMedicalHistory;

  payerVerificationPatientId: string | null;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export const updatableClientFileFields = [
  "assignedUserId",
  "assignedBcbaId",
  "assignedRbtIds",
  "address",
  "addressNotes",
  "intakeStatus",
  "intakeStatusNotes",
  "hiringStatus",
  "isHot",
  "payers",
  "scheduleNotes",
  "scheduleBlocks",
  "documents",
  "signedDocuments",
  "therapyPreferences",
  "behaviors",
  "medicalHistory",
  "payerVerificationPatientId",
] as const;

/**
 * A client's availability. A document on the client-availability sub-collection on an IClient.
 * path: clients/{clientId}/client-availability/{clientAvailabilityId}
 *
 * @param id The client's availability ID
 * @param clinicId The ID of the clinic that the client belongs to
 * @param minimumHours The client's minimum hours. BCBAs can customize this to display to parents.
 * @param recommendedHours The client's recommended hours. BCBAs can customize this to display to parents.
 * @param availability The client's {@link IAvailability} object
 */
export interface IClientAvailability {
  id: string;
  clinicId: string;

  minimumHours: number;
  recommendedHours: number;

  availability: IAvailability;
  availabilityStatus?: IAvailabilityStatus;
  availabilityOverrides?: IAvailabilityOverride[];

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

/**
 * An availability object. Captures intervals of time that a client or user is available.
 *
 * key: weekday from {@link Weekday} the day of the week for the availability
 * value: an array of {@link IWeekdayAvailability} objects
 */
export type IAvailability = {
  [key in Weekday]: IWeekdayAvailability[];
};

/**
 * Defines whether the availability is enabled for a given day of the week.
 */
export type IAvailabilityStatus = {
  [key in Weekday]: boolean;
};

/**
 * An availability override defines a specific date and
 * time range where a client or user is available/unavailable.
 */
export type IAvailabilityOverride = {
  date: string;
  timeRange: IWeekdayAvailability;
  /**
   * Whether the client is available on this date and time range.
   */
  available: boolean;
};

const BLANK_AVAILABILITY: IAvailability = Object.freeze({
  [Weekday.SUNDAY]: [],
  [Weekday.MONDAY]: [],
  [Weekday.TUESDAY]: [],
  [Weekday.WEDNESDAY]: [],
  [Weekday.THURSDAY]: [],
  [Weekday.FRIDAY]: [],
  [Weekday.SATURDAY]: [],
});

export const getBlankAvailability = () => {
  return cloneDeep(BLANK_AVAILABILITY);
};

const BLANK_AVAILABILITY_STATUS: IAvailabilityStatus = Object.freeze({
  [Weekday.SUNDAY]: true,
  [Weekday.MONDAY]: true,
  [Weekday.TUESDAY]: true,
  [Weekday.WEDNESDAY]: true,
  [Weekday.THURSDAY]: true,
  [Weekday.FRIDAY]: true,
  [Weekday.SATURDAY]: true,
});

export const getBlankAvailabilityStatus = () => {
  return cloneDeep(BLANK_AVAILABILITY_STATUS);
};

/**
 * A single availability block.
 *
 * @param startMs The start time of the availability block in milliseconds
 * @param endMs The end time of the availability block in milliseconds
 */
export type IWeekdayAvailability = {
  startMs: number;
  endMs: number;
};

/**
 * An invite for an {@link IUser} to Finni's internal software (Mission Control and Data Collection)
 *
 * @param id The invite's ID
 * @param clinicId The ID of the clinic that the invite belongs to
 * @param firstName The invitee's first name
 * @param lastName The invitee's last name
 * @param email The invitee's email address
 * @param providerType The invitee's {@link ProviderType}
 */
export interface IInvite {
  id: string;

  clinicId: string;

  firstName: string;
  lastName: string;
  email: string;
  providerType: ProviderType;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IInviteWithClinicName extends IInvite {
  clinicName: string;
}

/** Abstractions */

export const contactSchema = z.object({
  firstName: z.string(),
  middleName: z.string().optional(),
  lastName: z.string(),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
  dateOfBirth: z.string().optional(),
  sex: z.nativeEnum(Sex).optional(),
  address: addressSchema.optional(),
});

export type IContact = z.input<typeof contactSchema>;

export const authCodeSchema = z.record(
  z.nativeEnum(BillingCode),
  z.object({
    unitSize: z.number(),
    units: z.number(),
  })
);

export type IAuthCode = z.input<typeof authCodeSchema>;

export const authorizationSchema = z.object({
  id: z.string(),
  clinicId: z.string(),
  payerId: z.string(),
  isNoAuthRequired: z.boolean(),
  authNumber: z.string(),
  startDate: z.string().date().nullable(),
  endDate: z.string().date().nullable(),
  authCodes: authCodeSchema,
  documents: z.array(z.object({ path: z.string(), fileName: z.string() })).nullish(),
  pendingAuthorizationId: z.string().optional(),
  phoneReferenceNumber: z.string().nullish(),
  authMethod: z.nativeEnum(AuthMethod).nullish(),
  createdAt: z.instanceof(Timestamp).nullable(),
  updatedAt: z.instanceof(Timestamp).nullable(),
  deletedAt: z.instanceof(Timestamp).nullable(),
});

export type IAuthorization = z.input<typeof authorizationSchema>;

export interface IAuthorizationWithPayer extends IAuthorization {
  payer?: IPayer;
}

export enum PendingAuthStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}
// Fields are nullable to allow users to save incomplete data
export const pendingAuthorizationSchema = z.object({
  id: z.string(),
  // Not stored as the id, since we may have multiple pending auths
  // with the same auth number.
  authNumber: z.string().nullable(),
  phoneReferenceNumber: z.string().nullable(),
  clinicId: z.string(),
  clientId: z.string(),
  payerId: z.string().nullable(),
  startDate: z.string().date().nullable(),
  endDate: z.string().date().nullable(),
  authCodes: z.array(
    z.object({
      billingCode: z.nativeEnum(BillingCode),
      units: z.number().nullable(),
    })
  ),
  status: z.nativeEnum(PendingAuthStatus),
  documents: z.array(z.object({ path: z.string(), fileName: z.string() })).nullable(),
  authMethod: z.nativeEnum(AuthMethod).nullable(),
  updatedAt: z.number(),
  createdAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type PendingAuthorization = z.input<typeof pendingAuthorizationSchema>;

export enum InsuranceType {
  MEDICAID = "Medicaid",
  COMMERCIAL = "Commercial",
  TRICARE = "Tricare",
  BLUE_CROSS_BLUE_SHIELD = "Blue Cross Blue Shield",
  OTHER = "Other",
  UNKNOWN = "Unknown",
}

export const clientPayerSchema = z.object({
  payerId: z.string().optional(),
  memberNum: z.string().optional(),
  groupNum: z.string().optional(),
  startDate: z.string().date().optional(),
  policyHolder: contactSchema.optional(),
  policyHolderRelationship: z.nativeEnum(PolicyHolderRelationship).optional(),
  deductible: z.number().optional(),
  copay: z.number().optional(),
  photoUrls: z.array(z.string()).optional(),
});

export type IClientPayer = z.input<typeof clientPayerSchema>;

export const clientPayersSchema = z.object({
  primary: clientPayerSchema.optional(),
  secondary: clientPayerSchema.optional(),
});

export type IPayerRates = {
  [code in BillingCode]?: {
    [modifier in Modifier]?: {
      unitSize: number;
      rateCents: number; // in cents
    };
  };
};

export type IStateBillingModifiersMap = {
  [state in USStateCode]?: (
    appointment: IAppointment | ICompletedAppointment,
    payer: IPayer,
    providerType: ProviderType
  ) => Modifier[];
};

export type IPayerConfig = {
  [state in USStateCode]?: IStatePayersConfig;
};

export type IAppointmentTypeConfig = {
  [state in USStateCode]?: IAppointmentConfig[];
};

export type IStatePayersConfig = IPayer[];

export type IUserStats = {
  //delivered
  completedMinutes: number;
  indirectMinutes: number;

  //billings
  billedMinutes: number;

  //cancellations
  therapistCancelledMinutes: number;
  clientCancelledMinutes: number;
  otherCancelledMinutes: number;

  //impact
  impactBasisPoints: number;
  impactScore: number;

  //progression
  previousImpactBasisPoints: number;

  calculatedAtMs: number;
};

// Define Zod schema for IUserStats
export const UserStatsSchema = z.object({
  completedMinutes: z.number(),
  indirectMinutes: z.number(),
  billedMinutes: z.number(),
  therapistCancelledMinutes: z.number(),
  clientCancelledMinutes: z.number(),
  otherCancelledMinutes: z.number(),
  impactBasisPoints: z.number(),
  impactScore: z.number(),
  previousImpactBasisPoints: z.number(),
  calculatedAtMs: z.number(),
});

export type IClientStats = {
  //appointments
  appointmentMinutes: number;
  appointmentBreakdown: IAppointmentBreakdown;

  //completed
  completedMinutes: number;
  completedCents: number;
  completedBreakdown: IAppointmentBreakdown;

  //billed
  billedMinutes: number;
  billedCents: number;
  billedBreakdown: IAppointmentBreakdown;

  //cancelled
  cancelledMinutes: number;
  cancelledCents: number;
  cancelledBreakdown: IAppointmentBreakdown;

  //cancellationTypes
  therapistCancelledMinutes: number;
  clientCancelledMinutes: number;
  otherCancelledMinutes: number;

  calculatedAtMs: number;
};

export interface PayerCode {
  serviceLocations: AppointmentLocation[];
  authRequired: boolean;
  unitSize: number | null;
  rates: {
    [providerType in ProviderType]?: number;
  };
}

/**
 * A payer's configuration for a single state
 *
 * @param id the payer's firestore ID
 * @param name the display name of the payer
 * @param payerId the EDI payer ID.
 * @param benefitsEdiId the EDI ID for benefits checks.
 * @param silnaId the Silna ID for the payer.
 * @param modifiers mapping between provider type as billing level and coded modifier
 * @param locationModifiers mapping between locations and additional coded modifiers
 * @param isUnknownRates true if the payer's rates are unknown, false otherwise
 * @param codes a mapping of all codes in this payer's contract
 * @param codes.authRequired true if auth is required to bill this code, false otherwise
 * @param codes.unitSize size of a unit for this code, in minutes
 * @param codes.rates mapping between provider type as billing level and the rate IN CENTS
 * @param adonisPayerId mapping to adonis payer entity
 */
export interface IPayer {
  id: string;
  name: string;
  payerId: string;
  benefitsEdiId: string;
  silnaId: string | null;
  contractAddress: IAddress;
  billingAddress: IAddress;
  insuranceType: InsuranceType;
  modifiers: {
    [providerType in ProviderType]?: string;
  };
  locationModifiers: {
    [location in AppointmentLocation]?: string;
  };
  isUnknownRates: boolean;
  codes: {
    [code in BillingCode]?: {
      [modifier in Modifier | typeof DEFAULT_MODIFIER_PLACEHOLDER]?: PayerCode;
    };
  };
  getBillingModifiers?: (
    appointment: IAppointment | ICompletedAppointment,
    payer: IPayer,
    providerType: ProviderType
  ) => Modifier[];
  lastUpdatedBy?: string;
  state: USStateCode;
  adonisPayerId?: string;
  isOnHold?: boolean;
  plyAliases?: string[] | null;
  deletedAt?: FieldValue | Timestamp;
  createdAt?: FieldValue | Timestamp;
  updatedAt?: FieldValue | Timestamp;
}

export type IAppointmentConfig = {
  name: AppointmentType;
  billingCode: BillingCode;
  modifiers: Modifier[];
};

export type IAppointmentStats = {
  minutes: number;
  chargeCents: number;
  units: number;
};

export type IAppointmentBreakdown = {
  [apptName in AppointmentType]?: IAppointmentStats;
};

export interface IClientDocument {
  path: string;
  type: ClientDocumentType;
}

export const StaffFileSchema = z.object({
  path: z.string(),
  type: z.nativeEnum(StaffFileType),
});

export type IStaffFile = z.input<typeof StaffFileSchema>;

export const caqhSchema = z.object({
  identifier: z.string(),
  user: z.string(),
  password: z.string(),
});

export type ICaqh = z.input<typeof caqhSchema>;

export interface IDiagnostician {
  firstName: string;
  lastName: string;
  officeName: string;
  address: IAddress;
  phoneNumber: string;
  email?: string;
}
export interface IPediatrician {
  firstName: string;
  lastName: string;
  officeName: string;
  address: IAddress;
  phoneNumber: string;
  email?: string;
}

export interface ITherapyPreferences {
  primaryLanguage: string;
  preferredLanguage: string;
  preferredTherapistGender: TherapistGender;
  secondaryContact?: IContact;
  availability: ITherapyAvailability;
}

export type ITherapyAvailability = {
  [key in WeekBlock]?: DayBlock[];
};

export interface ISelfHarm {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  requireMedicalAttn: boolean;
  headDirected: boolean;
}

export interface IAggression {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  requireMedicalAttn: boolean;
  bite: boolean;
  weapons: boolean;
}

export interface IRunAway {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  leaveHome: boolean;
}

export interface IDestroyProperty {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  targetHighValue: boolean;
  targetGlass: boolean;
  structuralDamage: boolean;
}

export interface IFlopOnGround {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  inHome: boolean;
  outsideHome: boolean;
}

export interface ITakeOffClothes {
  frequency: BehaviorFrequency;
}

export interface IBehaviors {
  selfHarm?: ISelfHarm;
  aggression?: IAggression;
  runAway?: IRunAway;
  destroyProperty?: IDestroyProperty;
  flopOnGround?: IFlopOnGround;
  takeOffClothes?: ITakeOffClothes;
  goals?: IGoals[];
  otherGoals?: string;
}

export interface IMedicalHistory {
  currentServices?: string[];
  pastServices?: string[];
  livingSituation: string;
  existingDiagnosis?: string;
  medication?: string;
  mentalIllness?: string;
  treatmentImpairments?: string;
  visionHearingImpairment: boolean;
}

export interface ICredential {
  type: Credential;
  identifier: string;
  expiryMs: number;
  expiryWarningOffsetMs: number;
  effectiveMs?: number;
  state?: USStateCode;
  licenser?: string;
}

export const CredentialSchema = z.object({
  type: z.nativeEnum(Credential),
  identifier: z.string(),
  expiryMs: z.number(),
  expiryWarningOffsetMs: z.number(),
});

/**
 * This consolidates all client-related information into one object
 * Right now, it consists of:
 * 1. Client
 * 2. ClientFile
 * 3. ClientAvailability
 * 4. Active Authorizations
 * 5. Future Authorizations
 * 6. Primary/Secondary Payers
 * 7. Primary/Secondary Insurance Plans
 * 8. Incident Reports
 */
export interface IClientDetails {
  client: IClient;
  clientFile: IClientFile;
  activeAuths: IAuthorization[];
  futureAuths: IAuthorization[];
  primaryPayer: IPayer | null;
  secondaryPayer: IPayer | null;
  primaryInsurancePlan: IInsurancePlan | null;
  secondaryInsurancePlan: IInsurancePlan | null;
  clientAvailability?: IClientAvailability;
  incidentReports?: IIncidentReport[];
}

/**
 * This consolidates all approval appointments information into one object
 * Right now, it consists of:
 * 1. approvableAppointments
 * 2. missingNoteAppointments
 * 3. pendingIndirects
 * 4. pendingCancellations
 */
export interface IApprovals {
  approvableAppointments: IAppointment[];
  missingNoteAppointments: IAppointment[];
  pendingIndirects: IIndirect[];
  pendingCancellations: ICompletedAppointment[];
}

/**
 * This consolidates all payroll appointments information into one object
 * Right now, it consists of:
 * 1. users
 * 2. completedAppointments
 * 3. indirects
 */
export interface IPayrollRawData {
  users: IUser[];
  completedAppointments: ICompletedAppointment[];
  indirects: IIndirect[];
}

/**
 * This consolidates all client-related information + guardian into one object
 * Note: The client object is not optional
 */
export interface IClientGuardianDetails extends IClientDetails {
  guardian: IGuardian;
}

/**
 * This consolidates all client-related information + guardian into one object
 * Note: The difference between this and `IClientGuardianDetails` is that clients can be optional
 */
export interface IGuardianClientDetails extends Partial<IClientDetails> {
  client: IClient;
  activeAuths: IAuthorization[];
  futureAuths: IAuthorization[];
  clientFile: IClientFile;
  guardian: IGuardian;
  primaryInsurancePlan: IInsurancePlan | null;
  secondaryInsurancePlan: IInsurancePlan | null;
  primaryPayer: IPayer | null;
  secondaryPayer: IPayer | null;
}

export interface ISelectedPlace {
  label: string;
  value: ISelectedPlaceValue;
}

export interface ISelectedPlaceValue {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  terms: Array<{
    offset: number;
    value: string;
  }>;
  types: string[];
}

// Start of Orbit types
// TODO: Move Orbit types to a separate file.
export const signatureSchema = z.object({
  signedAt: z.number(),
  fullName: z.string().nullable(),
  dataURL: z.string().nullable(),
});

export type ISignature = z.input<typeof signatureSchema>;

export enum MeasurementType {
  TRIAL = "TRIAL-BY-TRIAL",
  PROMPT = "PROMPT",
  TASK_ANALYSIS = "TASK ANALYSIS",
  COUNT = "COUNT",
  DURATION = "DURATION",
  TEXT = "TEXT",
  RATE = "RATE",
  ABC = "ABC DATA",
}

export enum PromptOptionStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type IPromptOption = z.input<typeof promptOptionSchema>;

export const promptDataSchema = z.object({
  label: z.string(),
  score: z.number(),
});

export type IPromptData = z.input<typeof promptDataSchema>;

export const promptOptionSchema = z.object({
  id: z.string(),
  promptData: promptDataSchema,
  measurementDefinitionId: z.string(),
  status: z.nativeEnum(PromptOptionStatus),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
  createdBy: z.string(),
});

export const promptMeasurementHistorySchema = z.object({
  sessionId: z.string(),
  label: z.string(),
  score: z.number(),
  promptOptionId: z.string(),
});

export type IPromptMeasurementHistory = z.input<typeof promptMeasurementHistorySchema>;

export const taskAnalysisDataSchema = z.object({
  tasks: z.array(z.string()),
});

export enum RateMeasurementUnit {
  PER_MINUTE = "per minute",
  PER_HOUR = "per hour",
}

// to do @simrun: be removed after migration
export const oldPromptDataSchema = z.object({
  options: z.array(promptDataSchema),
});

export const measurementDefinitionSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(MeasurementType),
  affectsOutcome: z.boolean(),
  promptData: oldPromptDataSchema.nullable(), // to do @simrun: be removed after migration
  taskAnalysisData: taskAnalysisDataSchema.nullable(),
  rateMeasurementUnit: z.nativeEnum(RateMeasurementUnit).nullable().optional(),
  autoStart: z.boolean().nullable().optional(),
  abcCategories: z
    .object({
      antecedent: z.array(z.string()),
      behavior: z.array(z.string()),
      consequence: z.array(z.string()),
    })
    .nullish(),
});

export type IMeasurementDefinition = z.input<typeof measurementDefinitionSchema>;

export enum MeasurementCalculationType {
  PERCENTAGE = "PERCENTAGE",
  DURATION = "DURATION",
  SUM = "SUM",
  RATE = "RATE",
}

export enum BILLING_TABS {
  PENDING = "pending",
  FINALIZED = "finalized",
  ONHOLD = "on-hold",
  RATES = "rates",
}

export enum ProgramTargetStatus {
  NOT_INTRODUCED = "NOT INTRODUCED",
  BASELINE = "BASELINE",
  IN_PROGRESS = "IN PROGRESS",
  MAINTAIN = "MAINTAIN",
  MASTERED = "MASTERED",
  ALREADY_KNOWN = "ALREADY KNOWN",
  ON_HOLD = "ON HOLD",
  DEACTIVATED = "DEACTIVATED",
}

export const targetIntervalSchema = z.object({
  startTimeMs: z.number(),
  totalMs: z.number(),
  remainingMs: z.number().nullable(),
  applyInterval: z.boolean(),
  isPaused: z.boolean(),
});

export type ITargetInterval = z.input<typeof targetIntervalSchema>;

export const targetSchema = z.object({
  id: z.string(),
  groupId: z.string().nullable(),
  name: z.string(),
  description: z.string(),
  status: z.nativeEnum(ProgramTargetStatus),
  interval: z.nullable(targetIntervalSchema).optional(),
  minTrialCount: z.nullable(z.number()).optional(),
  maxTrialCount: z.nullable(z.number()).optional(),
  measurementDefinitions: z.array(measurementDefinitionSchema),
  progressNote: z.string().nullable(),
  createdBy: z.string(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
  displayOrder: z.number().optional(),
});

export const targetGroupDocumentSchema = z.object({
  createdAt: z.number(),
  deletedAt: z.number().nullable(),
  id: z.string(),
  measurementTypes: z.array(z.nativeEnum(MeasurementType)),
  targetGroupName: z.string(),
  updatedAt: z.number(),
});

export type ITarget = z.input<typeof targetSchema>;
export type ITargetGroupDocument = z.input<typeof targetGroupDocumentSchema>;
export type ITargetWithChains = ITarget & {
  targetChains: ITargetChain[];
};

export interface IActiveTarget {
  target: ITarget;
  programId: string;
}

export enum ProgramCategory {
  SKILL_ACQUISITION = "SKILL ACQUISITION",
  BEHAVIOR_TRACKING = "BEHAVIOR TRACKING",
}

const programCommonSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  tags: z.array(z.string()),
  category: z.nativeEnum(ProgramCategory),
  progressNote: z.string().nullable(),
  createdBy: z.string(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export const programTemplateSchema = programCommonSchema.extend({
  folder: z.array(z.string()),
});

export type IProgramTemplate = z.input<typeof programTemplateSchema>;

export const programSchema = programCommonSchema.extend({
  templateId: z.string().nullable(),
  clientId: z.string(),
  clinicId: z.string(),
  status: z.nativeEnum(ProgramTargetStatus),
});

export type IProgram = z.input<typeof programSchema>;

export const statusTransitionSchema = z.object({
  id: z.string(),
  transitionedAt: z.number(),
  status: z.nativeEnum(ProgramTargetStatus),
  createdBy: z.string().nullable(),
  transitionCriteriaId: z.string().nullable(),
  triggerSessionId: z.string().nullable(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type IStatusTransition = z.input<typeof statusTransitionSchema> & {
  transitionCriteria?: ITransitionCriteria;
};

export const transitionCriteriaSchema = z.object({
  id: z.string(),
  targetIds: z.array(z.string()),
  fromStatuses: z.array(z.nativeEnum(ProgramTargetStatus)),
  toStatus: z.nativeEnum(ProgramTargetStatus),
  minNumTrials: z.number(),
  maxNumTrials: z.number().nullable(),
  minScore: z.number().nullable(),
  maxScore: z.number().nullable(),
  numSessions: z.number(),
  isNumSessionsConsecutive: z.boolean().nullable(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type ITransitionCriteria = z.input<typeof transitionCriteriaSchema>;

export const targetChainSchema = z.object({
  id: z.string(),
  triggerTargetId: z.string(),
  triggerStatus: z.nativeEnum(ProgramTargetStatus),
  appliedTargetIds: z.array(z.string()),
  appliedStatus: z.nativeEnum(ProgramTargetStatus),
  metAt: z.number().nullable(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type ITargetChain = z.input<typeof targetChainSchema>;

export interface ITargetGroup {
  groupId: string | null;
  targets: ITarget[];
}

export interface ITargetGroupWithName extends ITargetGroup {
  targetGroupName?: string;
  targetGroupDocument?: ITargetGroupDocument;
  programId?: string;
}
export interface IProgramWithTargets extends IProgram {
  targets: ITarget[];
  targetGroups?: ITargetGroupDocument[];
}

export interface IProgramExtended extends IProgramWithTargets {
  transitionCriteria?: ITransitionCriteria[];
  targetChains?: ITargetChain[];
  lastRunAt?: number;
  groupedTargets?: ITargetGroupWithName[];
}

export interface IProgramTemplateWithTargets extends IProgramTemplate {
  targets: ITarget[];
  targetGroups: ITargetGroupWithName[];
}

export interface IProgramTemplateWithTargetsTransitionCriteria extends IProgramTemplate {
  targets: ITarget[];
  transitionCriteria: ITransitionCriteria[];
  targetChains: ITargetChain[];
}

export interface IProgramWithTargetGroups extends IProgram {
  targetGroups: ITargetGroupWithName[];
  lastRunAt?: number;
}

export interface IProgramTemplateWithTargetGroups extends IProgramTemplate {
  targetGroups: ITargetGroupWithName[];
}

export const transitionExplanationSchema = z.object({
  criteriaId: z.string(),
  explanations: z.array(z.string()),
});

export type ITransitionExplanation = z.input<typeof transitionExplanationSchema>;

export const transitionExplanationsWithTargetIdSchema = z.record(
  z.string(), // targetId
  z.array(transitionExplanationSchema)
);
export type ITransitionExplanationsWithTargetId = z.input<
  typeof transitionExplanationsWithTargetIdSchema
>;

export const sessionSchema = z.object({
  id: z.string(),
  clientId: z.string(),
  clinicId: z.string(),
  appointmentIds: z.array(z.string()),
  userId: z.string(),
  startMs: z.number(),
  endMs: z.number().nullable(),
  transitionExplanations: transitionExplanationsWithTargetIdSchema.nullable(),
  deletedMeasurementIds: z.array(z.string()).nullish(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
  isExternalSession: z.boolean().optional(),
});

export type ISession = z.input<typeof sessionSchema>;

export interface ISessionWithAppointment extends ISession {
  appointment: IAppointment;
}

export const dataPointSchema = z.object({
  measurementDefinitionId: z.string(),
  value: z.any(),
});

export type IDataPoint = z.input<typeof dataPointSchema>;

export type IDataPointWithDefinition = IDataPoint & {
  // Joining the data and definition for the measurement
  definition: IMeasurementDefinition;
};

export const eventLineTargetScopeSchema = z.object({
  programId: z.string(),
  targetIds: z.array(z.string()),
});

export type IEventLineTargetScope = z.input<typeof eventLineTargetScopeSchema>;

export const eventLineSchema = z.object({
  programIds: z.array(z.string()),
  targetScope: z.array(eventLineTargetScopeSchema).optional(),
  label: z.string(),
  description: z.string(),
  globalScope: z.array(z.nativeEnum(ProgramCategory)),
  dateMs: z.number(),
  id: z.string(),
  clientId: z.string(),
  clinicId: z.string(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type IEventLine = z.input<typeof eventLineSchema>;

// Measurement is a subcollection of Session.
export const measurementSchema = z.object({
  id: z.string(),
  programId: z.string(),
  targetId: z.string(),
  userId: z.string(),
  isDraft: z.boolean(),
  data: z.array(dataPointSchema),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type IMeasurement = z.input<typeof measurementSchema>;

export type IDurationMeasurementType = {
  startTimeMs: number | null;
  totalMs: number | null;
};

export type IRateMeasurementType = {
  duration: IDurationMeasurementType;
  count: number;
};

export type IPromptMeasurementType = {
  promptOptionId: string;
  score: number;
  label: string;
};

export enum ITaskAnalysisValue {
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete",
  UNKNOWN = "Unknown",
}

export type ABCMeasurementValue = {
  antecedent: {
    categories: string[];
    notes: string;
  };
  behavior: {
    categories: string[];
    notes: string;
  };
  consequence: {
    categories: string[];
    notes: string;
  };
  wasIncidentDuringSession: boolean;
  incidentDateMs?: number;
  reportedBy: string;
  reportedByOther: string;
};

export type ABCMeasurementFormSection = {
  antecedent: {
    categories: string[];
    notes: string;
  };
  behavior: {
    categories: string[];
    notes: string;
  };
  consequence: {
    categories: string[];
    notes: string;
  };
  wasIncidentDuringSession: boolean;
  incidentDate?: Moment;
  incidentTime?: Moment;
  reportedBy: string;
  reportedByOther: string;
};

// Unused type, but serves as a reference
export type MeasurementValueMapping = {
  [MeasurementType.DURATION]: IDurationMeasurementType;
  [MeasurementType.COUNT]: number;
  [MeasurementType.RATE]: IRateMeasurementType;
  [MeasurementType.TEXT]: string;
  [MeasurementType.TRIAL]: boolean;
  [MeasurementType.PROMPT]: string;
  [MeasurementType.TASK_ANALYSIS]: ITaskAnalysisValue[];
  [MeasurementType.ABC]: ABCMeasurementValue;
};

export const NULLABLE_MEASUREMENTS = [MeasurementType.COUNT, MeasurementType.DURATION] as const;
export type NullableMeasurement = (typeof NULLABLE_MEASUREMENTS)[number];

export const NULLABLE_MEASUREMENT_DEFAULT_VALUES: {
  [K in NullableMeasurement]: MeasurementValueMapping[K];
} = {
  [MeasurementType.DURATION]: {
    startTimeMs: null,
    totalMs: 0,
  },
  [MeasurementType.COUNT]: 0,
};

export type ITaskAnalysisMeasurementType = ITaskAnalysisValue[];

export interface IMeasurementWithSessionId extends IMeasurement {
  sessionId: string;
}

export interface IMeasurementWithSessionIdAndDefinitions extends IMeasurementWithSessionId {
  data: IDataPointWithDefinition[];
}

export interface ITargetWithMeasurements extends ITarget {
  measurements: IMeasurementWithSessionId[];
  statusTransitions?: IStatusTransition[];
}

export interface IProgramWithMeasurements extends IProgram {
  targets: ITargetWithMeasurements[];
}

/**
 * ISessionWithMeasurements
 *   session metadata
 *   program[]
 *     program metadata
 *     target[]
 *       target metadata
 *       measurement[]
 */
export interface ISessionWithMeasurements extends ISession {
  programs: IProgramWithMeasurements[];
}

export interface ISessionDetails {
  session: ISessionWithMeasurements;
  client: IClient;
  user: IUser;
  appointment: IAppointment;
  note: INote;
}

// End of Orbit types

export interface IExpectedRevenue {
  units: number;
  chargeCents: number;
  payerInfo: {
    name: string;
    unitRate: number;
  };
}

export interface IExpectedRevenueResponse {
  units: number;
  chargeCents: number;
  payerInfo: {
    payer: IPayer;
    unitRate: number;
    modifiers: Modifier[];
    payerId: string;
    state: USStateCode;
    providerType: ProviderType;
  };
}

export const appointmentAuditResultSchema = z.object({
  narrative: z.string(),
  isAuditViolation: z.boolean(),
  auditResultMessage: z.string().optional(),
  appointmentId: z.string(),
  clinicId: z.string(),
  noteId: z.string(),
  billingCode: z.string(),
  modifiers: z.array(z.nativeEnum(Modifier)),
  attendees: z.array(z.object({ email: z.string(), status: z.nativeEnum(AttendeeStatus) })),
  renderingUserId: z.string(),
  location: z.string(),
  startMs: z.number(),
  endMs: z.number(),
  createdAt: z.number(),
  updatedAt: z.number(),
});

export type IAppointmentAuditResult = z.input<typeof appointmentAuditResultSchema>;
export interface IInterval {
  startMs: number;
  endMs: number;
  eventDetail?: string;
  appointmentId?: string;
  clinicName?: string;
}

export enum PlyEnrollmentStatus {
  PENDING_GROUP = "pending_group",
  PENDING_INDIVIDUAL = "pending_individual",
  IN_NETWORK = "in_network",
  COMPLETE = "complete",
  PAYER_PROCESSING = "payer_processing",
  DEPENDENCY_REQUIRED = "dependency_required",
  INPUT_NEEDED = "input_needed",
  CLOSED = "closed",
  DENIED = "denied",
  WORKING = "working",
}

export const plyAddressSchema = z.object({
  line1: z.string(),
  line2: z.string().optional(),
  city: z.string(),
  state: z.nativeEnum(USStateCode),
  country: z.nativeEnum(CountryCode).optional(),
  zipCode: z.string().optional(),
  zip: z.string().optional(),
});

export type IPlyAddress = z.input<typeof plyAddressSchema>;

// TODO: this should be updated with all the possible enums, once Ply informs us more about their data
export const plyLineOfBusinessSchema = z.object({
  type: z.string(),
  status: z.nativeEnum(PlyEnrollmentStatus),
  effective_date: z.string().nullable(),
});

export type IPlyLineOfBusiness = z.input<typeof plyLineOfBusinessSchema>;

export const enrollmentStatusSchema = z.object({
  practice: z.string(),
  payer_name: z.string(),
  payer_id: z.string().optional(),
  payer_ids: z.array(z.string()).optional(),
  state: z.nativeEnum(USStateCode),
  status: z.union([z.nativeEnum(PlyEnrollmentStatus), z.array(z.nativeEnum(PlyEnrollmentStatus))]),
  effective_date: z.string().nullable(),
  locations: z.array(plyAddressSchema).optional(),
  lines_of_business: z.array(plyLineOfBusinessSchema).optional(),
});

export type IPlyServiceEnrollmentStatus = z.input<typeof enrollmentStatusSchema>;

export enum LibraryDocumentType {
  LIBRARY = "library",
  FOLDER = "folder",
  PROGRAM_TEMPLATE = "program-template",
}

export const baseLibraryDocumentSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  ownedBy: z.string(),
  createdBy: z.string(),
  createdAt: z.instanceof(Timestamp),
  updatedAt: z.instanceof(Timestamp),
  deletedAt: z.instanceof(Timestamp).nullable(),
  type: z.nativeEnum(LibraryDocumentType),
  documentReference: z
    .custom<DocumentReference>((data) => data instanceof DocumentReference)
    .nullish(), // firestore DocumentReference
  isPublic: z.boolean().optional(),
  clinicId: z.string().optional(),
});

export type ILibraryDocument = z.input<typeof baseLibraryDocumentSchema>;

export const libraryTagSchema = z.object({
  id: z.string(),
  name: z.string(),
  taggedDocumentReferences: z.array(
    z.custom<DocumentReference>((data) => data instanceof DocumentReference)
  ),
});

export type ILibraryTag = z.input<typeof libraryTagSchema>;

export enum AppName {
  MISSION_CONTROL = "mission_control",
  DEN = "den",
  ORBIT = "orbit",
  PARENT_PORTAL = "parent_portal",
}

//  * User-friendly display names for applications.
//  * Used for analytics tracking and UI display purposes.
//  * @see AppName for technical identifiers

export enum AppTitle {
  MISSION_CONTROL = "Mission Control",
  DEN = "Den",
  ORBIT = "Orbit",
  PARENT_PORTAL = "Parent Portal",
}

export interface ITargetMeasurementCheck {
  targetId: string;
  targetName: string;
  programName: string;
  currentCount: number;
  minCount: number;
}

export type ObjectWithLabel = { value: string; label: string };

export enum KlaviyoEventKey {
  NewInvite = "new-invite",
  NewGuardian = "new-guardian",
  ConvertedInquiry = "converted-inquiry",
  InsuranceReminder = "insurance-reminder",
  OnboardingReminder = "onboarding-reminder",
  MissingNoteActionReminder = "missing-note-action-reminder",
  IncidentReport = "incident-report-submitted",
}

export type MissingActionFilterType =
  | "narrative"
  | "providerSignature"
  | "guardianSignature"
  | undefined;

export enum MissingActionEventType {
  ProviderSignature = "providerSignature",
  GuardianSignature = "guardianSignature",
  NarrativeMissing = "narrative",
}

export type GuardrailErrors = Record<string, string[]>;
