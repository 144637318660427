import { omitBy } from "lodash";

// Stronger typing for Object.keys
export function keys<O extends object>(o: O) {
  return Object.keys(o) as (keyof O)[];
}

export function isTruthy<T>(value: T | null | undefined): value is T {
  return Boolean(value);
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function hasProperty<T, K extends string>(
  obj: T,
  property: K
): obj is T & Record<K, unknown> {
  return typeof obj === "object" && obj !== null && property in obj;
}

export function arrayIncludes<T, TArray extends readonly T[]>(
  array: TArray,
  element: T
): element is TArray[number] {
  return array.includes(element);
}

export type OmitUndefined<T> = {
  [K in keyof T]: T[K] extends undefined ? never : T[K];
};

export function omitUndefinedProperties<T extends Record<string, any>>(data: T) {
  return omitBy(data, (value) => value === undefined) as OmitUndefined<T>;
}

export type Nullable<T> = { [K in keyof T]: T[K] | null };
export type Defined<T> = T extends undefined ? never : T;

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};
