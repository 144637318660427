import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { updateUserPayerBillingHoldEndPointRequestSchema } from "../endpoints";
import {
  IUser,
  KlaviyoEventKey,
  MissingActionEventType,
  onboardingStepKeys,
  onboardingStepStatuses,
  UserPermission,
} from "../types";

const c = initContract();

export const usersContract = c.router({
  updateUserPermission: {
    method: "POST",
    path: "/users/update-permission",
    body: z.object({
      id: z.string(),
      permission: z.nativeEnum(UserPermission),
    }),
    responses: {
      200: z.string(),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  updateUserPayerBillingHold: {
    method: "PATCH",
    path: "/users/update-user-payer-billing-hold",
    body: updateUserPayerBillingHoldEndPointRequestSchema,
    responses: {
      200: z.object({
        success: z.boolean(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getUserByEmail: {
    method: "GET",
    path: "/users/get-by-email",
    query: z.object({
      email: z.string(),
    }),
    responses: {
      200: c.type<{ user: IUser }>(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  sendOnboardingReminders: {
    method: "POST",
    path: "/users/send-onboarding-reminders",
    body: z.object({
      userIds: z.array(z.string()),
      clinicId: z.string(),
    }),
    responses: {
      200: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  sendAppointmentActionReminders: {
    method: "POST",
    path: "/users/send-appointment-action-reminders",
    body: z.object({
      userId: z.string(),
      clinicId: z.string(),
      eventKey: z.nativeEnum(KlaviyoEventKey),
      clientIds: z.array(z.string()).optional(),
      eventType: z.nativeEnum(MissingActionEventType),
      userNoteInfo: z
        .array(
          z.object({
            noteLink: z.string(),
            noteDateTime: z.string(),
          })
        )
        .optional(),
    }),
    responses: {
      200: z.object({
        message: z.string(),
      }),
      404: z.object({
        message: z.string(),
      }),
      403: z.object({
        message: z.string(),
      }),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  updateUserOnboardingStep: {
    method: "POST",
    path: "/users/update-user-onboarding-step",
    body: z.object({
      id: z.string(),
      stepKey: onboardingStepKeys,
      status: onboardingStepStatuses,
    }),
    responses: {
      200: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  notifyAllUsersMissingAppointmentActions: {
    method: "POST",
    path: "/users/notify-all-users-missing-appointment-actions",
    body: z.object({}),
    responses: {
      200: z.object({
        message: z.string(),
      }),
      400: z.object({
        message: z.string(),
        context: z.record(z.unknown()).optional(),
        cause: z.unknown(),
      }),
      404: z.object({
        message: z.string(),
        context: z.record(z.unknown()).optional(),
        cause: z.unknown(),
      }),
      409: z.object({
        message: z.string(),
        context: z.record(z.unknown()).optional(),
        cause: z.unknown(),
      }),
      500: z.object({
        message: z.string(),
        context: z.record(z.unknown()).optional(),
        cause: z.unknown(),
      }),
    },
  },
  notifyHRAndProviderSuccessAboutIncident: {
    method: "POST",
    path: "/users/notify-hr-and-provider-success-about-incident",
    body: z.object({
      dateOfIncident: z.string(),
      typeOfIncident: z.string(),
      incidentFormLink: z.string(),
      clinicDisplayName: z.string(),
      clinicId: z.string(),
      hasBeenSubmittedBefore: z.boolean(),
    }),
    responses: {
      200: z.object({
        message: z.string(),
      }),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
