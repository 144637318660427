import {
  CountryCode,
  IAddress,
  IClientFile,
  IUpdateClientEndpointRequest,
  USStateCode,
} from "@finni-health/shared";
import { PlacesAutocompleteInput } from "@finni-health/ui";
import { Button, Card, Form, Input, Row, Select, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";

import { ClientFileIntakeQuestionnaireStates, VALID_ZIP_CODE_REGEX } from "../../../consts";
import { AuthContext } from "../../AuthProvider";

const { Title, Text } = Typography;

type IProps = {
  isMobile: boolean;
  tempClientFile: IClientFile;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
};

export const EnterTherapyLocation: React.FC<IProps> = ({
  isMobile,
  tempClientFile,
  updateClient,
  setClientFileIntakeQuestionnaireStatus,
}: IProps) => {
  const { client } = useContext(AuthContext);

  const [form] = Form.useForm<IAddress>();
  const [address, setAddress] = useState<IAddress>({} as IAddress);
  const [addressNotes, setAddressNotes] = useState<string>("");

  useEffect(() => {
    setAddress({ ...tempClientFile.address, ...address });
    setAddressNotes(tempClientFile.addressNotes || addressNotes);
  }, [tempClientFile]);

  return (
    <Card
      title={
        <>
          <Title
            level={5}
            style={{
              marginBottom: 10,
              fontWeight: 400,
              whiteSpace: "pre-line",
            }}
          >
            What is the primary address at which your child will receive therapy?
          </Title>
        </>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Form
        form={form}
        layout="vertical"
        labelCol={{ span: 24 }}
        onFinish={async () => {
          await updateClient({
            id: client.id,
            address: { ...address, country: CountryCode.US },
            addressNotes,
          });
          setClientFileIntakeQuestionnaireStatus(
            ClientFileIntakeQuestionnaireStates.ENTER_PRIMARY_LANGUAGE
          );
        }}
      >
        <Text
          type="secondary"
          style={{
            fontSize: 14,
            whiteSpace: "pre-line",
          }}
        >
          Primary Address of Therapy
        </Text>
        <Form.Item
          label="Line 1"
          name="line1"
          rules={[
            {
              required: true,
              message: "Please enter your child's primary therapy location's line 1",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 10,
          }}
          initialValue={tempClientFile.address?.line1}
        >
          <PlacesAutocompleteInput
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY as string}
            initialAddressLine1={tempClientFile?.address?.line1}
            onChange={(fullAddress: IAddress) => {
              setAddress({
                ...address,
                ...fullAddress,
              });
              form.setFieldsValue(fullAddress);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Line 2 (optional)"
          name="line2"
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
          initialValue={tempClientFile.address?.line2}
        >
          <Input
            onChange={(e) => {
              setAddress({
                ...address,
                line2: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: "Please enter your child's primary therapy location's city",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
          initialValue={tempClientFile.address?.city}
        >
          <Input
            onChange={(e) => {
              setAddress({
                ...address,
                city: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: "Please enter your child's primary therapy location's state",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
          initialValue={tempClientFile.address?.state}
        >
          <Select
            showSearch
            onChange={(value: USStateCode) => {
              setAddress({
                ...address,
                state: value,
                country: CountryCode.US,
              });
            }}
          >
            {Object.values(USStateCode).map((state) => (
              <Select.Option value={state} key={state}>
                {state}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[
            {
              required: true,
              message: "Please enter your child's primary therapy location's country",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
          initialValue={CountryCode.US}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Zip Code"
          name="zipCode"
          rules={[
            {
              required: true,
              pattern: VALID_ZIP_CODE_REGEX,
              message: "Please enter your child's primary therapy location's zip code",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
          initialValue={tempClientFile.address?.zipCode}
        >
          <Input
            maxLength={5}
            onChange={(e) => {
              setAddress({
                ...address,
                zipCode: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Additional Notes (optional)"
          name="notes"
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
          initialValue={tempClientFile.addressNotes}
        >
          <TextArea
            autoSize
            placeholder="Let us know about additional details for your child's therapy location preferences, including secondary locations, if any."
            onChange={(e) => {
              setAddressNotes(e.target.value);
            }}
          />
        </Form.Item>

        <Row justify="end">
          <Button htmlType="submit" type="primary" shape="round">
            Next
          </Button>
        </Row>
      </Form>
    </Card>
  );
};
