import { SOUTH_NM_CLINIC_ID, TEST_CLINIC_ID } from "../consts";
import { IClient, IGuardian, IUser } from "../types";

export enum Features {
  CALENDAR_REDESIGN = "Calendar Redesign",
  PERFORMANCE_REPORT = "Performance Report",
  NEW_AUTH_FLOW = "New Auth Flow",
  NEW_NOTES_TAB = "New Notes Tab",
}

type FeatureFlagRule = {
  clinicIds: string[];
  enabled: boolean;
};

type FeatureFlagConfig = {
  rules: FeatureFlagRule[];
  default: boolean;
};

// Rules are evaluated in order. The first rule match will have the specified
// value returned. If no rules match, "default" is returned
const FEATURE_FLAG_CONFIGS: Record<Features, FeatureFlagConfig> = {
  [Features.CALENDAR_REDESIGN]: {
    rules: [
      {
        clinicIds: [
          TEST_CLINIC_ID,
          "5S52WtvIBJXkxGEtamvE", // Arc Light
          "mHwkuIeevA01LDZRDrA9", // inspire ABA - Nicole
          "GA0AUq560NZ1XSx3IyBb", // ABA.CO
          "CGJj8XTcQuv8EfonTKxC", // Roots
          "gdB7JMdmKTYzDsel2qgI", // affinity ABA - Alexa/Jenna
          "7MBfmyWMF6sqcke6XPAI", // sparkJoy ABA
          "BHNOOve0y22x4gwTzrr4", // Spicer Exceptional ABA
          "UUGS5EnyZ6tgcSuZ5s2J", // Chockstone Behavioral
          "pJQTyDvchBiZmpTGVLHc", // Beauty and the Behavior
          "k9Da5Qs6StM2q87s6VkR", // pivot ABA
          "bZXsjPDAU7c8AYLzbruR", // Big Bear
          "8OYj0EdqefRLbmfi8y5w", // Lexie's practice - Lexie
        ],
        enabled: true,
      },
    ],
    default: false,
  },
  [Features.PERFORMANCE_REPORT]: {
    rules: [],
    default: true,
  },
  [Features.NEW_AUTH_FLOW]: {
    rules: [
      {
        clinicIds: [TEST_CLINIC_ID, SOUTH_NM_CLINIC_ID],
        enabled: true,
      },
    ],
    default: false,
  },
  [Features.NEW_NOTES_TAB]: {
    rules: [],
    default: true,
  },
};

export function evaluateFlag<TFeature extends Features>(
  feature: TFeature,
  userOrGuardianOrClient?: Pick<IUser | IGuardian | IClient, "clinicId">
) {
  const config = FEATURE_FLAG_CONFIGS[feature];
  for (const rule of config.rules) {
    if (rule.clinicIds.includes(userOrGuardianOrClient?.clinicId || "")) {
      return rule.enabled;
    }
  }
  return config.default;
}
