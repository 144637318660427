import { z } from "zod";

import { CountryCode, USStateCode } from "./common";
import { PlyEnrollmentStatus } from "./general";

export const enrollmentAddressSchema = z.object({
  line1: z.string(),
  line2: z.string().optional(),
  city: z.string(),
  state: z.nativeEnum(USStateCode),
  status: z.string().optional(),
  country: z.nativeEnum(CountryCode).optional(),
  zipCode: z.string().optional(),
  zip: z.string().optional(),
});

export type IEnrollmentAddress = z.infer<typeof enrollmentAddressSchema>;

export const enrollmentSchema = z.object({
  id: z.string(),
  payerName: z.string(),
  payerEdiId: z.string().nullable(),
  payerDocumentId: z.string().nullable(),
  userId: z.string(),
  state: z.nativeEnum(USStateCode),
  status: z.nativeEnum(PlyEnrollmentStatus),
  serviceAddress: enrollmentAddressSchema.nullish(),
  effectiveMs: z.number().nullable(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type IEnrollment = z.infer<typeof enrollmentSchema>;
