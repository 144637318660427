import { FieldValue, Timestamp } from "firebase/firestore";
import { z } from "zod";

export enum PayerVerificationType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  UPCOMING_PRIMARY = "upcomingPrimary",
}

export enum PayerVerificationStatusEnum {
  CompleteEligible = "complete.eligible",
  CompleteIneligible = "complete.ineligible",
  ErrorMemberId = "error.member.id",
  ErrorMemberDob = "error.member.dob",
  ErrorMemberName = "error.member.name",
  ErrorPayer = "error.payer",
  ErrorPayerNpi = "error.payer.npi",
  ErrorSohar = "error.sohar",
  Pending = "pending",
}

export enum InsuranceTypeCode {
  MEDICAID = "48",
  COMMERCIAL = "C1",
  OTHER = "Other",
}

export type PayerVerificationStatus =
  | "complete.eligible"
  | "complete.ineligible"
  | "error.member.id"
  | "error.member.dob"
  | "error.member.name"
  | "error.payer"
  | "error.payer.npi"
  | "error.sohar"
  | "pending";

/**
 * @deprecated: This model is no longer in use, but exists in firestore under clients/payer-verification
 */
export interface IPayerVerification {
  carveOut: {
    payerId: string;
    payerName: string;
  } | null;
  coverageEndDate: string | null;
  coverageStartDate: string | null;
  inn: {
    coinsurance: number | null;
    copay: number | null;
    cost: number | null;
    priorAuthRequired: boolean;
    remainingDeductible: number | null;
    totalDeductible: number | null;
  };
  memberId: string;
  oon: {
    coinsurance: number | null;
    copay: number | null;
    cost: number | null;
    priorAuthRequired: boolean;
    remainingDeductible: number | null;
    totalDeductible: number | null;
  };
  patientId: string;
  payerId: string;
  payerName: string;
  planName: string | null;
  status: PayerVerificationStatusEnum;
  timestamp: string;
  verificationId: string;
  insuranceTypeCode?: InsuranceTypeCode | null;
  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
}

export const payerVerificationWebhookResponseSchema = z.object({
  carveOut: z
    .object({
      payerId: z.string(),
      payerName: z.string(),
    })
    .nullable(),
  coverageEndDate: z.string().nullable(),
  coverageStartDate: z.string().nullable(),
  inn: z.object({
    coinsurance: z.number().nullable(),
    copay: z.number().nullable(),
    cost: z.number().nullable(),
    priorAuthRequired: z.boolean(),
    remainingDeductible: z.number().nullable(),
    totalDeductible: z.number().nullable(),
  }),
  memberId: z.string(),
  oon: z.object({
    coinsurance: z.number().nullable(),
    copay: z.number().nullable(),
    cost: z.number().nullable(),
    priorAuthRequired: z.boolean(),
    remainingDeductible: z.number().nullable(),
    totalDeductible: z.number().nullable(),
  }),
  patientId: z.string(),
  payerId: z.string(),
  payerName: z.string(),
  planName: z.string().nullable(),
  status: z.nativeEnum(PayerVerificationStatusEnum),
  timestamp: z.string(),
  verificationId: z.string(),
  insuranceTypeCode: z.nativeEnum(InsuranceTypeCode).or(z.string()).optional().nullable(),
  id: z.string().optional().nullable(),
});

export const payerVerificationWebhookUpdateEndpointRequestSchema =
  payerVerificationWebhookResponseSchema.extend({
    type: z.nativeEnum(PayerVerificationType),
    clientFileId: z.string(),
  });

export const createPayerVerificationSchema = payerVerificationWebhookResponseSchema;

export type IPayerVerificationUpdateEndpointRequest = z.input<
  typeof payerVerificationWebhookUpdateEndpointRequestSchema
>;

export type ICreatePayerVerification = z.input<typeof createPayerVerificationSchema>;

export type IPayerVerificationWebhookUpdateEndpointRequest = z.input<
  typeof payerVerificationWebhookResponseSchema
>;
