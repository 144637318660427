import {
  BehaviorFrequency,
  BehaviorSeverity,
  IUpdateClientEndpointRequest,
} from "@finni-health/shared";
import { Button, Card, Radio, Row, Slider, Space, Typography } from "antd";
import React, { ReactNode, useContext } from "react";

import { frequencyMarks, selected, unselected } from "../../../consts";
import { getBehaviorFrequency } from "../../../helpers/get-behavior-frequency";
import { getBehaviorFrequencyValue } from "../../../helpers/get-behavior-frequency-value";
import { AuthContext } from "../../AuthProvider";

const { Title, Text } = Typography;

type IProps = {
  isMobile: boolean;
  tempBehaviors: any;
  setTempBehaviors: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterAggressionFrequency: React.FC<IProps> = ({
  isMobile,
  tempBehaviors,
  setTempBehaviors,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClient,
  backButton,
}: IProps) => {
  const { client } = useContext(AuthContext);

  return (
    <Card
      title={
        <>
          <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
            Is your child aggressive towards others?
          </Title>
          <Text type="secondary" style={{ fontSize: 14, whiteSpace: "pre-line" }}>
            This includes threatening, hitting, pinching, kicking, spitting, and biting.
          </Text>
        </>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.aggression !== undefined ? selected : unselected}
          onClick={() => {
            setTempBehaviors({
              ...tempBehaviors,
              aggression: {
                ...tempBehaviors.aggression!,
                frequency: tempBehaviors.aggression?.frequency || BehaviorFrequency.MONTHLY,
              },
            });
          }}
        >
          Yes
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.aggression === undefined ? selected : unselected}
          onClick={() => {
            const newTempBehaviors = tempBehaviors;
            delete newTempBehaviors.aggression;
            setTempBehaviors({ ...newTempBehaviors });
          }}
        >
          No
        </Button>
      </Space>
      {tempBehaviors.aggression !== undefined && (
        <>
          Severity
          <Radio.Group
            style={{ marginBottom: 25 }}
            onChange={(e) => {
              setTempBehaviors({
                ...tempBehaviors,
                aggression: {
                  ...tempBehaviors.aggression!,
                  severity: e.target.value,
                },
              });
            }}
            value={tempBehaviors.aggression.severity}
          >
            <Space direction="vertical">
              <Radio value={BehaviorSeverity.LOW}>
                No marks on body, no burns, no choking, no targeting eyes
              </Radio>
              <Radio value={BehaviorSeverity.MEDIUM}>
                Reddening of skin or mild swelling, mild gagging, 1st degree burns
              </Radio>
              <Radio value={BehaviorSeverity.HIGH}>
                Light scratches, small or shallow breaks in skin, moderate to severe swelling, 2nd
                degree burns, vomiting or significant choking
              </Radio>
              <Radio value={BehaviorSeverity.EXTREME}>
                Resulting in scars, lasting tissue damage, disfigurement, 3rd degree burns,
                poisoning, loss of consciousness, targeting eyes, broken bones
              </Radio>
            </Space>
          </Radio.Group>
          Frequency
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Slider
              tooltipVisible={false}
              max={4}
              marks={frequencyMarks}
              step={null}
              onAfterChange={(e) => {
                setTempBehaviors({
                  ...tempBehaviors,
                  aggression: {
                    ...tempBehaviors.aggression!,
                    frequency: getBehaviorFrequency(e),
                  },
                });
              }}
              style={{ width: "75%", marginBottom: 50, alignSelf: "center" }}
              value={getBehaviorFrequencyValue(tempBehaviors.aggression.frequency)}
            />
          </div>
          <Text>Do others need to seek medical attention as a result of aggression?</Text>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 25,
            }}
          >
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.aggression?.requireMedicalAttn ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  aggression: {
                    ...tempBehaviors.aggression!,
                    requireMedicalAttn: true,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.aggression?.requireMedicalAttn === false ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  aggression: {
                    ...tempBehaviors.aggression!,
                    requireMedicalAttn: false,
                  },
                });
              }}
            >
              No
            </Button>
          </Space>
          <Text>Does your child bite and break skin?</Text>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 25,
            }}
          >
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.aggression?.bite ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  aggression: {
                    ...tempBehaviors.aggression!,
                    bite: true,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.aggression?.bite === false ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  aggression: {
                    ...tempBehaviors.aggression!,
                    bite: false,
                  },
                });
              }}
            >
              No
            </Button>
          </Space>
          <Text>Does your child use objects to inflict harm?</Text>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 25,
            }}
          >
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.aggression?.weapons ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  aggression: {
                    ...tempBehaviors.aggression!,
                    weapons: true,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.aggression?.weapons === false ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  aggression: {
                    ...tempBehaviors.aggression!,
                    weapons: false,
                  },
                });
              }}
            >
              No
            </Button>
          </Space>
        </>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={
            tempBehaviors.aggression !== undefined &&
            (tempBehaviors.aggression?.requireMedicalAttn === undefined ||
              tempBehaviors.aggression?.bite === undefined ||
              tempBehaviors.aggression?.weapons === undefined ||
              tempBehaviors.aggression?.severity === undefined)
          }
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClient({
              id: client.id,
              behaviors: tempBehaviors,
            });
            setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
