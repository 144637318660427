import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { addressSchema } from "../types";

const c = initContract();
export const clinicsContract = c.router({
  update: {
    method: "PUT",
    path: "/clinics/:clinicId",
    pathParams: z.object({
      clinicId: z.string(),
    }),
    body: z.object({
      clinicId: z.string(),
      name: z.string().optional(),
      displayName: z.string().optional(),
      phoneNumber: z.string().optional(),
      email: z.string().optional(),
      address: addressSchema.optional(),
      addressNotes: z.string().optional(),
      logoPath: z.string().optional(),
      locations: z.array(z.string()).optional(),
      serviceAreas: z.array(z.string()).optional(),
      lookerReportURL: z.string().optional(),
      slackId: z.string().optional(),
      isAdonisEnabled: z.boolean().optional(),
      dropboxTemplateId: z.string().optional(),
      handbookPreviewUrl: z.string().optional(),
      calendarSettings: z
        .object({
          minimumAppointmentLength: z.number(),
        })
        .optional(),
    }),
    responses: {
      200: z.string(),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
