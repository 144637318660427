import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { IUpdateClientEndpointRequest } from "../endpoints";
import {
  IAppointment,
  IClient,
  ICompletedAppointment,
  IInsurancePlan,
  INote,
  IPayer,
  ISilnaPlan,
  IUser,
} from "../types";
import { benefitsChecksSchema } from "../types/benefitsChecks";

const c = initContract();
export const clientsContract = c.router({
  getByClinic: {
    method: "GET",
    path: "/clients/get-all-by-user-and-clinic",
    query: z.object({
      // query params are all strings
      activeOnly: z
        .enum(["yes", "no"])
        .optional()
        .transform((val) => val === "yes"),
      clinicId: z.string(),
      // Unused, but kept for outdated frontends
      userId: z.any(),
    }),
    responses: {
      // TODO: Convert to zod schema
      200: c.type<IClient[]>(),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getById: {
    method: "GET",
    path: "/clients/:clientId",
    pathParams: z.object({
      clientId: z.string(),
    }),
    query: z.object({
      // for permissions
      clinicId: z.string(),
    }),
    responses: {
      200: c.type<{ client: IClient }>(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  update: {
    method: "POST",
    path: "/clients/update",
    body: c.type<IUpdateClientEndpointRequest>(),
    responses: {
      200: c.type<IClient>(),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  sendInsuranceConfirmationReminders: {
    method: "POST",
    path: "/clients/send-insurance-confirmation-reminders",
    body: z.object({
      clientIds: z.array(z.string()),
      // This is needed to enforce permissions
      clinicId: z.string(),
    }),
    responses: {
      202: z.object({
        success: z.boolean(),
      }),
      403: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  updateClientBillingHold: {
    method: "PATCH",
    path: "/clients/update-hold-client-claim",
    body: z.object({
      id: z.string(),
      isOnHold: z.boolean(),
    }),
    responses: {
      200: z.object({
        updatedIsOnHold: z.boolean(),
        success: z.boolean(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getClientAppointments: {
    method: "GET",
    path: "/clients/:clientId/appointments",
    pathParams: z.object({
      clientId: z.string(),
    }),
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      timeZone: z.string(),
    }),
    responses: {
      200: c.type<{ appointments: IAppointment[]; providers: IUser[] }>(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getClientCompletedAppointments: {
    method: "GET",
    path: "/clients/:clientId/completed-appointments",
    pathParams: z.object({
      clientId: z.string(),
    }),
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
    }),
    responses: {
      200: c.type<{ completedAppointments: ICompletedAppointment[] }>(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getNotesPendingGuardianSignature: {
    method: "GET",
    path: "/clients/:clientId/notes-pending-guardian-signature",
    summary: "Get notes pending guardian signature for a client",
    pathParams: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: c.type<{ notes: INote[] }>(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  createBenefitsCheck: {
    method: "POST",
    path: "/clients/create-benefits-check",
    body: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: z.object({
        benefitsCheckId: z.string(),
      }),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getSilnaPlans: {
    method: "GET",
    path: "/clients/:clientId/silna-plans",
    pathParams: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: c.type<{ primary: ISilnaPlan | null; secondary: ISilnaPlan | null }>(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getBenefitsChecks: {
    method: "GET",
    path: "/clients/:clientId/benefits-checks",
    pathParams: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: z.object({
        primary: benefitsChecksSchema,
        secondary: benefitsChecksSchema.nullable(),
      }),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getAssigned: {
    method: "GET",
    path: "/clients/get-assigned",
    query: z.object({
      clinicId: z.string(),
      providerId: z.string(),
    }),
    responses: {
      200: c.type<IClient[]>(),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getActiveInsurancePlans: {
    method: "GET",
    path: "/clients/:clientId/active-insurance-plans",
    pathParams: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: c.type<{
        primaryInsurancePlan: IInsurancePlan | null;
        secondaryInsurancePlan: IInsurancePlan | null;
      }>(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getActivePayers: {
    method: "GET",
    path: "/clients/:clientId/active-payers",
    pathParams: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: c.type<{
        primaryPayer: IPayer | null;
        secondaryPayer: IPayer | null;
      }>(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  notifyHRAndProviderSuccessAboutIncident: {
    method: "POST",
    path: "/clients/notify-hr-and-provider-success-about-incident",
    body: z.object({
      dateOfIncident: z.string(),
      typeOfIncident: z.string(),
      incidentFormLink: z.string(),
      clinicDisplayName: z.string(),
      clinicId: z.string(),
      hasBeenSubmittedBefore: z.boolean(),
    }),
    responses: {
      200: z.object({
        message: z.string(),
      }),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
