import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { appointmentSchema, appointmentSchemaWithNote, MissingActionEventType } from "../types";

const c = initContract();

export type PendingAppointment =
  | z.infer<typeof appointmentSchema>
  | z.infer<typeof appointmentSchemaWithNote>;

export const approvalsContract = c.router({
  getPendingAppointmentsMissingActions: c.query({
    method: "GET",
    path: "/approvals/pending-missing-actions",
    query: z.object({
      clinicId: z.string(),
      filterType: z.nativeEnum(MissingActionEventType).optional(),
    }),
    responses: {
      200: z.array(z.union([appointmentSchema, appointmentSchemaWithNote])),
      400: z.object({ message: z.string() }),
      403: z.object({ message: z.string() }),
      404: z.object({ message: z.string() }),
      500: z.object({ message: z.string() }),
    },
  }),
});
