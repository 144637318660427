import {
  AppointmentType,
  BillingCode,
  IAppointment,
  IAppointmentConfig,
  ICompletedAppointment,
  IPayer,
  Modifier,
  ProviderType,
} from "../../types";
import { getBaseBillingModifiers } from "./helpers";

export const CONNECTICUT_APPOINTMENTS: IAppointmentConfig[] = [
  {
    name: AppointmentType.INITIAL_ASSESSMENT,
    billingCode: BillingCode.CODE_H0031,
    modifiers: [],
  },
  {
    name: AppointmentType.ADDITIONAL_ASSESSMENT,
    billingCode: BillingCode.CODE_H0032,
    modifiers: [],
  },
  {
    name: AppointmentType.ADDITIONAL_ASSESSMENT,
    billingCode: BillingCode.CODE_H0032,
    modifiers: [Modifier.TS],
  },
  {
    name: AppointmentType.ADDITIONAL_ASSESSMENT,
    billingCode: BillingCode.CODE_90889,
    modifiers: [],
  },
  {
    name: AppointmentType.DIRECT_SUPERVISION,
    billingCode: BillingCode.CODE_H0046,
    modifiers: [],
  },
  {
    name: AppointmentType.PARENT_TRAINING,
    billingCode: BillingCode.CODE_H2014,
    modifiers: [],
  },
];

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  providerType: ProviderType
) => {
  return getBaseBillingModifiers(appointment, payer, providerType);
};

export const conneticutBillingModifier = getStateBillingModifiers;
