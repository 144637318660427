import { IUpdateClientEndpointRequest } from "@finni-health/shared";
import { Button, Card, Form, Input, Row, Space, Typography } from "antd";
import React, { ReactNode, useContext } from "react";

import { ClientFileIntakeQuestionnaireStates, selected, unselected } from "../../../consts";
import { AuthContext } from "../../AuthProvider";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempTherapyPreferences: any;
  setTempTherapyPreferences: (value: any) => void;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterPreferredLanguage: React.FC<IProps> = ({
  isMobile,
  tempTherapyPreferences,
  setTempTherapyPreferences,
  setClientFileIntakeQuestionnaireStatus,
  updateClient,
  backButton,
}: IProps) => {
  const { client } = useContext(AuthContext);

  return (
    <Card
      title={
        <Title
          level={5}
          style={{
            fontWeight: 400,
            whiteSpace: "pre-line",
          }}
        >
          {`What language would you prefer to be used for your child's therapy?`}
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempTherapyPreferences.preferredLanguage === "english" ? selected : unselected}
          onClick={() => {
            setTempTherapyPreferences({
              ...tempTherapyPreferences,
              preferredLanguage: "english",
            });
          }}
        >
          English
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempTherapyPreferences.preferredLanguage !== "english" &&
            tempTherapyPreferences.preferredLanguage !== undefined
              ? selected
              : unselected
          }
          onClick={() => {
            setTempTherapyPreferences({
              ...tempTherapyPreferences,
              preferredLanguage: "",
            });
          }}
        >
          Other
        </Button>
      </Space>
      {tempTherapyPreferences.preferredLanguage !== "english" &&
        tempTherapyPreferences.preferredLanguage !== undefined && (
          <Form layout="vertical" labelCol={{ span: 24 }}>
            <Form.Item
              label="Preferred Language"
              name="preferredLanguage"
              rules={[
                {
                  required: true,
                  message: "Please enter the language you prefer for therapy",
                },
              ]}
              initialValue={tempTherapyPreferences.preferredLanguage}
              style={{
                maxWidth: 450,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 25,
              }}
            >
              <Input
                onChange={(e) => {
                  setTempTherapyPreferences({
                    ...tempTherapyPreferences,
                    preferredLanguage: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Form>
        )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={
            tempTherapyPreferences.preferredLanguage === "" ||
            tempTherapyPreferences.preferredLanguage === undefined
          }
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClient({
              id: client.id,
              therapyPreferences: tempTherapyPreferences,
            });
            setClientFileIntakeQuestionnaireStatus(
              ClientFileIntakeQuestionnaireStates.ENTER_PREFERRED_THERAPIST_GENDER
            );
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
