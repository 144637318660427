import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  createSignableDocumentSchema,
  SignableDocument,
  updateSignableDocumentSchema,
} from "../types/signableDocuments";

const c = initContract();
export const signableDocumentsContract = c.router({
  list: {
    method: "GET",
    path: "/signable-documents",
    query: z.object({
      clinicId: z.string().optional(),
    }),
    responses: {
      200: c.type<{ signableDocuments: SignableDocument[] }>(),
      500: z.object({ message: z.string() }),
    },
  },
  listAll: {
    method: "GET",
    path: "/admin/signable-documents",
    responses: {
      200: c.type<{ signableDocuments: SignableDocument[] }>(),
      500: z.object({ message: z.string() }),
    },
  },
  create: {
    method: "POST",
    path: "/admin/signable-documents",
    body: createSignableDocumentSchema,
    responses: {
      200: z.object({ id: z.string() }),
      500: z.object({ message: z.string() }),
    },
  },
  update: {
    method: "PUT",
    path: "/admin/signable-documents/:id",
    pathParams: z.object({ id: z.string() }),
    body: updateSignableDocumentSchema,
    responses: {
      200: z.object({ id: z.string() }),
      404: z.object({ message: z.string() }),
      500: z.object({ message: z.string() }),
    },
  },
  delete: {
    method: "DELETE",
    path: "/admin/signable-documents/:id",
    pathParams: z.object({ id: z.string() }),
    body: z.object({}).nullable(),
    responses: {
      200: z.object({ id: z.string() }),
      404: z.object({ message: z.string() }),
      500: z.object({ message: z.string() }),
    },
  },
});
