import {
  IAppointmentWithNote,
  KlaviyoEventKey,
  MissingActionEventType,
  MissingActionFilterType,
} from "../types";

// Creates cyclical dependency if imported from consts in mission-control package
const MOTIVITY_FORMS_URL = "https://app.motivity.net/orgs/-1Fu5VnIJ0OWP3NyAflEWg/psn/ppsn/snote/";
const ORBIT_BASE_URL = "https://orbit.finnihealth.com";

//TODO: Add guardianSignature once ready to support
export const ALL_FILTER_TYPES: MissingActionFilterType[] = ["narrative", "providerSignature"];

export interface ReminderData {
  eventKey: KlaviyoEventKey;
  eventType: MissingActionEventType;
  userId?: string;
  clientIds?: string[];
  appointments?: Array<{
    isOrbitNote: boolean;
    noteId: string;
    clientId: string;
    noteDateTime: string;
  }>;
}

export interface UserAppointment {
  userId: string;
  isOrbitNote: boolean;
  noteId: string;
  clientId: string;
  noteDateTime: string;
}

const missingActionFilterTypeToEventKey: Record<
  NonNullable<MissingActionFilterType>,
  MissingActionEventType
> = {
  narrative: MissingActionEventType.NarrativeMissing,
  providerSignature: MissingActionEventType.ProviderSignature,
  guardianSignature: MissingActionEventType.GuardianSignature,
};

export const createUserNoteLinks = (data: ReminderData, clinicName: string) => {
  return data.appointments?.map((appt) => ({
    noteLink: appt.isOrbitNote
      ? `${ORBIT_BASE_URL}/${clinicName}/clients/${appt.clientId}/notes/${appt.noteId}`
      : `${MOTIVITY_FORMS_URL}${appt.noteId}`,
    noteDateTime: appt.noteDateTime,
  }));
};

export const processUserAppointments = (
  pendingAppointments: IAppointmentWithNote[],
  filterType?: MissingActionFilterType
): ReminderData[] => {
  const userAppointments: UserAppointment[] = pendingAppointments
    .filter(
      (appt): appt is IAppointmentWithNote & { userId: string; noteId: string } =>
        Boolean(appt.userId) && Boolean(appt.noteId)
    )
    .map((appt) => ({
      userId: appt.userId,
      isOrbitNote: appt.isOrbitNote || false,
      noteId: appt.noteId,
      clientId: appt.clientId,
      noteDateTime: appt.noteDateTime || "",
    }));

  const userGroups = userAppointments.reduce((groups, appt) => {
    const key = appt.userId;
    if (!groups[key]) groups[key] = [];
    groups[key].push(appt);
    return groups;
  }, {} as Record<string, UserAppointment[]>);

  const createReminderDataForUsers = (filterType?: MissingActionFilterType) =>
    Object.entries(userGroups).map(([userId, appointments]) => ({
      eventKey: KlaviyoEventKey.MissingNoteActionReminder,
      eventType: missingActionFilterTypeToEventKey[filterType || "narrative"],
      userId,
      appointments,
    }));

  if (filterType) {
    return createReminderDataForUsers(filterType);
  } else {
    return ALL_FILTER_TYPES.flatMap((filterType) => createReminderDataForUsers(filterType));
  }
};
