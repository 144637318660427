import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { calendarAppointmentSchema } from "../types";

const c = initContract();

export const calendarContract = c.router({
  getAppointmentsForClinicAndRange: {
    method: "GET",
    path: "/calendar/get-appointments",
    query: z.object({
      clinicId: z.string(),
      // Using coerce because this is the request param. So everything converts to string.
      // Need to convert it back to number.
      // Without coerce, zod validation fails.
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      timeZone: z.string(),
    }),
    responses: {
      // TODO: Convert to zod schema
      200: calendarAppointmentSchema.array(),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
