import { contract } from "@finni-health/shared";
import { tsRestFetchApi } from "@ts-rest/core";
import { initTsrReactQuery } from "@ts-rest/react-query/v5";
import { getAuth } from "firebase/auth";

export const tsr = initTsrReactQuery(contract, {
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL || "",
  api: async (args) => {
    const token = await getAuth().currentUser?.getIdToken();
    args.headers.Authorization = token || "";
    return tsRestFetchApi(args);
  },
});
