import { IClient } from "@finni-health/shared";
import { Button, Card, Divider, Row, Typography } from "antd";
import { useHistory } from "react-router-dom";

import { tsr } from "../../services/tsr";
import { InsuranceFileUpload } from "./InsuranceFileUpload";

const { Title, Text } = Typography;

export const CurrentInsuranceCard = ({ client }: { client: IClient }) => {
  const history = useHistory();

  const insurancePlansQuery = tsr.clients.getActiveInsurancePlans.useQuery({
    queryData: { params: { clientId: client?.id ?? "" } },
    queryKey: ["clients.getActiveInsurancePlans", client?.id],
    enabled: Boolean(client?.id),
  });

  const primaryInsurance = insurancePlansQuery.data?.body.primaryInsurancePlan;

  return (
    <Card
      style={{
        flex: 1,
        maxWidth: 350,
        borderRadius: 10,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
      }}
      // 71px is approximately the height of the title
      bodyStyle={{ height: "calc(100% - 71px)" }}
      title={
        <Row align="middle" justify="center">
          <Title level={5} style={{ marginTop: 5, marginBottom: 5 }}>
            Current Insurance Card
          </Title>
        </Row>
      }
    >
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: "100%",
          padding: 10,
          gap: 20,
          alignContent: "space-between",
        }}
      >
        {primaryInsurance && (
          <Row style={{ width: "100%" }}>
            <Divider orientation="left">Active Primary Payer</Divider>
            <InsuranceFileUpload
              label="Front"
              name="Front"
              storagePath="primary/front"
              initialFileUrl={primaryInsurance?.photoURLs?.[0]}
              isEditable={false}
            />
            <InsuranceFileUpload
              label="Back"
              name="Back"
              storagePath="primary/back"
              initialFileUrl={primaryInsurance?.photoURLs?.[1]}
              isEditable={false}
            />
          </Row>
        )}

        <Button
          size="large"
          type="primary"
          shape="round"
          onClick={() => history.push("/insurance-confirmed")}
        >
          I have no new insurance info
        </Button>
      </Row>
    </Card>
  );
};
