import { z } from "zod";

export const benefitsChecksSchema = z.object({
  id: z.string(),
  // Benefits check ID from silna
  externalId: z.string(),
  clientId: z.string(),
  verifiedMs: z.number().nullable(),

  benefitsCheckStatus: z.enum([
    "REQUESTED",
    "EDI_PROCESSING",
    "AWAITING_SILNA",
    "AWAITING_PROVIDER",
    "COMPLETE",
    "WITHDRAWN",
  ]),
  eligibilityStatus: z.enum(["ELIGIBLE", "INELIGIBLE"]).nullable(),
  networkStatus: z.enum(["IN_NETWORK", "OUT_OF_NETWORK"]).nullable(),

  payerDocId: z.string().nullable(),
  planStartMs: z.number().nullable(),
  planEndMs: z.number().nullable(),

  copayAmount: z.number().nullable(),
  coinsurancePercentage: z.number().nullable(),
  individualDeductibleAmount: z.number().nullable(),
  individualDeductibleAmountMet: z.number().nullable(),
  familyDeductibleAmount: z.number().nullable(),
  familyDeductibleAmountMet: z.number().nullable(),
  individualOutOfPocketAmount: z.number().nullable(),
  individualOutOfPocketAmountMet: z.number().nullable(),
  familyOutOfPocketAmount: z.number().nullable(),
  familyOutOfPocketAmountMet: z.number().nullable(),

  hardVisitLimitPresent: z.boolean().nullable(),
  hardVisitLimitUnits: z.number().nullable(),
  hardVisitLimitUnitType: z.string().nullable(),

  isAuthorizationRequired: z.boolean().nullable(),
  authorizationRequirementTime: z.string().nullable(),
  authorizationRequirementQuantity: z.number().nullable(),
  authorizationRequirementUnit: z.string().nullable(),

  authRequirement: z
    .object({
      /**
       * Raw text with info about the auth requirement.
       */
      raw: z.string().nullable(),
      requiredFor: z
        .object({
          /**
           * Defines the authorization requirement rule type. ALL_EXCEPT means that auth is required for all codes except
           * the codes in 'exceptions'. NONE_EXCEPT means auth is not required for codes other than the exceptions.
           */
          type: z.enum(["ALL_EXCEPT", "NONE_EXCEPT"]),
          /**
           * List of procedure codes that are exceptions to the rule
           */
          exceptions: z.array(z.string()),
        })
        .nullable(),
    })
    .nullable(),

  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type IBenefitsCheck = z.infer<typeof benefitsChecksSchema>;
