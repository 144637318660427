import {
  IDropboxSignCustomField,
  IDropboxSignSigner,
  IGetEmbeddedUrlEndpointRequest,
} from "../../endpoints/dropboxSign";
import { IClient, IClinic, IGuardian } from "../../types";
import { SignableDocument, SignableDocumentCustomFieldKey } from "../../types/signableDocuments";
import { DropboxDocumentSignerRole } from "./types";

/**
 * Common payloads are:
 *  - Signer
 *  - Custom fields
 */
export const buildDropboxCommonPayload = ({
  client,
  guardian,
  clinic,
  // Custom fields are used to prefill the document
  signableDocument: { customFields },
}: {
  client: IClient;
  guardian: IGuardian;
  clinic: IClinic;
  signableDocument: SignableDocument;
}): { signers: IDropboxSignSigner[]; customFields: IDropboxSignCustomField[] } => {
  const prefilledFields: IGetEmbeddedUrlEndpointRequest["customFields"] = [];

  for (const field in customFields) {
    switch (field) {
      case SignableDocumentCustomFieldKey.ParentFullName:
        prefilledFields.push({
          name: customFields[SignableDocumentCustomFieldKey.ParentFullName] ?? "",
          value: `${guardian.firstName} ${guardian.lastName}`,
          editor: DropboxDocumentSignerRole["Parent/Guardian"],
          required: true,
        });
        break;
      case SignableDocumentCustomFieldKey.ClientFullName:
        prefilledFields.push({
          name: customFields[SignableDocumentCustomFieldKey.ClientFullName] ?? "",
          value: `${client.firstName} ${client.lastName}`,
          editor: DropboxDocumentSignerRole["Parent/Guardian"],
          required: true,
        });
        break;
      case SignableDocumentCustomFieldKey.PracticeName:
        prefilledFields.push({
          name: customFields[SignableDocumentCustomFieldKey.PracticeName] ?? "",
          value: clinic.displayName,
          editor: DropboxDocumentSignerRole["Parent/Guardian"],
          required: true,
        });
        break;
      default:
        break;
    }
  }

  const formData = {
    signers: [
      {
        emailAddress: guardian.email,
        name: `${guardian.firstName} ${guardian.lastName}`,
        role: DropboxDocumentSignerRole["Parent/Guardian"],
      },
    ],
    customFields: prefilledFields,
  };

  return formData;
};
