import { BehaviorFrequency, IUpdateClientEndpointRequest } from "@finni-health/shared";
import { Button, Card, Row, Slider, Space, Typography } from "antd";
import React, { ReactNode, useContext } from "react";

import { frequencyMarks, selected, unselected } from "../../../consts";
import { getBehaviorFrequency } from "../../../helpers/get-behavior-frequency";
import { getBehaviorFrequencyValue } from "../../../helpers/get-behavior-frequency-value";
import { AuthContext } from "../../AuthProvider";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempBehaviors: any;
  setTempBehaviors: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterStrippingClothingFrequency: React.FC<IProps> = ({
  isMobile,
  tempBehaviors,
  setTempBehaviors,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClient,
  backButton,
}: IProps) => {
  const { client } = useContext(AuthContext);

  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          Does your child take off their clothes in public?
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.takeOffClothes !== undefined ? selected : unselected}
          onClick={() => {
            setTempBehaviors({
              ...tempBehaviors,
              takeOffClothes: {
                ...tempBehaviors.takeOffClothes!,
                frequency: tempBehaviors.takeOffClothes?.frequency || BehaviorFrequency.MONTHLY,
              },
            });
          }}
        >
          Yes
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.takeOffClothes === undefined ? selected : unselected}
          onClick={() => {
            const newTempBehaviors = tempBehaviors;
            delete newTempBehaviors.takeOffClothes;
            setTempBehaviors({ ...newTempBehaviors });
          }}
        >
          No
        </Button>
      </Space>
      {tempBehaviors.takeOffClothes !== undefined && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          Frequency
          <Slider
            tooltipVisible={false}
            max={4}
            marks={frequencyMarks}
            step={null}
            onAfterChange={(e) => {
              setTempBehaviors({
                ...tempBehaviors,
                takeOffClothes: {
                  ...tempBehaviors.takeOffClothes!,
                  frequency: getBehaviorFrequency(e),
                },
              });
            }}
            style={{ width: "75%", marginBottom: 50, alignSelf: "center" }}
            value={getBehaviorFrequencyValue(tempBehaviors.takeOffClothes.frequency)}
          />
        </div>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClient({
              id: client.id,
              behaviors: tempBehaviors,
            });
            setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
