import { IUpdateClientEndpointRequest } from "@finni-health/shared";
import { Button, Card, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import React, { ReactNode, useContext } from "react";

import { selectedToggle, unselectedToggle } from "../../../consts";
import { AuthContext } from "../../AuthProvider";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempMedicalHistory: any;
  setTempMedicalHistory: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterOtherIssues: React.FC<IProps> = ({
  isMobile,
  tempMedicalHistory,
  setTempMedicalHistory,
  updateClient,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  backButton,
}: IProps) => {
  const { client } = useContext(AuthContext);

  const toggleTreatmentImpairment = (treatmentImpairment: string) => {
    const newTempMedicalHistory = tempMedicalHistory;
    let tempTreatmentImpairments = tempMedicalHistory.treatmentImpairments || [];

    if (tempMedicalHistory.treatmentImpairments?.includes(treatmentImpairment)) {
      tempTreatmentImpairments = tempTreatmentImpairments.filter(
        (tempTreatmentImpairment: string) => {
          return tempTreatmentImpairment !== treatmentImpairment;
        }
      );
    } else {
      tempTreatmentImpairments.push(treatmentImpairment);
    }

    if (_.isEmpty(tempTreatmentImpairments)) {
      delete newTempMedicalHistory.treatmentImpairments;
    } else {
      newTempMedicalHistory.treatmentImpairments = tempTreatmentImpairments;
    }

    setTempMedicalHistory({ ...newTempMedicalHistory });
  };

  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          Are there any legal, cultural, or medical issues that could impact care?
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.treatmentImpairments?.includes("CustodyAgreement")
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleTreatmentImpairment("CustodyAgreement");
          }}
        >
          Custody Agreement
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.treatmentImpairments?.includes("Legal Proceeding")
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleTreatmentImpairment("Legal Proceeding");
          }}
        >
          Legal Proceeding in Process Involving Your Child
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.treatmentImpairments?.includes("ReligiousBeliefs")
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleTreatmentImpairment("ReligiousBeliefs");
          }}
        >
          Religious Beliefs Impacting Care
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.otherTreatmentImpairments !== undefined
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            if (tempMedicalHistory.otherTreatmentImpairments === undefined) {
              setTempMedicalHistory({
                ...tempMedicalHistory,
                otherTreatmentImpairments: "",
              });
            } else {
              const newTempMedicalHistory = tempMedicalHistory;
              delete newTempMedicalHistory.otherTreatmentImpairments;
              setTempMedicalHistory({ ...newTempMedicalHistory });
            }
          }}
        >
          Other
        </Button>
      </Row>
      {tempMedicalHistory.otherTreatmentImpairments !== undefined && (
        <Row
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <TextArea
            autoSize
            defaultValue={tempMedicalHistory.otherTreatmentImpairments}
            placeholder="Let us know what other factors may impact your child's care."
            style={{ marginBottom: 25, maxWidth: 400 }}
            onChange={(e) => {
              setTempMedicalHistory({
                ...tempMedicalHistory,
                otherTreatmentImpairments: e.target.value,
              });
            }}
          />
        </Row>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={tempMedicalHistory.otherTreatmentImpairments === ""}
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClient({
              id: client.id,
              medicalHistory: tempMedicalHistory,
            });
            setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
