import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import {
  ClientDocumentType,
  IClientDocument,
  IClientFile,
  IUpdateClientEndpointRequest,
} from "@finni-health/shared";
import { Button, Card, Col, List, message, Row, Spin, Typography } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { isEqual } from "lodash";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import app from "../../services/firebase";
import * as FirestoreService from "../../services/firestore";
import { AuthContext } from "../AuthProvider";

const { Title, Text } = Typography;
export interface IDiagnosticsUploadTaskProps {
  clientFile: IClientFile;
}

export const DiagnosticsUploadTask = ({ clientFile }: IDiagnosticsUploadTaskProps) => {
  const { client } = useContext(AuthContext);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IClientDocument[]>(clientFile.documents || []);
  const [fileList, setFileList] = useState<any[]>([]);
  const [numDocumentsUploaded, setNumDocumentsUploaded] = useState<number>(0);
  const location = useLocation();

  useEffect(() => {
    if (!isEqual(documents, clientFile.documents)) {
      updateClient({
        documents,
        id: client.id,
      }).catch(() => {});
    }
  }, [documents]);

  const onDeleteDocument = (document: IClientDocument) => {
    setNumDocumentsUploaded(numDocumentsUploaded - 1);

    const tempDocuments = [...documents];
    tempDocuments.splice(documents.indexOf(document), 1);
    setDocuments(tempDocuments);

    void message.success(`Successfully deleted care document`);
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: (file: File) => {
      //file size larger than 10MB (10000000 Bytes)
      if (file.size > 10000000) {
        void message.error(
          `Your file: ${file.name} is too large and will not be uploaded. Only files under 10MB can be uploaded`
        );
        return false;
      }

      setIsUploading(true);

      if (numDocumentsUploaded >= 10) {
        void message.warning(
          "You can only upload a maximum of 10 documents. If you want to upload a different document, you must first remove one"
        );
        setFileList([...fileList]);
        setIsUploading(false);
        return false;
      }

      uploadBytes(ref(getStorage(app), `clientFiles/${clientFile.id}/documents/${file.name}`), file)
        .then((results) => {
          setFileList([...fileList, file]);
          setNumDocumentsUploaded(numDocumentsUploaded + 1);
          const path = results.ref.fullPath;
          const newDocument = {
            type: ClientDocumentType.DIAGNOSIS_REPORT,
            path,
          };
          setDocuments([...documents, newDocument]);
          void message.success(`Successfully Uploaded ${file.name}`);
          setIsUploading(false);
        })
        .catch(() => {});

      ReactGA.event("care_document_upload", {
        page_path: location.pathname + location.search + location.hash,
      });

      // Antd has built in upload logic, override it with firebase storage upload
      return false;
    },
    fileList,
  };

  const updateClient = (req: IUpdateClientEndpointRequest) => {
    return FirestoreService.updateClient(req);
  };

  return (
    <Card
      style={{
        width: "100%",
        margin: "0 10px",
        whiteSpace: "pre-wrap",
      }}
      title={
        <Row align="middle" justify="space-between">
          <Title level={4} style={{ marginTop: 5, marginBottom: 5 }}>
            Care Documents
          </Title>
        </Row>
      }
    >
      <Text
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {documents.length > 0
          ? "Thanks for uploading some diagnosis docs! If you have additional files you would like to share, please add them here. Entire PDFs are preferred."
          : "We're missing diagnosis docs, we need them to qualify you for care with your insurance. Entire PDFs are preferred."}
      </Text>
      <Row justify="space-between" align="middle" style={{ width: "100%", marginTop: 5 }}>
        {documents.length > 0 && (
          <List
            size="small"
            style={{ width: "100%" }}
            bordered
            dataSource={documents}
            renderItem={(document) => (
              <List.Item
                actions={[
                  <Button key="url-delete" type="text" onClick={() => onDeleteDocument(document)}>
                    <DeleteOutlined />
                  </Button>,
                ]}
              >
                <Row>{document.path.split("/")[3]}</Row>
              </List.Item>
            )}
          />
        )}
        <Col span={24}>
          <Dragger style={{ padding: 2, marginTop: 15 }} {...draggerProps}>
            {isUploading ? (
              <Spin />
            ) : (
              <>
                <Text className="ant-upload-drag-icon">
                  <InboxOutlined />
                </Text>
                <Text className="ant-upload-text">Upload</Text>
              </>
            )}
          </Dragger>
        </Col>
      </Row>
    </Card>
  );
};
