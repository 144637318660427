import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { IInsurancePlan, insurancePlanSchema } from "../types";

const c = initContract();
export const insurancePlansContract = c.router({
  get: {
    method: "GET",
    path: "/insurance-plans",
    query: z.object({
      clinicId: z.string(),
    }),
    responses: {
      200: z.object({
        insurancePlans: z.array(insurancePlanSchema),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  update: {
    method: "POST",
    path: "/insurance-plans/:insurancePlanId",
    pathParams: z.object({
      insurancePlanId: z.string(),
    }),
    body: c.type<Partial<IInsurancePlan>>(),
    responses: {
      200: z.object({
        insurancePlanId: z.string(),
      }),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  create: {
    method: "POST",
    path: "/insurance-plans",
    body: c.type<Omit<IInsurancePlan, "id" | "createdAt" | "updatedAt">>(),
    responses: {
      200: z.object({
        insurancePlanId: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
