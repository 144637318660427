import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { appointmentAuditResultSchema } from "../types/general";
const c = initContract();

export const aiContract = c.router({
  generateNarrative: {
    method: "POST",
    path: "/ai/generate-narrative",
    body: z.object({
      sessionInfo: z.string(),
    }),
    responses: {
      200: z.string(),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  auditAppointment: {
    method: "POST",
    path: "/ai/audit-appointment",
    body: z.object({
      appointmentId: z.string(),
    }),
    responses: {
      200: appointmentAuditResultSchema,
      500: z.object({
        message: z.string(),
      }),
    },
  },
  psmDailySummary: {
    method: "GET",
    path: "/ai/psm-daily-summary",
    query: z.object({
      clinicId: z.string(),
    }),
    responses: {
      200: z.object({
        messages: z.array(
          z.object({
            role: z.enum(["system", "user", "assistant"]),
            content: z.string(),
          })
        ),
      }),
      500: z.object({
        messages: z.array(
          z.object({
            role: z.enum(["system", "user", "assistant"]),
            content: z.string(),
          })
        ),
      }),
    },
  },
  psmChat: {
    method: "POST",
    path: "/ai/psm-chat",
    body: z.object({
      messages: z.array(
        z.object({
          role: z.enum(["system", "user", "assistant"]),
          content: z.string(),
        })
      ),
    }),
    responses: {
      200: z.string(),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  parseClientPayerCards: {
    method: "GET",
    path: "/ai/parse-client-payer-cards",
    query: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: z.object({
        primary: z.object({
          payerName: z.string(),
          memberNumber: z.string(),
          groupNumber: z.string(),
          policyHolderName: z.string(),
        }),
        secondary: z.object({
          payerName: z.string(),
          memberNumber: z.string(),
          groupNumber: z.string(),
          policyHolderName: z.string(),
        }),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
