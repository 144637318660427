import { IClientDetails, isDefined } from "@finni-health/shared";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useContext } from "react";

import { AuthContext } from "../components/AuthProvider";
import { IntakeComplete } from "../components/ClientOnboardingFlow/IntakeComplete";
import { PreapprovalComplete } from "../components/ClientOnboardingFlow/PreapprovalComplete";
import { Welcome } from "../components/ClientOnboardingFlow/Welcome";
import { PageName } from "../consts";
import { isIntakeComplete, isPreapprovalComplete } from "../helpers/client-file-status";
import { usePageViewTracking } from "../hooks/usePageViewTracking";
import * as FirestoreService from "../services/firestore";
import { Loading } from "./Loading";

export const ClientFileFlow: React.FC = () => {
  const { guardian } = useContext(AuthContext);
  const queryClient = useQueryClient();

  usePageViewTracking(PageName.CLIENT_FILE_FLOW);

  const { data: clientDetails, isLoading } = useQuery<IClientDetails | undefined>({
    queryKey: ["getClientDetailsByGuardianId", guardian.id],
    queryFn: () => FirestoreService.getClientDetailsByGuardianId(guardian.id),
    enabled: isDefined(guardian),
  });

  const invalidateGetClientFileQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["getClientDetailsByGuardianId", guardian.id],
    });
  };

  if (isLoading || !clientDetails) {
    return <Loading pageName={PageName.CLIENT_FILE_FLOW} />;
  }

  const clientFile = clientDetails.clientFile;

  if (!isPreapprovalComplete(clientDetails)) {
    return <Welcome clientFile={clientFile} />;
  }

  if (!isIntakeComplete(clientDetails)) {
    return (
      <PreapprovalComplete
        clientFile={clientFile}
        refreshClientFile={invalidateGetClientFileQuery}
      />
    );
  }

  return <IntakeComplete clientFile={clientFile} />;
};
