import { z } from "zod";

import {
  eventLineSchema,
  eventLineTargetScopeSchema,
  programSchema,
  programTemplateSchema,
} from "../types";

export const createProgramEndpointRequestSchema = programSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .strict();

export type ICreateProgramEndpointRequest = z.input<typeof createProgramEndpointRequestSchema>;

export const updateProgramEndpointRequestSchema = programSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    id: z.string(),
    userId: z.string(),
  })
  .strict();

export type IUpdateProgramEndpointRequest = z.input<typeof updateProgramEndpointRequestSchema>;

export const createEventLineEndpointRequestSchema = eventLineSchema
  .omit({ id: true, createdAt: true, updatedAt: true, deletedAt: true })
  .strict();

export type ICreateEventLineEndpointRequest = z.input<typeof createEventLineEndpointRequestSchema>;

export const updateTargetScopeOfEventLineEndpointRequestSchema = z.object({
  eventLineId: z.string(),
  targetScope: z.array(eventLineTargetScopeSchema),
});

export type IUpdateTargetScopeOfEventLineEndpointRequest = z.input<
  typeof updateTargetScopeOfEventLineEndpointRequestSchema
>;

export const createProgramTemplateEndpointRequestSchema = programTemplateSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .strict();

export const updateEventLineEndpointRequestSchema = eventLineSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    id: z.string(),
  })
  .strict();

export type ICreateProgramTemplateEndpointRequest = z.input<
  typeof createProgramTemplateEndpointRequestSchema
>;

export type IUpdateEventLineEndpointRequest = z.input<typeof updateEventLineEndpointRequestSchema>;

export const updateProgramTemplateEndpointRequestSchema = programTemplateSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    id: z.string(),
  })
  .strict();

export type IUpdateProgramTemplateEndpointRequest = z.input<
  typeof updateProgramTemplateEndpointRequestSchema
>;
