import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { finniErrorResponseSchemaMap } from "../helpers/errors";
import { AuthMethod, BillingCode, pendingAuthorizationSchema, PendingAuthStatus } from "../types";
const c = initContract();

export const pendingAuthorizationContract = c.router({
  create: {
    method: "POST",
    path: "/pending-authorizations",
    body: z.object({
      id: z.string().optional(),
      startDate: z.string().date().nullable(),
      endDate: z.string().date().nullable(),
      payerId: z.string().nullable(),
      authNumber: z.string().nullable(),
      authMethod: z.nativeEnum(AuthMethod).nullable(),
      documents: z.array(z.object({ path: z.string(), fileName: z.string() })).nullable(),
      phoneReferenceNumber: z.string().nullable(),
      authCodes: z.array(
        z.object({
          units: z.number().nullable(),
          billingCode: z.nativeEnum(BillingCode),
        })
      ),
      clinicId: z.string(),
      clientId: z.string(),
    }),
    responses: {
      200: z.object({ id: z.string() }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  update: {
    method: "PUT",
    path: "/pending-authorizations/:id",
    body: z.object({
      // Required for enforceClinicPermissions to pass.
      id: z.string(),
      startDate: z.string().date().nullish(),
      endDate: z.string().date().nullish(),
      payerId: z.string().nullish(),
      authNumber: z.string().nullish(),
      documents: z.array(z.object({ path: z.string(), fileName: z.string() })).nullish(),
      authCodes: z
        .array(
          z.object({
            units: z.number().nullish(),
            billingCode: z.nativeEnum(BillingCode),
          })
        )
        .optional(),
      authMethod: z.nativeEnum(AuthMethod).nullish(),
      clinicId: z.string().optional(),
      phoneReferenceNumber: z.string().nullish(),
    }),
    pathParams: z.object({
      id: z.string(),
    }),
    responses: {
      200: z.object({ id: z.string() }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  list: {
    method: "GET",
    path: "/pending-authorizations",
    query: z.object({
      clinicId: z.string().optional(),
      statuses: z.array(z.nativeEnum(PendingAuthStatus)).optional(),
      clientId: z.string().optional(),
    }),
    responses: {
      200: z.array(pendingAuthorizationSchema),
    },
  },
  delete: {
    method: "DELETE",
    path: "/pending-authorizations/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    body: c.noBody(),
    responses: {
      200: z.object({ id: z.string() }),
    },
  },
  approveForClient: {
    method: "POST",
    path: "/clients/:clientId/pending-authorizations/approve",
    body: c.noBody(),
    pathParams: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: z.object({ message: z.string() }),
      ...finniErrorResponseSchemaMap,
    },
  },
});
