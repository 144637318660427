import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

export const cigmmoContract = c.router({
  getNotes: {
    method: "GET",
    path: "/cigmmo/get-notes",
    responses: {
      200: z.null(),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
