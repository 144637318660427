import { z } from "zod";

import { DropboxDocumentSignerRole, DropboxDocumentType } from "../services/dropboxSignService";

export enum SignableDocumentCustomFieldKey {
  ClientFullName = "clientFullName",
  /** Name of the guardian */
  ParentFullName = "parentFullName",
  /** Name of the clinic */
  PracticeName = "practiceName",
}

export const signableDocumentSchema = z.object({
  id: z.string(),
  title: z.string(),
  documentType: z.nativeEnum(DropboxDocumentType),
  version: z.number().int(),
  description: z.string(),
  templateId: z.string(),
  previewURL: z.string().url(),
  signerRole: z.nativeEnum(DropboxDocumentSignerRole),
  customFields: z.record(z.nativeEnum(SignableDocumentCustomFieldKey), z.string()),
  isDraft: z.boolean(),
  subject: z.string(),
  message: z.string(),

  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type SignableDocument = z.infer<typeof signableDocumentSchema>;

export const createSignableDocumentSchema = z.object({
  documentType: z.nativeEnum(DropboxDocumentType),
  title: z.string(),
  description: z.string(),
  templateId: z.string(),
  previewURL: z.string().url(),
  signerRole: z.nativeEnum(DropboxDocumentSignerRole),
  customFields: z.record(z.nativeEnum(SignableDocumentCustomFieldKey), z.string()),
  isDraft: z.boolean(),
  subject: z.string(),
  message: z.string(),
});

export const updateSignableDocumentSchema = z.object({
  title: z.string(),
  description: z.string(),
  templateId: z.string(),
  previewURL: z.string().url(),
  signerRole: z.nativeEnum(DropboxDocumentSignerRole),
  customFields: z.record(z.nativeEnum(SignableDocumentCustomFieldKey), z.string()),
  isDraft: z.boolean(),
  subject: z.string(),
  message: z.string(),
});

export type CreateSignableDocumentBody = z.infer<typeof createSignableDocumentSchema>;
export type UpdateSignableDocumentBody = z.infer<typeof updateSignableDocumentSchema>;
