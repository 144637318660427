import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();
export const benefitsChecksContract = c.router({
  updateAllPendingBenefitsChecksWithSilna: {
    method: "GET",
    path: "/benefits-checks/update-all-pending-benefits-checks-with-silna",
    responses: {
      200: z.object({
        success: z.boolean(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
