import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { BillingCode } from "../types";
import {
  aggregateInsightsPayloadSchema,
  CANCELLATION_GROUP_SEGMENTS,
  cancellationDataSchema,
  EFFICIENCY_GROUP_SEGMENTS,
  INSIGHTS_INCREMENT,
  NC_GROUP_SEGMENTS,
  ncBarChartItemSchema,
  ncLineChartItemSchema,
  SR_GROUP_SEGMENTS,
  UTILIZATION_GROUP_SEGMENTS,
} from "../types/insights";

const c = initContract();
export const insightsContract = c.router({
  // GET Efficiency for a specific clinic
  getClinicEfficiency: {
    method: "GET",
    path: "/clinics/:clinicId/insights/efficiency",
    pathParams: z.object({
      clinicId: z.string(),
    }),
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      segment: z.nativeEnum(EFFICIENCY_GROUP_SEGMENTS),
      increment: z.nativeEnum(INSIGHTS_INCREMENT),
    }),
    responses: {
      200: z.object({
        data: z.object({
          clinicId: z.string(),
          endMs: z.number(),
          segment: z.nativeEnum(EFFICIENCY_GROUP_SEGMENTS),
          startMs: z.number(),
          efficiencies: z.object({
            employees: z.array(
              z.object({
                employee: z.object({
                  id: z.string(),
                  name: z.string(),
                }),
                efficiencies: z.record(
                  z.string(),
                  z.object({
                    efficiency: z.number(),
                    hoursAvailable: z.number(),
                    hoursScheduled: z.number(),
                  })
                ),
                totalEfficiency: z.number(),
              })
            ),
            clients: z.array(
              z.object({
                client: z.object({
                  id: z.string(),
                  name: z.string(),
                }),
                efficiencies: z.record(
                  z.string(),
                  z.object({
                    efficiency: z.number(),
                    hoursAvailable: z.number(),
                    hoursScheduled: z.number(),
                  })
                ),
                totalEfficiency: z.number(),
              })
            ),
          }),
        }),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getClinicUtilization: {
    method: "GET",
    path: "/clinics/:clinicId/insights/utilization",
    pathParams: z.object({
      clinicId: z.string(),
    }),
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      segment: z.nativeEnum(UTILIZATION_GROUP_SEGMENTS),
      increment: z.nativeEnum(INSIGHTS_INCREMENT),
    }),
    responses: {
      200: z.object({
        data: z.object({
          clinicId: z.string(),
          endMs: z.number(),
          segment: z.nativeEnum(UTILIZATION_GROUP_SEGMENTS),
          startMs: z.number(),
          utilizations: z.object({
            supervisors: z.array(
              z.object({
                supervisor: z.object({
                  id: z.string(),
                  name: z.string(),
                }),
                utilizations: z.record(
                  z.string(),
                  z.record(
                    z.nativeEnum(BillingCode),
                    z.object({
                      utilizationRatio: z.number(),
                      scheduledHours: z.number(),
                      billedHours: z.number(),
                      authorizedHours: z.number(),
                    })
                  )
                ),
                totalUtilization: z.number(),
                totalScheduledHours: z.number(),
                totalBilledHours: z.number(),
                totalAuthorizedHours: z.number(),
              })
            ),
            clients: z.array(
              z.object({
                client: z.object({
                  id: z.string(),
                  name: z.string(),
                }),
                utilizations: z.record(
                  z.string(),
                  z.record(
                    z.nativeEnum(BillingCode),
                    z.object({
                      utilizationRatio: z.number(),
                      scheduledHours: z.number(),
                      billedHours: z.number(),
                      authorizedHours: z.number(),
                    })
                  )
                ),
                totalUtilization: z.number(),
                totalScheduledHours: z.number(),
                totalBilledHours: z.number(),
                totalAuthorizedHours: z.number(),
              })
            ),
          }),
        }),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getClinicSupervisionRatio: {
    method: "GET",
    path: "/clinics/:clinicId/insights/supervision-ratio",
    pathParams: z.object({
      clinicId: z.string(),
    }),
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      segment: z.nativeEnum(SR_GROUP_SEGMENTS),
      increment: z.nativeEnum(INSIGHTS_INCREMENT),
    }),
    responses: {
      200: z.object({
        data: z.object({
          clinicId: z.string(),
          endMs: z.number(),
          segment: z.nativeEnum(SR_GROUP_SEGMENTS),
          startMs: z.number(),
          supervisionRatios: z.object({
            technicians: z.array(
              z.object({
                technician: z.object({
                  id: z.string(),
                  name: z.string(),
                }),
                supervisionRatios: z.record(
                  z.string(),
                  z.object({
                    ratio: z.number().nullable(),
                    direct: z.number(),
                    supervision: z.number(),
                  })
                ),
                totalSupervisionRatio: z.number().nullable(),
              })
            ),
            clients: z.array(
              z.object({
                client: z.object({
                  id: z.string(),
                  name: z.string(),
                }),
                supervisionRatios: z.record(
                  z.string(),
                  z.object({
                    ratio: z.number().nullable(),
                    direct: z.number(),
                    supervision: z.number(),
                  })
                ),
                totalSupervisionRatio: z.number().nullable(),
              })
            ),
          }),
        }),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getClinicNotesCompliance: {
    method: "GET",
    path: "/clinics/:clinicId/insights/notes-compliance",
    pathParams: z.object({
      clinicId: z.string(),
    }),
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      segment: z.nativeEnum(NC_GROUP_SEGMENTS),
      increment: z.nativeEnum(INSIGHTS_INCREMENT),
    }),
    responses: {
      200: z.object({
        data: z.object({
          clinicId: z.string(),
          endMs: z.number(),
          segment: z.nativeEnum(NC_GROUP_SEGMENTS),
          startMs: z.number(),
          ncBarChartData: z.array(ncBarChartItemSchema),
          ncLineChartData: z.array(ncLineChartItemSchema),
        }),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  generateAndStoreAggregateEfficiencyInsights: {
    method: "POST",
    path: "/insights/generate/aggregate-efficiency",
    query: z.object({
      clinicId: z.string().optional(),
      dateRange: z
        .object({
          startMs: z.coerce.number(),
          endMs: z.coerce.number(),
        })
        .optional(),
    }),
    body: c.noBody(),
    responses: {
      200: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  generateAndStoreAggregateSupervisionRatioInsights: {
    method: "POST",
    path: "/insights/generate/aggregate-supervision-ratio",
    query: z.object({
      clinicId: z.string().optional(),
      dateRange: z.object({ startMs: z.coerce.number(), endMs: z.coerce.number() }).optional(),
    }),
    body: c.noBody(),
    responses: {
      200: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getAggregateInsights: {
    method: "GET",
    path: "/insights/stored/aggregate",
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      clinicId: z.string().optional(),
    }),
    responses: {
      200: z.object({
        data: z.array(aggregateInsightsPayloadSchema),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getClinicCancellations: {
    method: "GET",
    path: "/clinics/:clinicId/insights/cancellations",
    pathParams: z.object({
      clinicId: z.string(),
    }),
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      segment: z.nativeEnum(CANCELLATION_GROUP_SEGMENTS),
      increment: z.nativeEnum(INSIGHTS_INCREMENT),
    }),
    responses: {
      200: z.object({
        data: cancellationDataSchema,
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
