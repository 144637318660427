import { benefitsChecksSchema } from "./benefitsChecks";
import { appointmentSchema, indirectSchema } from "./calendar";
import { enrollmentSchema } from "./enrollments";
import { authorizationSchema, guardianSchema, pendingAuthorizationSchema } from "./general";
import { clinicInsightsWeeklySchema } from "./insights";
import { clinicInsightsMonthlySchema } from "./insights";
import { insurancePlanSchema } from "./insurancePlans";
import { noteSchema } from "./notes";
import { pushNotificationStatusSchema, pushNotificationTokenSchema } from "./push-notifications";
import { signableDocumentSchema } from "./signableDocuments";

export enum FirestoreCollectionName {
  APPOINTMENTS = "appointments",
  AUTHORIZATIONS = "authorizations",
  BILLED_APPOINTMENTS = "billed-appointments",
  BENEFITS_CHECKS = "benefits-checks",
  CLIENT_AVAILABILITY = "client-availability",
  INSURANCE_PLANS = "insurance-plans",
  CLIENT_FILES = "client-files",
  CLIENT_PINS = "client-pins",
  CLIENTS = "clients",
  CLINICS = "clinics",
  COMPLETED_APPOINTMENTS = "completed-appointments",
  APPOINTMENT_AUDIT_RESULTS = "appointment-audit-results",
  DEPLOY_METADATA = "deploy-metadata",
  EVENT_LINES = "event-lines",
  ENROLLMENTS = "enrollments",
  GUARDIANS = "guardians",
  INQUIRES = "inquiries",
  INDIRECTS = "indirects",
  INVITES = "invites",
  LIBRARIES = "libraries",
  LIBRARY_TAGS = "library-tags",
  MEASUREMENTS = "measurements",
  NOTES = "notes",
  PUSH_NOTIFICATION_STATUSES = "push-notification-statuses",
  PUSH_NOTIFICATION_TOKENS = "push-notification-tokens",
  // @deprecated - Collection is no longer in use
  PAYER_VERIFICATION = "payer-verification",
  PAYERS = "payers",
  PAY_PERIOD_CLIENT_ADJUSTMENTS = "client-adjustments",
  PAY_PERIOD_CLINIC_ADJUSTMENTS = "clinic-adjustments",
  PAY_PERIOD_INFO = "pay-period-info",
  PAY_PERIOD_PAYROLL = "payroll",
  PAY_PERIOD_STAFF_ADJUSTMENTS = "staff-adjustments",
  PAY_PERIOD_SUBCONTRACTING_ADJUSTMENTS = "subcontracting-adjustments",
  PENDING_AUTHORIZATIONS = "pending-authorizations",
  PROGRAMS = "programs",
  PROGRAM_TEMPLATES = "program-templates",
  PROMPTS = "prompts",
  SESSIONS = "sessions",
  SIGNABLE_DOCUMENTS = "signable-documents",
  STATUS_TRANSITIONS = "status-transitions",
  TARGETS = "targets",
  TARGET_GROUPS = "target-groups",
  TRANSITION_CRITERIA = "transition-criteria",
  TARGET_CHAINS = "target-chains",
  UPDATE_WORKING_HOURS = "update-working-hours",
  USER_AVAILABILITY = "user-availability",
  USER_PAY_RATES = "user-pay-rates",
  USERS = "users",
  CLINIC_INSIGHTS_WEEKLY = "insights-weekly",
  CLINIC_INSIGHTS_MONTHLY = "insights-monthly",
}

export const firestoreCollectionSchemas = {
  [FirestoreCollectionName.ENROLLMENTS]: enrollmentSchema,
  [FirestoreCollectionName.INDIRECTS]: indirectSchema,
  [FirestoreCollectionName.NOTES]: noteSchema,
  [FirestoreCollectionName.BENEFITS_CHECKS]: benefitsChecksSchema,
  [FirestoreCollectionName.APPOINTMENTS]: appointmentSchema,
  [FirestoreCollectionName.GUARDIANS]: guardianSchema,
  [FirestoreCollectionName.PENDING_AUTHORIZATIONS]: pendingAuthorizationSchema,
  [FirestoreCollectionName.PUSH_NOTIFICATION_TOKENS]: pushNotificationTokenSchema,
  [FirestoreCollectionName.PUSH_NOTIFICATION_STATUSES]: pushNotificationStatusSchema,
  [FirestoreCollectionName.SIGNABLE_DOCUMENTS]: signableDocumentSchema,
  [FirestoreCollectionName.INSURANCE_PLANS]: insurancePlanSchema,
  [FirestoreCollectionName.AUTHORIZATIONS]: authorizationSchema,
  [FirestoreCollectionName.CLINIC_INSIGHTS_WEEKLY]: clinicInsightsWeeklySchema,
  [FirestoreCollectionName.CLINIC_INSIGHTS_MONTHLY]: clinicInsightsMonthlySchema,
};
