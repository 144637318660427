import { z } from "zod";

export const SilnaPatientSchema = z.object({
  id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  date_of_birth: z.string(),
  patient_code: z.string(),
  patient_specialties: z.array(z.literal("ABA_THERAPY")),
  provider_service_location_id: z.string(),
  status: z.enum(["ACTIVE", "INTAKE", "DISCHARGED", "ON_HOLD"]),
  patient_diagnosis_ids: z.array(z.string()).nullable(),
  patient_place_of_service_ids: z.array(z.string()).nullable(),
  source_id: z.string().nullable(),
  zip_code: z.string().nullable(),
});

export const SilnaPlanSchema = z.object({
  id: z.string(),
  member_number: z.string(),
  patient_id: z.string(),
  payor_entity_id: z.string(),
  plan_eligibility_status: z.enum(["ACTIVE", "INACTIVE", "NO_CHECK"]),
  plan_order_number: z.number(),
  provider_id: z.string(),
  archived_at: z.string().nullable(),
  end_date: z.string().nullable(),
  group_number: z.string().nullable(),
  insurance_card_back_file_id: z.string().nullable().optional(),
  insurance_card_front_file_id: z.string().nullable().optional(),
  insurance_type: z
    .enum([
      "COMMERCIAL",
      "MEDICARE",
      "MEDICAID",
      "REGIONAL_CENTER",
      "WORKERS_COMPENSATION",
      "AUTO_INSURANCE",
    ])
    .nullable(),
  is_primary: z.boolean().nullable(),
  latest_benefits_check: z
    .object({
      benefits_check_id: z.string(),
      status: z.enum([
        "REQUESTED",
        "EDI_PROCESSING",
        "AWAITING_SILNA",
        "AWAITING_PROVIDER",
        "COMPLETE",
        "WITHDRAWN",
      ]),
      date_verified: z.string().nullable(),
    })
    .nullable(),
  latest_eligibility_check: z
    .object({
      eligibility_check_id: z.string(),
      status: z.enum(["ELIGIBLE", "INELIGIBLE", "INCOMPLETE", "AWAITING_PROVIDER", "WITHDRAWN"]),
      date_verified: z.string().nullable(),
    })
    .nullable(),
  source_id: z.string().nullable(),
  start_date: z.string().nullable(),
});

export const SilnaPayerSchema = z.object({
  id: z.string(),
  name: z.string(),
  alternate_identifiers: z.array(
    z.object({
      identifier: z.string(),
      payor_types: z.array(
        z.enum([
          "COMMERCIAL",
          "MEDICARE",
          "MEDICAID",
          "REGIONAL_CENTER",
          "WORKERS_COMPENSATION",
          "AUTO_INSURANCE",
        ])
      ),
      standard: z.enum(["AVAILITY", "WAYSTAR", "CHANGE_HEALTHCARE", "OFFICE_ALLY", "CLAIM_MD"]),
    })
  ),
});

export const SilnaSpecialtySpecificDataSchema = z.object({
  specialty: z.literal("ABA_THERAPY"),
  auth_required: z.boolean().nullable(),
  referral_required: z.boolean().nullable(),
  referral_info: z.string().nullable(),
  requirement_time: z.enum(["AFTER_SERVICES", "BEFORE_SERVICES"]).nullable(),
  requirement_quantity: z.number().nullable(),
  requirement_unit: z.enum(["CPT_UNITS", "VISITS"]).nullable(),
  auth_company: z.string().nullable(),
  has_visit_limit: z.boolean().nullable(),
  visit_limit_quantity: z.number().nullable(),
  visits_used: z.number().nullable(),
  copay_amount: z.string().nullable(),
  coinsurance_percentage: z.string().nullable(),
  individual_deductible_amount: z.string().nullable(),
  individual_deductible_amount_met: z.string().nullable(),
  family_deductible_amount: z.string().nullable(),
  family_deductible_amount_met: z.string().nullable(),
  individual_out_of_pocket_amount: z.string().nullable(),
  individual_out_of_pocket_amount_met: z.string().nullable(),
  family_out_of_pocket_amount: z.string().nullable(),
  family_out_of_pocket_amount_met: z.string().nullable(),
  medicare_dollars_used: z.string().nullable(),
  reimbursement_rate: z.string().nullable(),
  exclusions_and_limitations: z.string().nullable(),
  patient_cost_estimates: z.array(
    z.object({
      cost_estimate_name: z.string(),
      cost_of_service: z.string(),
    })
  ),
});

export const SilnaBenefitsCheckSchema = z.object({
  id: z.string(),
  status: z.enum([
    "REQUESTED",
    "EDI_PROCESSING",
    "AWAITING_SILNA",
    "AWAITING_PROVIDER",
    "COMPLETE",
    "WITHDRAWN",
  ]),
  eligibility_status: z.enum(["ELIGIBLE", "INELIGIBLE"]).nullable(),
  reference: z.string().nullable(),
  insurance_type: z
    .enum([
      "COMMERCIAL",
      "MEDICARE",
      "MEDICAID",
      "REGIONAL_CENTER",
      "WORKERS_COMPENSATION",
      "AUTO_INSURANCE",
    ])
    .nullable(),
  note: z.string().nullable(),
  date_verified: z.string().nullable(),
  patient_plan_id: z.string(),
  patient_id: z.string(),
  provider_id: z.string(),
  specialty_specific_data: z.array(SilnaSpecialtySpecificDataSchema),
  network_status: z.enum(["IN_NETWORK", "OUT_OF_NETWORK"]).nullable(),
  plan_type: z.string().nullable(),
  plan_start_date: z.string().nullable(),
  plan_end_date: z.string().nullable(),
  patient_information: z.object({
    subscriber_first_name: z.string().nullable(),
    subscriber_last_name: z.string().nullable(),
    subscriber_date_of_birth: z.string().nullable(),
    subscriber_address: z.string().nullable(),
    subscriber_state: z.string().nullable(),
    subscriber_city: z.string().nullable(),
    subscriber_zip_code: z.string().nullable(),
    subscriber_patient_relationship: z.string().nullable(),
  }),
  created_at: z.string(),
});

// Add type inference from the schemas
export type ISilnaPatient = z.infer<typeof SilnaPatientSchema>;
export type ISilnaPlan = z.infer<typeof SilnaPlanSchema>;
export type ISilnaPayer = z.infer<typeof SilnaPayerSchema>;
export type ISilnaSpecialtySpecificData = z.infer<typeof SilnaSpecialtySpecificDataSchema>;
export type ISilnaBenefitsCheck = z.infer<typeof SilnaBenefitsCheckSchema>;
