import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import { IClientFile, PayerLevel } from "@finni-health/shared";
import {
  Avatar,
  Button,
  Card,
  Col,
  message,
  Row,
  Spin,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import lodash from "lodash";
const { isEmpty } = lodash;
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import app from "../../services/firebase";
import { tsr } from "../../services/tsr";
import { AuthContext } from "../AuthProvider";

const { Title, Text } = Typography;
export interface IMissingInsuranceTaskProps {
  clientFile: IClientFile;
}

export const MissingInsuranceTask = ({ clientFile }: IMissingInsuranceTaskProps) => {
  const { client } = useContext(AuthContext);
  const [isFrontUploading, setIsFrontUploading] = useState<boolean>(false);
  const [isBackUploading, setIsBackUploading] = useState<boolean>(false);
  const [cardFrontFileList, setCardFrontFileList] = useState<UploadFile[]>([]);
  const [cardBackFileList, setCardBackFileList] = useState<UploadFile[]>([]);
  const [frontPhotoUrl, setFrontPhotoURL] = useState<string>("");
  const [backPhotoUrl, setBackPhotoURL] = useState<string>("");
  const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false);
  const location = useLocation();

  const insurancePlansQuery = tsr.clients.getActiveInsurancePlans.useQuery({
    queryData: { params: { clientId: client?.id ?? "" } },
    queryKey: ["clients.getActiveInsurancePlans", client?.id],
    enabled: Boolean(client?.id),
  });

  // Set initial photo URLs from the insurance plan data
  useEffect(() => {
    if (insurancePlansQuery.data?.body.primaryInsurancePlan && !initialLoadComplete) {
      const primaryPlan = insurancePlansQuery.data.body.primaryInsurancePlan;
      const frontUrl = primaryPlan.photoURLs?.[0] || "";
      const backUrl = primaryPlan.photoURLs?.[1] || "";

      if (frontUrl) setFrontPhotoURL(frontUrl);
      if (backUrl) setBackPhotoURL(backUrl);

      setInitialLoadComplete(true);
    }
  }, [insurancePlansQuery.data?.body.primaryInsurancePlan, initialLoadComplete]);

  // Handle updates to the insurance plan when photos change
  useEffect(() => {
    // Skip the effect on initial load or if no changes
    if (!initialLoadComplete) return;

    const primaryInsurancePlan = insurancePlansQuery.data?.body.primaryInsurancePlan;
    const [oldFrontUrl, oldBackUrl] = primaryInsurancePlan?.photoURLs || ["", ""];

    // Only update if there's an actual change to the URLs
    if (frontPhotoUrl !== oldFrontUrl || backPhotoUrl !== oldBackUrl) {
      if (primaryInsurancePlan) {
        // Update existing plan
        void tsr.insurancePlans.update.mutate({
          params: {
            insurancePlanId: primaryInsurancePlan.id,
          },
          body: {
            photoURLs: [frontPhotoUrl, backPhotoUrl],
          },
        });
      } else if (frontPhotoUrl || backPhotoUrl) {
        // Create new plan if we have at least one photo
        void tsr.insurancePlans.create.mutate({
          body: {
            payerId: "unknown",
            clientId: client.id,
            clinicId: client.clinicId,
            silnaPatientPlanId: null,
            payerLevel: PayerLevel.PRIMARY,
            memberNumber: null,
            groupNumber: null,
            policyHolder: null,
            policyHolderRelationship: null,
            photoURLs: [frontPhotoUrl, backPhotoUrl],
            startMs: 0,
            endMs: null,
            deletedAt: null,
          },
        });
      }
    }
  }, [frontPhotoUrl, backPhotoUrl, initialLoadComplete]);

  const getUploadProps = (
    fileList: any,
    setFileList: (fileList: UploadFile[]) => void,
    storageLocation: string,
    setURL: (url: string) => void,
    setIsUploading: (isUploading: boolean) => void
  ): UploadProps => {
    return {
      name: "file",
      accept: "image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp",
      multiple: false,
      showUploadList: false,
      listType: "picture",
      beforeUpload: async (file) => {
        //file size larger than 10MB (10000000 Bytes)
        if (file.size > 10000000) {
          void message.error("Your file is too large. Please select a file under 10MB");
          setFileList([]);
          return false;
        }

        setFileList([file]);
        setIsUploading(true);

        try {
          const fileRef = ref(
            getStorage(app),
            `clientFiles/${clientFile.id}/insurance/${storageLocation}`
          );
          const uploadResult = await uploadBytes(fileRef, file);
          const tempURL = await getDownloadURL(uploadResult.ref);
          setURL(tempURL);

          ReactGA.event("insurance_upload", {
            page_path: location.pathname + location.search + location.hash,
          });

          void message.success("Successfully Uploaded");
        } catch (error) {
          void message.error("Failed to upload file. Please try again.");
          console.error("Upload error:", error);
        } finally {
          setIsUploading(false);
        }

        // Prevent remote upload
        return false;
      },
      fileList,
    };
  };

  const renderPhotoCard = (
    photoUrl: string,
    uploadProps: Partial<UploadProps>,
    fileList: any[],
    label: string
  ) => (
    <Card
      style={{
        marginTop: 5,
        marginBottom: 5,
        width: "100%",
      }}
      bodyStyle={{ padding: "14px 8px" }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <Avatar shape="square" src={photoUrl} />
        </Col>
        <Col span={14}>
          <Text>{label}</Text>
        </Col>
        <Col>
          <Upload {...uploadProps} fileList={fileList}>
            <Button type="text">
              <EditOutlined />
            </Button>
          </Upload>
        </Col>
      </Row>
    </Card>
  );

  const uploadCardFrontProps = getUploadProps(
    cardFrontFileList,
    setCardFrontFileList,
    "primary/front",
    setFrontPhotoURL,
    setIsFrontUploading
  );

  const uploadCardBackProps = getUploadProps(
    cardBackFileList,
    setCardBackFileList,
    "primary/back",
    setBackPhotoURL,
    setIsBackUploading
  );

  return (
    <Card
      style={{ width: "100%", margin: "0 10px" }}
      title={
        <Row align="middle" justify="space-between">
          <Title level={4} style={{ marginTop: 5, marginBottom: 5 }}>
            Insurance Card
          </Title>
        </Row>
      }
    >
      <Row align="middle" justify="space-between">
        <Text>
          {!isEmpty(frontPhotoUrl) && !isEmpty(backPhotoUrl)
            ? "Thanks for uploading your insurance card! We are reaching out to confirm eligibility."
            : "Please upload pictures of your insurance card."}
        </Text>
      </Row>
      <Row align="middle">
        {isEmpty(frontPhotoUrl) ? (
          <Col span={24}>
            <Dragger
              {...uploadCardFrontProps}
              style={{
                marginTop: 5,
                marginBottom: 5,
                width: "100%",
              }}
            >
              {isFrontUploading ? (
                <Spin />
              ) : (
                <>
                  <Text className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </Text>
                  <Text>Front</Text>
                </>
              )}
            </Dragger>
          </Col>
        ) : (
          renderPhotoCard(frontPhotoUrl, uploadCardFrontProps, cardFrontFileList, "Front")
        )}
      </Row>
      <Row justify="space-between" align="middle">
        {isEmpty(backPhotoUrl) ? (
          <Col span={24}>
            <Dragger
              {...uploadCardBackProps}
              style={{
                marginTop: 5,
                marginBottom: 5,
                width: "100%",
              }}
            >
              {isBackUploading ? (
                <Spin />
              ) : (
                <>
                  <Text className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </Text>
                  <Text className="ant-upload-text">Back</Text>
                </>
              )}
            </Dragger>
          </Col>
        ) : (
          renderPhotoCard(backPhotoUrl, uploadCardBackProps, cardBackFileList, "Back")
        )}
      </Row>
    </Card>
  );
};
