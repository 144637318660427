import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();
export const appointmentsContract = c.router({
  matchWithNote: {
    method: "POST",
    path: "/appointments/:appointmentId/match-note",
    pathParams: z.object({
      appointmentId: z.string(),
    }),
    body: z.object({
      clinicId: z.string(),
      noteId: z.string(),
    }),
    responses: {
      200: z.null(),
      404: z.object({
        message: z.string(),
      }),
      422: z.object({
        message: z.string(),
      }),
    },
  },
  updateAppointmentAndNoteTime: {
    method: "PATCH",
    path: "/appointments/update-appointment-and-note-time",
    body: z.object({
      id: z.string(),
      noteId: z.string(),
      startMs: z.number().nullable().optional(),
      endMs: z.number().nullable().optional(),
    }),
    responses: {
      200: z.null(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  checkGuardrails: {
    method: "POST",
    path: "/appointments/check-guardrails",
    body: z.object({
      id: z.string(),
    }),
    responses: {
      200: z.object({
        passed: z.boolean(),
        violations: z.array(
          z.object({
            title: z.string(),
            details: z.string(),
          })
        ),
      }),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  checkClientEligibility: {
    method: "POST",
    path: "/appointments/check-client-eligibility",
    body: z.object({
      value: z.string(),
    }),
    responses: {
      200: z.object({
        messages: z.array(z.string()),
      }),
      404: z.object({
        messages: z.array(z.string()),
      }),
      500: z.object({
        messages: z.array(z.string()),
      }),
    },
  },
});
