import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import {
  buildDropboxCommonPayload,
  DEBUG_DROPBOX,
  DropboxDocumentType,
  getLatestSignableDocument,
  IClient,
  IClientFile,
  ISignedDropboxDocument,
  SignableDocument,
} from "@finni-health/shared";
import { Button, Card, Divider, List, message, Row, Typography } from "antd";
import * as HelloSign from "hellosign-embedded";
import { useContext, useEffect, useRef, useState } from "react";

import { useGetSignableDocuments } from "../../hooks/useGetSignableDocuments";
import * as FirestoreService from "../../services/firestore";
import { AuthContext } from "../AuthProvider";

// Hack the types for the library
const dropboxClient = new (HelloSign as any)({
  clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
});

const { Title, Text } = Typography;
export interface IProps {
  clientFile: IClientFile;
  client: IClient;
  refreshClientFile: () => void;
}

export const ConsentFormsTask = ({ clientFile, client, refreshClientFile }: IProps) => {
  const { guardian, clinic } = useContext(AuthContext);

  const [signedLatestDocuments, setSignedLatestDocuments] = useState<
    (SignableDocument & ISignedDropboxDocument)[]
  >([]);
  const [upNextDocuments, setUpNextDocuments] = useState<SignableDocument[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const retriesRef = useRef<number>(0); // avoid re-renders
  const currentDocBeingSignedRef = useRef<string | undefined>(); // avoid re-renders

  const { signableDocuments } = useGetSignableDocuments(clinic.id);
  const documentsToSign = Object.values(DropboxDocumentType)
    .map((documentType) => getLatestSignableDocument({ signableDocuments, documentType }))
    .filter(Boolean); // Remove any undefined results (document types with no documents)

  const handleDownloadDocument = async (document: ISignedDropboxDocument) => {
    const signatureRequestId = document.signatureRequestId;

    try {
      const pdf = await FirestoreService.downloadSignedDocument({
        signatureRequestId,
      });

      window.open(pdf.fileUrl, "_blank");
    } catch (error) {
      console.error("error", error);
      void message.error(
        "Oops! Something went wrong. Please try again or reach out to care@finnihealth.com for help"
      );
    }
  };

  const handleGetEmbbedUrl = async (signableDocument: SignableDocument) => {
    try {
      currentDocBeingSignedRef.current = signableDocument.id;
      const formData = {
        formName: signableDocument.documentType,
        ...buildDropboxCommonPayload({ client, guardian, clinic, signableDocument }),
      };
      const embedResponse = await FirestoreService.getEmbeddedUrl(formData);

      if (embedResponse.signUrl && embedResponse.expiresAt) {
        openEmbedWindow(embedResponse.signUrl);
      } else {
        currentDocBeingSignedRef.current = undefined;

        void message.error(
          "Oops! Something went wrong. Please try again or reach out to care@finnihealth.com for help"
        );
      }
    } catch (error) {
      currentDocBeingSignedRef.current = undefined;

      console.error(error);
      void message.error(
        "Oops! Something went wrong. Please try again or reach out to care@finnihealth.com for help"
      );
    }
  };

  const openEmbedWindow = (url: string) => {
    dropboxClient.open(url, {
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
      allowCancel: true,
      // Set these both to true when testing
      skipDomainVerification: DEBUG_DROPBOX,
      testMode: DEBUG_DROPBOX,
    });
  };

  // Get the last signed document for each document type
  const getLatestSignedDocuments = (
    signedDocuments: ISignedDropboxDocument[]
  ): (SignableDocument & ISignedDropboxDocument)[] => {
    const signedDocs = [];

    for (const document of documentsToSign) {
      const signed = signedDocuments.filter(
        (signedDocument) => signedDocument.signableDocumentId === document.id
      );

      if (signed.length === 0) {
        continue;
      }

      const sorted = signed.sort((a, b) => b.signedAt - a.signedAt);
      signedDocs.push({
        ...sorted[0], // ISignedDropboxDocument
        ...document, // SignableDocument
      });
    }

    return signedDocs;
  };

  useEffect(() => {
    const latestSignedDocuments = getLatestSignedDocuments(clientFile.signedDocuments || []);

    // We can afford to just check the length here because
    // it will increase by 1 when a new document is signed
    if (latestSignedDocuments.length !== signedLatestDocuments.length) {
      setSignedLatestDocuments(latestSignedDocuments);
      setIsLoading(false);
    }

    const signedDocumentIds = latestSignedDocuments.map((document) => document.signableDocumentId);
    const documentsRequiringSignature = documentsToSign.filter(
      ({ id }) => !signedDocumentIds.includes(id)
    );
    setUpNextDocuments(documentsRequiringSignature);
  }, [clientFile, signableDocuments]);

  // Listen for the finish event so we know when the document has been signed
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const handleFinish = (): void => {
      setIsLoading(true);

      const checkAndUpdateClientFile = async () => {
        const freshClientFile = await FirestoreService.getClientFileByGuardianId(guardian.id);

        const formHasBeenSigned = freshClientFile.signedDocuments?.find(
          (doc: ISignedDropboxDocument) =>
            doc.signableDocumentId === currentDocBeingSignedRef.current
        );

        if (formHasBeenSigned) {
          refreshClientFile();
          clearInterval(intervalId);
          currentDocBeingSignedRef.current = undefined;

          return;
        }

        if (retriesRef.current >= 10) {
          console.info("Exceeded max retries, please refresh the page");
          refreshClientFile();
          clearInterval(intervalId);
          currentDocBeingSignedRef.current = undefined;

          void message.error("Exceeded max retries, please refresh the page");
          return;
        }

        retriesRef.current += 1;
      };

      intervalId = setInterval(checkAndUpdateClientFile, 4000);
    };

    dropboxClient.on("finish", handleFinish);

    return () => {
      dropboxClient.off("finish", handleFinish);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Card
      style={{
        width: "100%",
        margin: "0 10px",
        whiteSpace: "pre-wrap",
      }}
      title={
        <Row align="middle" justify="space-between">
          <Title level={4} style={{ marginTop: 5, marginBottom: 5 }}>
            Handbooks and Consent Forms
          </Title>
        </Row>
      }
    >
      <Text
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        Download a copy for your records.
      </Text>
      <Row style={{ width: "100%", marginTop: 5 }}>
        <List
          size="small"
          style={{ width: "100%" }}
          bordered
          // Here we only show the latest signed document for each document type
          dataSource={signedLatestDocuments}
          renderItem={(document: SignableDocument & ISignedDropboxDocument) => (
            <List.Item
              actions={[
                <Button
                  key={document.signatureRequestId}
                  type="text"
                  onClick={() => handleDownloadDocument(document)}
                  icon={<DownloadOutlined />}
                />,
              ]}
            >
              <Row>{document.title}</Row>
            </List.Item>
          )}
        />
      </Row>

      {upNextDocuments.length > 0 && (
        <>
          <Divider />
          <Title level={4} style={{ marginTop: 5, marginBottom: 5 }}>
            Other Consent Forms
          </Title>
          <Row style={{ width: "100%", marginTop: 5 }}>
            <List
              size="small"
              style={{ width: "100%", marginTop: 5 }}
              bordered
              dataSource={upNextDocuments}
              renderItem={(doc: SignableDocument) => (
                <List.Item
                  actions={[
                    <Button
                      key={doc.id}
                      type="text"
                      onClick={() => handleGetEmbbedUrl(doc)}
                      loading={currentDocBeingSignedRef.current === doc.id && isLoading}
                      icon={<FilePdfOutlined />}
                    />,
                  ]}
                >
                  <Row>{doc.title}</Row>
                </List.Item>
              )}
            />
          </Row>
        </>
      )}
    </Card>
  );
};
