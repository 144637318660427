import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

const registerPushNotificationTokenBodySchema = z.object({
  token: z.string().min(1),
  platform: z.enum(["ios", "android"]),
  provider: z.enum(["expo"]),
  guardianId: z.string(),
});

export const pushNotificationsContract = c.router({
  registerToken: {
    method: "POST",
    path: "/push-notifications/register-token",
    body: registerPushNotificationTokenBodySchema,
    responses: {
      200: z.object({ success: z.boolean() }),
      500: z.object({ message: z.string() }),
    },
  },
  syncStatuses: {
    method: "POST",
    path: "/push-notifications/sync-statuses",
    body: z.object({}).nullish(),
    responses: {
      200: z.object({ success: z.boolean() }),
      500: z.object({ message: z.string() }),
    },
  },
  notifyUpcomingAppointments: {
    method: "POST",
    path: "/push-notifications/notify-upcoming-appointments",
    body: z.object({}).nullish(),
    responses: {
      200: z.object({ success: z.boolean() }),
      500: z.object({ message: z.string() }),
    },
  },
});
