import { startCase, toLower } from "lodash";

import { IClient, IUser } from "../types";

const ANTD_DEFAULT_COLORS = [
  "magenta",
  "purple",
  "volcano",
  "orange",
  "blue",
  "lime",
  "green",
  "cyan",
  "gold",
  "geekblue",
  "red",
];

export const removeUnderscoreAndCapitalize = (str: string) => {
  return startCase(toLower(str));
};

export const getLastUpdatedByString = (user: IUser) => {
  return `${user.firstName} ${user.lastName} (${user.email})`;
};

export const capitalizeName = (str: string) => {
  let nameArray = str.split(" ");
  nameArray = nameArray.map((name: string) => capitalizeFirstLetter(name));
  return nameArray.join(" ");
};

export const capitalizeFirstLetter = (str: string) => {
  return str && str[0].toUpperCase() + str.slice(1).toLocaleLowerCase();
};

export const getInitials = (firstName: string, lastName: string) => {
  return firstName.slice(0, 2) + lastName.slice(0, 2);
};

export const getInitialsFromClient = (client: IClient) => {
  return client && client.firstName.slice(0, 2) + client.lastName.slice(0, 2);
};

export const stringToHashNumber = (string: string) => {
  let hash = 0;
  if (!string) return hash;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const generatePastelColorWithAlpha = (string: string) => {
  const uniqueValue = stringToHashNumber(string);
  const r = uniqueValue % 255;
  const g = Math.floor(uniqueValue / 3) % 255;
  const b = Math.floor(uniqueValue / 5) % 255;
  return `rgba(${r}, ${g}, ${b}, ${0.3})`;
};

export const stringToColor = (string: string) => {
  let hash = stringToHashNumber(string);

  return ANTD_DEFAULT_COLORS[Math.abs(hash) % ANTD_DEFAULT_COLORS.length];
};

export const allCapsSnakeCaseToTitleCase = (str: string) => {
  // Convert to lowercase and then convert to title case
  return startCase(str.toLowerCase());
};

export const replaceSpecialCharactersWithUnicode = (str: string): string => {
  if (!str) return "";
  if (typeof str !== "string") return str; // If the input string is empty, return an empty string
  const replacements: Record<string, string> = {
    "/": "\u002F", // Replace '/' with Unicode for '/'
    ",": "\u002C", // Replace ',' with Unicode for ','
    ".": "\u002E", // Replace '.' with Unicode for '.'
    "(": "\u0028", // Replace '(' with Unicode for '('
    ")": "\u0029", // Replace ')' with Unicode for ')'
    "*": "\u002A", // Replace '*' with Unicode for '*'
    "&": "\u0026", // Replace '&' with Unicode for '&'
    "^": "\u005E", // Replace '^' with Unicode for '^'
    "%": "\u0025", // Replace '%' with Unicode for '%'
    $: "\u0024", // Replace '$' with Unicode for '$'
    "#": "\u0023", // Replace '#' with Unicode for '#'
    "@": "\u0040", // Replace '@' with Unicode for '@'
    "!": "\u0021", // Replace '!' with Unicode for '!'
    "'": "\u0027", // Replace "'" with Unicode for "'"
    '"': "\u0022", // Replace '"' with Unicode for '"'
    ":": "\u003A", // Replace ':' with Unicode for ':'
    ";": "\u003B", // Replace ';' with Unicode for ';'
    "?": "\u003F", // Replace '?' with Unicode for '?'
    ">": "\u003E", // Replace '>' with Unicode for '>'
    "<": "\u003C", // Replace '<' with Unicode for '<'
    "[": "\u005B", // Replace '[' with Unicode for '['
    "]": "\u005D", // Replace ']' with Unicode for ']'
    "{": "\u007B", // Replace '{' with Unicode for '{'
    "}": "\u007D", // Replace '}' with Unicode for '}'
    "\n": "\u000A", // Replace '\n' (line break) with Unicode for line break
    "~": "\u007E", // Replace '~' with Unicode for '~'
    "|": "\u007C", // Replace '|' with Unicode for '|'
    _: "\u005F", // Replace '_' with Unicode for '_'
    "=": "\u003D", // Replace '=' with Unicode for '='
    "+": "\u002B", // Replace '+' with Unicode for '+'
    "-": "\u002D", // Replace '-' with Unicode for '-'
    "\\": "\u005C", // Replace '\\' with Unicode for '\\'
  };

  // Use a regular expression to match any of the specified characters or line breaks
  const regex = new RegExp(`[${Object.keys(replacements).join("")}\n]`, "g");

  // Replace the matched characters or line breaks with their Unicode representations
  return String(str)
    .replace(regex, (match) => replacements[match])
    .replaceAll(",", "\\u002C"); // Extra case for double ,, for some reason it's not caught
};

export const camelCaseToText = (str: string) => {
  if (!str) return "";
  return (
    str
      // insert a space before all capital letters
      .replace(/([A-Z])/g, " $1")
      // capitalize the first letter
      .replace(/^./, (str) => str.toUpperCase())
      .trim()
  );
};
