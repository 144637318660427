import { IClient, IClientFile, isGeneralConsentFormSigned } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { useResponsive } from "@finni-health/ui";
import { Button, Col, Divider, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie-player";

import { ClientOnboardingFlow } from "../../consts";
import { isDocumentsComplete, isPayersComplete } from "../../helpers/client-file-status";
import { usePageViewTracking } from "../../hooks/usePageViewTracking";
import dogAnimation from "../../lottie/raise-dog.json";
import { Loading } from "../../pages/Loading";
import { getClientById } from "../../services/firestore";
import { AuthContext } from "../AuthProvider";
import { ConsentFormsTask } from "./ConsentFormsTask";
import { DiagnosticsUploadTask } from "./DiagnosticsUploadsTask";
import { IntakeTasksCard } from "./IntakeTasksCard";
import { MissingInsuranceTask } from "./MissingInsuranceTask";

const { Title, Text } = Typography;

interface IProps {
  clientFile: IClientFile;
  refreshClientFile: () => void;
}

export const PreapprovalComplete: React.FC<IProps> = ({
  clientFile,
  refreshClientFile,
}: IProps) => {
  const { guardian } = useContext(AuthContext);
  const [client, setClient] = useState<IClient>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isMobile } = useResponsive();

  usePageViewTracking(ClientOnboardingFlow.PREAPPROVAL_COMPLETE);

  useEffect(() => {
    fetchClient().catch(() => {});
  }, []);

  const fetchClient = async () => {
    setClient(await getClientById(clientFile.clientId));
    setIsLoading(false);
  };

  const payersComplete = isPayersComplete(clientFile);
  const documentsComplete = isDocumentsComplete(clientFile?.documents);
  const generalConsentComplete = isGeneralConsentFormSigned(clientFile.signedDocuments);

  const userHasTaskCards = payersComplete || documentsComplete;

  return isLoading ? (
    <Loading pageName={ClientOnboardingFlow.PREAPPROVAL_COMPLETE} />
  ) : (
    <>
      <Row justify="center">
        <Col span={isMobile ? 24 : 12}>
          <Title level={4} style={{ marginBottom: 10 }}>
            Thanks for completing your pre-approval, {guardian.firstName}!
          </Title>
          <Title level={5} style={{ marginTop: 0 }}>
            {`We can't wait to meet ${client?.firstName}, in the meantime give us a
            better idea of who they are by completing your intake questionnaire.`}
          </Title>
          <Text>
            {`Completing this will help us understand how we can best accommodate
            and customize ${client?.firstName}'s care. We'll reach out soon to
            schedule your initial assessment.`}
          </Text>
          <Text strong style={{ display: "block", marginTop: 15, fontSize: 14 }}>
            Have questions?
            <br />
            Call
            <Button
              type="text"
              href="tel:(575) 520-6074"
              style={{
                marginLeft: 5,
                marginRight: 5,
                paddingLeft: 5,
                paddingRight: 5,
                fontSize: 14,
                fontWeight: 500,
                background: COLORS.LIGHT_PURPLE + "26",
              }}
            >
              (575) 520-6074
            </Button>
            or email
            <Button
              type="text"
              href="mailto:care@finnihealth.com?subject=I have a question about the Finni enrollment process!"
              style={{
                marginLeft: 5,
                marginRight: 5,
                paddingLeft: 5,
                paddingRight: 5,
                fontSize: 14,
                fontWeight: 500,
                background: COLORS.LIGHT_PURPLE + "26",
              }}
            >
              care@finnihealth.com
            </Button>
          </Text>
        </Col>
        {!isMobile && (
          <Col>
            <div>
              <Lottie play loop animationData={dogAnimation} style={{ width: 300 }} />
            </div>
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: 50, width: "100%" }} justify="space-evenly">
        <Col
          span={isMobile ? "auto" : userHasTaskCards ? 14 : 20}
          style={{ marginBottom: 20, padding: "0 10px" }}
        >
          <Row justify="center">
            <IntakeTasksCard clientFile={clientFile} />
          </Row>
        </Col>
        {userHasTaskCards && (
          <Col span={isMobile ? "auto" : 10}>
            {documentsComplete && (
              <>
                <Row justify="center">
                  <DiagnosticsUploadTask clientFile={clientFile} />
                </Row>
              </>
            )}
            {payersComplete && (
              <Row justify="center">
                <Divider
                  type="vertical"
                  style={{ margin: 0, height: 20, borderLeftWidth: "2px" }}
                />
                <MissingInsuranceTask clientFile={clientFile} />
              </Row>
            )}
            {generalConsentComplete && (
              <Row justify="center">
                <Divider
                  type="vertical"
                  style={{ margin: 0, height: 20, borderLeftWidth: "2px" }}
                />
                <ConsentFormsTask
                  clientFile={clientFile}
                  refreshClientFile={refreshClientFile}
                  client={client || ({} as IClient)}
                />
              </Row>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};
