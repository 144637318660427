import { z } from "zod";

import { BillingCode } from "./general";

export enum INSIGHTS_DATE_RANGE {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
}

export enum INSIGHTS_INCREMENT {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export enum INSIGHTS_COLOR {
  ORANGE = "#E6820D",
  LIGHT_ORANGE = "#E6820D80",
  ALERT_ORANGE_BORDER = "#ffd3ad",
  ALERT_ORANGE_BACKGROUND = "#fff8f0",
}

export enum ALERT_COLOR {
  ORANGE_TEXT = "#E6820D",
  ORANGE_BORDER = "#ffd3ad",
  ORANGE_BACKGROUND = "#fff8f0",
}

export enum GRAPH_COLOR {
  // Primary colors to use first
  ORANGE = INSIGHTS_COLOR.ORANGE,
  LIGHT_ORANGE = INSIGHTS_COLOR.LIGHT_ORANGE,
  BLUE = "#0074D9",
  RED = "#f5222d",
  YELLOW = "#ffd166",
  GRAY = "#9B9BC2",
  GREEN = "#008000",
  PURPLE = "#9254de",

  // Additional colors
  LIGHT_BLUE = "#0074D980",
  TEAL = "#00C4B4",
  PINK = "#eb2f96",
  INDIGO = "#2f54eb",
  EMERALD = "#50C878",
  LAVENDER = "#9B6B9E",
  CORAL = "#FF6B6B",
  MINT = "#7DAA92",
  ROSE = "#E5C3D1",
  SLATE = "#A4A4A4",
  GOLD = "#FFD700",
  NAVY = "#481620",
  LIME = "#52c41a",
  CYAN = "#13c2c2",
  MAROON = "#722ed1",
  TAUPE = "#9BABC5",
}

export type BarSegment = {
  value: number;
  color: GRAPH_COLOR;
};

export type BarChartSegmentLabel = {
  label: string;
  color: GRAPH_COLOR;
};

export type BarChartItem<T> = {
  id: string;
  label: string;
  type: T;
  segments: BarSegment[];
};

// EFFICIENCY
export enum EFFICIENCY_GROUP {
  EMPLOYEES = "employees",
  CLIENTS = "clients",
}

export enum EFFICIENCY_GROUP_SEGMENTS {
  ALL = "all",
  EMPLOYEES = EFFICIENCY_GROUP.EMPLOYEES,
  CLIENTS = EFFICIENCY_GROUP.CLIENTS,
}

export type EfficiencyGroup = EFFICIENCY_GROUP;
export type EfficiencyGroupSegment = EFFICIENCY_GROUP_SEGMENTS;

export const EFFICIENCY_GROUP_COLORS_MAP: Record<EfficiencyGroup, GRAPH_COLOR> = {
  [EFFICIENCY_GROUP.CLIENTS]: GRAPH_COLOR.BLUE,
  [EFFICIENCY_GROUP.EMPLOYEES]: GRAPH_COLOR.ORANGE,
};

// UTILIZATION
export enum UTILIZATION_GROUP {
  CLIENTS = "clients",
  SUPERVISORS = "supervisors",
}

export enum UTILIZATION_GROUP_SEGMENTS {
  ALL = "all",
  CLIENTS = UTILIZATION_GROUP.CLIENTS,
  SUPERVISORS = UTILIZATION_GROUP.SUPERVISORS,
}

export type UtilizationGroup = UTILIZATION_GROUP;
export type UtilizationGroupSegment = UTILIZATION_GROUP_SEGMENTS;

export const UTILIZATION_GROUP_COLORS_MAP: Record<UtilizationGroup, GRAPH_COLOR> = {
  [UTILIZATION_GROUP.CLIENTS]: GRAPH_COLOR.ORANGE,
  [UTILIZATION_GROUP.SUPERVISORS]: GRAPH_COLOR.ORANGE,
};

export type UtilizationByCode = {
  utilizationRatio: number;
  scheduledHours: number;
  billedHours: number;
  authorizedHours: number;
};

export type SupervisorUtilization = {
  supervisor: {
    id: string;
    name: string;
  };
  utilizations: Record<string, Record<BillingCode, UtilizationByCode>>;
  totalUtilization: number;
  totalScheduledHours: number;
  totalBilledHours: number;
  totalAuthorizedHours: number;
  clientCount: number;
};

export type ClientUtilization = {
  client: {
    id: string;
    name: string;
  };
  utilizations: Record<string, Record<BillingCode, UtilizationByCode>>;
  totalUtilization: number;
  totalScheduledHours: number;
  totalBilledHours: number;
  totalAuthorizedHours: number;
};

export type UtilizationData = {
  clinicId: string;
  endMs: number;
  segment: string;
  startMs: number;
  utilizations: {
    supervisors: SupervisorUtilization[];
    clients: ClientUtilization[];
  };
};

// SUPERVISION RATIO
export enum SR_GROUP {
  TECHNICIANS = "technicians",
  CLIENTS = "clients",
}

export enum SR_GROUP_SEGMENTS {
  ALL = "all",
  TECHNICIANS = SR_GROUP.TECHNICIANS,
  CLIENTS = SR_GROUP.CLIENTS,
}

export type SRGroup = SR_GROUP;
export type SRGroupSegment = SR_GROUP_SEGMENTS;

export const SR_GROUP_COLORS_MAP: Record<SRGroup, GRAPH_COLOR> = {
  [SR_GROUP.CLIENTS]: GRAPH_COLOR.BLUE,
  [SR_GROUP.TECHNICIANS]: GRAPH_COLOR.ORANGE,
};

export enum NC_GROUP {
  SUPERVISORS = "supervisors",
  TECHNICIANS = "technicians",
}

export enum NC_GROUP_SEGMENTS {
  ALL = "all",
  SUPERVISORS = NC_GROUP.SUPERVISORS,
  TECHNICIANS = NC_GROUP.TECHNICIANS,
}

export type NCGroup = NC_GROUP;
export type NCGroupSegment = NC_GROUP_SEGMENTS;

export const NC_GROUP_COLORS_MAP: Record<NCGroup, GRAPH_COLOR> = {
  [NC_GROUP.SUPERVISORS]: GRAPH_COLOR.BLUE,
  [NC_GROUP.TECHNICIANS]: GRAPH_COLOR.ORANGE,
};

export const ncBarChartItemSchema = z.object({
  userId: z.string(),
  userFullName: z.string(),
  userGroup: z.enum([NC_GROUP.SUPERVISORS, NC_GROUP.TECHNICIANS]),
  compliantNoteCount: z.number(),
  nonCompliantNoteCount: z.number(),
  complianceRatio: z.number(),
});

export type NCBarChartItem = z.infer<typeof ncBarChartItemSchema>;

export const ncLineChartItemSchema = z.object({
  userId: z.string(),
  userFullName: z.string(),
  userGroup: z.enum([NC_GROUP.SUPERVISORS, NC_GROUP.TECHNICIANS]),
  compliantNoteCount: z.number(),
  nonCompliantNoteCount: z.number(),
  complianceRatio: z.number(),
  date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/), // Format: YYYY-MM-DD
});

export type NCLineChartItem = z.infer<typeof ncLineChartItemSchema>;

export const aggregateEfficiencySchema = z.object({
  employees: z
    .object({
      value: z.number(),
      hoursAvailable: z.number(),
      hoursScheduled: z.number(),
    })
    .nullable(),
  clients: z
    .object({
      value: z.number(),
      hoursAvailable: z.number(),
      hoursScheduled: z.number(),
    })
    .nullable(),
});

export type IAggregateEfficiency = z.infer<typeof aggregateEfficiencySchema>;

export const aggregateSupervisionRatioSchema = z.object({
  technicians: z
    .object({
      value: z.number(),
      supervisionHours: z.number(),
      directHours: z.number(),
    })
    .nullable(),
  clients: z
    .object({
      value: z.number(),
      supervisionHours: z.number(),
      directHours: z.number(),
    })
    .nullable(),
});

export type IAggregateSupervisionRatio = z.infer<typeof aggregateSupervisionRatioSchema>;

export const clinicInsightsWeeklySchema = z.object({
  id: z.string(),
  clinicId: z.string(),
  startOfWeekMs: z.number(),
  startOfWeekDate: z.string(),
  endOfWeekMs: z.number(),
  endOfWeekDate: z.string(),
  aggregateEfficiency: aggregateEfficiencySchema.optional(),
  aggregateSupervisionRatio: aggregateSupervisionRatioSchema.optional(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type IClinicInsightsWeekly = z.infer<typeof clinicInsightsWeeklySchema>;

export const clinicInsightsMonthlySchema = z.object({
  id: z.string(),
  clinicId: z.string(),
  startOfMonthMs: z.number(),
  startOfMonthDate: z.string(),
  endOfMonthMs: z.number(),
  endOfMonthDate: z.string(),
  aggregateEfficiency: aggregateEfficiencySchema.optional(),
  aggregateSupervisionRatio: aggregateSupervisionRatioSchema.optional(),
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export type IClinicInsightsMonthly = z.infer<typeof clinicInsightsMonthlySchema>;

export const aggregateInsightsPayloadSchema = z.object({
  clinicId: z.string(),
  clinicName: z.string(),
  weeklyInsights: z.array(clinicInsightsWeeklySchema),
  monthlyInsights: z.array(clinicInsightsMonthlySchema),
});

export type IAggregateInsightsPayload = z.infer<typeof aggregateInsightsPayloadSchema>;
// CANCELLATIONS

// Enums
export enum CANCELLATION_GROUP {
  CLIENTS = "clients",
  EMPLOYEES = "employees",
}

export enum CANCELLATION_GROUP_SEGMENTS {
  ALL = "all",
  CLIENTS = CANCELLATION_GROUP.CLIENTS,
  EMPLOYEES = CANCELLATION_GROUP.EMPLOYEES,
}

// Schemas
export const cancellationReasonCountSchema = z.object({
  reason: z.string(),
  count: z.number(),
  cancelledAt: z.number(),
  startMs: z.number(),
});

export const cancellationClientDataSchema = z.object({
  client: z.object({
    id: z.string(),
    name: z.string(),
  }),
  cancellations: z.record(z.string(), z.array(cancellationReasonCountSchema).nullable()),
  totalCancellations: z.number(),
});

export const cancellationEmployeeDataSchema = z.object({
  employee: z.object({
    id: z.string(),
    name: z.string(),
  }),
  cancellations: z.record(z.string(), z.array(cancellationReasonCountSchema).nullable()),
  totalCancellations: z.number(),
});

export const cancellationDataSchema = z.object({
  clinicId: z.string(),
  endMs: z.number(),
  segment: z.nativeEnum(CANCELLATION_GROUP_SEGMENTS),
  startMs: z.number(),
  cancellations: z.object({
    clients: z.array(cancellationClientDataSchema),
    employees: z.array(cancellationEmployeeDataSchema),
  }),
});

// Types
export type CancellationGroup = CANCELLATION_GROUP;
export type CancellationGroupSegment = CANCELLATION_GROUP_SEGMENTS;

export const CANCELLATION_GROUP_COLORS_MAP: Record<CancellationGroup, GRAPH_COLOR> = {
  [CANCELLATION_GROUP.CLIENTS]: GRAPH_COLOR.BLUE,
  [CANCELLATION_GROUP.EMPLOYEES]: GRAPH_COLOR.ORANGE,
};

export type CancellationReasonCount = z.infer<typeof cancellationReasonCountSchema>;

export type CancellationData = z.infer<typeof cancellationDataSchema>;
