import {
  CountryCode,
  IClinic,
  ICreateGuardianEndpointRequest,
  IntakeStatus,
  USStateCode,
} from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { PhoneNumberInput } from "../components/PhoneNumberInput";
import { VALID_EMAIL_REGEX, VALID_PASSWORD_REGEX } from "../consts";
import { signIn } from "../helpers/sign-in";
import app from "../services/firebase";
import * as FirestoreService from "../services/firestore";

const { Title, Text } = Typography;

interface ISignupFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

export const SecretSignUp: React.FC = () => {
  const { clinicName } = useParams<{ clinicName: string }>();

  const history = useHistory();

  const [form] = Form.useForm<ISignupFormValues>();
  const [clinic, setClinic] = useState<IClinic>({} as IClinic);
  const [logoURL, setLogoURL] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isMobile } = useResponsive();

  useEffect(() => {
    fetchClinic().catch(() => {});
  }, [clinicName]);

  const fetchClinic = async () => {
    const clinic = await FirestoreService.getClinicByName(clinicName);
    setClinic(clinic);

    if (clinic?.logoPath) {
      const getLogoUrl = async () => {
        const clientLogoURL = await getDownloadURL(ref(getStorage(app), clinic.logoPath));
        setLogoURL(clientLogoURL);
      };
      getLogoUrl().catch(() => {});
    }
  };

  return (
    <Row style={{ height: "100%" }}>
      <Col span={24}>
        <Row
          style={{
            width: "100%",
            marginTop: 30,
            paddingBottom: 30,
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
          justify="center"
          align="middle"
        >
          <Col>
            {logoURL ? (
              <div style={{ display: "flex", width: "100%" }}>
                <img src={logoURL} height={40} />
              </div>
            ) : (
              <a
                href="https://www.finnihealth.com"
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <img src="/logo.svg" height={40} />
              </a>
            )}
          </Col>
        </Row>
        <Row
          style={{
            width: "100%",
            marginTop: 30,
            marginBottom: 15,
            textAlign: "center",
            paddingLeft: isMobile ? 10 : 0,
            paddingRight: isMobile ? 10 : 0,
          }}
          justify="center"
        >
          <div style={{ marginBottom: 50 }}>
            <Title style={{ fontFamily: "Reckless Neue" }}>Welcome to {clinic.displayName}</Title>
            <Text style={{ display: "block", marginBottom: 30, fontWeight: 400 }}>
              Provide your information below to sign up and begin onboarding. We keep all your data
              private and secure.
            </Text>
            <Form
              form={form}
              layout="vertical"
              labelCol={{ span: 24 }}
              onFinish={async (values) => {
                setIsLoading(true);

                const createGuardianRequest: ICreateGuardianEndpointRequest = {
                  clinicId: clinic.id,
                  firstName: values.firstName,
                  middleName: "",
                  lastName: values.lastName,
                  phoneNumber: {
                    primary: values.phoneNumber,
                    home: "",
                    work: "",
                    cell: "",
                  },
                  email: values.email,
                  password: values.password,
                  address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: clinic.address.state || USStateCode.NM,
                    country: CountryCode.US,
                    zipCode: clinic.serviceAreas[0] || "",
                  },
                  addressNotes: "",
                  intakeStatus: IntakeStatus.UNDISCOVERED,
                  intakeStatusNotes: "",
                  payers: {},
                };

                const isEmailAvailable = await FirestoreService.isGuardianEmailAvailable(
                  values.email
                );

                if (!isEmailAvailable) {
                  void message.error(
                    "An account already exists with that email address. Would you like to log in instead?"
                  );
                  setIsLoading(false);
                  return;
                }

                await FirestoreService.createGuardian(createGuardianRequest);

                await signIn(
                  values.email,
                  values.password,
                  () => {
                    history.push("/");
                  },
                  () => {
                    void message.error("Oops! Something went wrong");
                    history.push("/");
                  }
                );

                setIsLoading(false);
              }}
            >
              <Form.Item
                label="Parent/Guardian's First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "First name is a required field",
                  },
                ]}
                style={{
                  maxWidth: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 25,
                }}
              >
                <Input
                  style={{
                    borderRadius: 50,
                    padding: 10,
                    paddingLeft: 20,
                  }}
                  name="firstName"
                />
              </Form.Item>

              <Form.Item
                label="Parent/Guardian's Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Last name is a required field",
                  },
                ]}
                style={{
                  maxWidth: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 25,
                }}
              >
                <Input
                  style={{
                    borderRadius: 50,
                    padding: 10,
                    paddingLeft: 20,
                  }}
                  name="lastName"
                />
              </Form.Item>

              <Form.Item
                label="What's the best number to reach you?"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid phone number",
                  },
                ]}
                style={{
                  maxWidth: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 25,
                }}
              >
                <PhoneNumberInput
                  style={{
                    borderRadius: 50,
                    padding: 10,
                    paddingLeft: 20,
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    pattern: VALID_EMAIL_REGEX,
                    message: "Please enter a valid email",
                  },
                ]}
                style={{
                  maxWidth: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 25,
                }}
              >
                <Input
                  style={{
                    borderRadius: 50,
                    padding: 10,
                    paddingLeft: 20,
                  }}
                  name="email"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    pattern: VALID_PASSWORD_REGEX,
                    message:
                      "Your Password must be at least 8 characters long, contain at least 1 number, and at least 1 special character (`~!@#$%^&*-_=+;:'\",<.>?)",
                  },
                  {
                    required: true,
                    message: "Please input your Password",
                  },
                ]}
                style={{
                  maxWidth: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 25,
                }}
              >
                <Input.Password
                  style={{
                    borderRadius: 50,
                    padding: 10,
                    paddingLeft: 20,
                  }}
                  name="password"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your Password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords that you entered do not match")
                      );
                    },
                  }),
                ]}
                style={{
                  maxWidth: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 25,
                }}
              >
                <Input.Password
                  style={{
                    borderRadius: 50,
                    padding: 10,
                    paddingLeft: 20,
                  }}
                />
              </Form.Item>
              <Button
                style={{ width: 120, height: 44, fontWeight: 600 }}
                htmlType="submit"
                type="primary"
                shape="round"
                loading={isLoading}
              >
                Sign Up
              </Button>
              <Typography.Text style={{ display: "block", marginTop: 30, fontWeight: 400 }}>
                Already have an account? <Link to="/login">Log In</Link>
              </Typography.Text>
            </Form>
          </div>
        </Row>
      </Col>
    </Row>
  );
};
