import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

export const sessionContract = c.router({
  createFromAppointment: {
    method: "POST",
    path: "/sessions",
    body: z.object({
      userId: z.string(),
      appointmentId: z.string(),
    }),
    responses: {
      200: z.object({
        sessionId: z.string(),
      }),
      400: z.object({
        message: z.string(),
      }),
      404: z.object({
        message: z.string(),
      }),
      409: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  endSession: {
    method: "PATCH",
    path: "/sessions/:sessionId/end",
    pathParams: z.object({
      sessionId: z.string(),
    }),
    body: c.noBody(),
    responses: {
      200: z.object({
        sessionId: z.string(),
      }),
      400: z.object({
        message: z.string(),
      }),
      404: z.object({
        message: z.string(),
      }),
      409: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
