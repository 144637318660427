import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { authCodeUtilizationSchema } from "../endpoints";
import { AuthMethod, BillingCode, IAuthorization } from "../types";

const c = initContract();
export const authorizationsContract = c.router({
  /**
   * Get authorization utilization data for a specific client
   * Returns all authorization codes with their utilization details
   */
  getAuthUtilizationByClientId: {
    method: "GET",
    path: "/clients/:clientId/auth-utilization",
    pathParams: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: z.object({
        authUtilizations: z.array(authCodeUtilizationSchema),
      }),
      400: z.object({
        message: z.string(),
      }),
      401: z.object({
        message: z.string(),
      }),
      403: z.object({
        message: z.string(),
      }),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  list: {
    method: "GET",
    path: "/clients/:clientId/authorizations",
    pathParams: z.object({
      clientId: z.string(),
    }),
    responses: {
      200: c.type<IAuthorization[]>(),
    },
  },
  update: {
    method: "PUT",
    path: "/clients/:clientId/authorizations/:authId",
    pathParams: z.object({
      clientId: z.string(),
      authId: z.string(),
    }),
    body: z.object({
      startDate: z.string().date().optional(),
      endDate: z.string().date().optional(),
      payerId: z.string().optional(),
      authNumber: z.string().optional(),
      authCodes: z.record(
        z.nativeEnum(BillingCode),
        z.object({
          units: z.number(),
        })
      ),
      phoneReferenceNumber: z.string().optional(),
      authType: z.nativeEnum(AuthMethod).optional(),
    }),
    responses: {
      200: z.object({
        message: z.string(),
      }),
    },
  },
});
