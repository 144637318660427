import { DropboxDocumentType, ISignedDropboxDocument } from "../services/dropboxSignService";
import { SignableDocument } from "../types";

/**
 * A signable document could have multiple "versions",
 * this function returns the latest version of a document
 */
export const getLatestSignableDocument = ({
  signableDocuments,
  documentType,
}: {
  signableDocuments: SignableDocument[];
  documentType: DropboxDocumentType;
}) => {
  return (
    signableDocuments
      // filter out draft and deleted documents
      .filter((doc) => doc.documentType === documentType && !doc.isDraft && !doc.deletedAt)
      // sort by version number
      .sort((a, b) => (b.version || 0) - (a.version || 0))[0]
  );
};

export const isGeneralConsentFormSigned = (signedDocuments?: ISignedDropboxDocument[]): boolean => {
  return (
    signedDocuments?.some(
      // In the future, we might wanna check if it was the latest version of the document that was signed
      // For now, we just make sure the document was signed
      (doc) => doc.documentType === DropboxDocumentType.CAREGIVER_CONSENT_FORM
    ) || false
  );
};
