import { BillingCode, IAppointment, INote, IPayer, Modifier, ViolationDetail } from "../types";
import {
  IMotivityNote,
  NOTE_FIELD_LABELS,
  NOTE_FIELDS,
  NoteStatus,
  NoteTypes,
} from "../types/notes";

const THERAPIST_SESSION_NOTES = [
  NoteTypes.THERAPIST_SESSION,
  NoteTypes.TRICARE_THERAPIST_SESSION,
  NoteTypes.COLORADO_MEDICAID_THERAPIST_SESSION,
];

//note field validation
export const isNoteOverSixMinutes = (note: INote) => {
  return Math.abs(note.endMs - note.startMs) >= 6 * 60 * 1000;
};

export const isNoteNarrativePresent = (note: INote) => {
  return !!note.narrative && note.narrative.length > 0;
};

export const isNoteProviderSigned = (note: INote) => {
  return !!note.providerSignature?.signedAt && note.providerSignature?.signedAt > 0;
};

export const noteRequiresParentSignature = (note: INote) => {
  return THERAPIST_SESSION_NOTES.includes(note.noteType);
};

export const noteRequiresBehaviorData = (note: INote) => {
  return !note.isOrbitNote && THERAPIST_SESSION_NOTES.includes(note.noteType);
};

export const noteRequiresTargetData = (note: INote) => {
  return !note.isOrbitNote && THERAPIST_SESSION_NOTES.includes(note.noteType);
};

export const isNoteClientSigned = (note: INote) => {
  if (
    noteRequiresParentSignature(note) &&
    (!note.guardianSignature?.signedAt || !(note.guardianSignature?.signedAt > 0))
  ) {
    return false;
  }

  return true;
};

export const isNoteBehaviorDataPresent = (note: IMotivityNote) => {
  if (
    !note.isOrbitNote &&
    noteRequiresBehaviorData(note) &&
    (!note.data.behaviorData || !(note.data.behaviorData.length > 0))
  ) {
    return false;
  }
  return true;
};

export const isNoteTargetDataPresent = (note: IMotivityNote) => {
  if (
    !note.isOrbitNote &&
    noteRequiresTargetData(note) &&
    (!note.data.targetData || !(note.data.targetData.length > 0))
  ) {
    return false;
  }

  return true;
};

//compound requirements
export const isNoteLibraryDataPresent = (note: IMotivityNote) => {
  return isNoteBehaviorDataPresent(note) && isNoteTargetDataPresent(note);
};

export const isNoteSigned = (note: IMotivityNote) => {
  if (!isNoteProviderSigned(note)) {
    return false;
  }

  if (noteRequiresParentSignature(note) && !isNoteClientSigned(note)) {
    return false;
  }

  return true;
};

type NoteRequirement = {
  function: (note: IMotivityNote) => boolean;
  unapprovableQuery: {
    field: string;
    operator: any;
    value: any;
  } | null;
  errorMessage: string;
  errorMessageDetails: string;
  isNewListEnabled?: boolean;
};

export const NOTE_REQUIREMENTS: NoteRequirement[] = [
  {
    function: isNoteOverSixMinutes,
    unapprovableQuery: null,
    errorMessage: "Minimum Note Duration (6 minutes)",
    errorMessageDetails: "Note is less than 6 minutes",
    isNewListEnabled: true,
  },
  {
    function: isNoteNarrativePresent,
    unapprovableQuery: {
      field: "narrative",
      operator: "==",
      value: "",
    },
    errorMessage: NOTE_FIELD_LABELS[NOTE_FIELDS.NARRATIVE],
    errorMessageDetails: "Narrative is missing",
  },
  {
    function: isNoteProviderSigned,
    unapprovableQuery: {
      field: "providerSignature.signedAt",
      operator: "==",
      value: NaN,
    },
    errorMessage: NOTE_FIELD_LABELS[NOTE_FIELDS.PROVIDER_SIGNED],
    errorMessageDetails: "Provider signature is missing",
  },
  {
    function: isNoteClientSigned,
    unapprovableQuery: {
      field: "guardianSignature.signedAt",
      operator: "==",
      value: NaN,
    },
    errorMessage: NOTE_FIELD_LABELS[NOTE_FIELDS.CLIENT_SIGNED],
    errorMessageDetails: "Client signature is missing",
  },
  // Current we don't check behavior and target data for orbit notes
  {
    function: isNoteBehaviorDataPresent,
    unapprovableQuery: {
      field: "data.behaviorData",
      operator: "==",
      value: "",
    },
    errorMessage: NOTE_FIELD_LABELS[NOTE_FIELDS.BEHAVIOR_DATA],
    errorMessageDetails: "Behavior data is missing",
    isNewListEnabled: true,
  },
  {
    function: isNoteTargetDataPresent,
    unapprovableQuery: {
      field: "data.targetData",
      operator: "==",
      value: "",
    },
    errorMessage: NOTE_FIELD_LABELS[NOTE_FIELDS.TARGET_DATA],
    errorMessageDetails: "Target data is missing",
    isNewListEnabled: true,
  },
];

//note approval check
export const isNoteApprovable = (note: IMotivityNote) => {
  const results = NOTE_REQUIREMENTS.map((requirement) => {
    return requirement.function(note);
  });

  if (results.includes(false)) {
    return false;
  } else {
    return true;
  }
};

// This function is used to get the missing fields list that is compatible with short and long descriptions
export const getNewNoteMissingFieldsMessage = (note: INote): ViolationDetail[] => {
  const missingFields: ViolationDetail[] = NOTE_REQUIREMENTS.filter(
    (requirement) => requirement.isNewListEnabled
  ).reduce((acc, requirement) => {
    if (requirement.errorMessage === NOTE_FIELD_LABELS[NOTE_FIELDS.TARGET_DATA]) {
      requirement.errorMessageDetails =
        "Target Data is missing. This means session has not ran in Motivity and session data is missing. Please ensure the session has been run in Motivity and reload this note.";
    }
    if (!requirement.function(note as IMotivityNote)) {
      acc.push({ title: requirement.errorMessage, details: requirement.errorMessageDetails });
    }
    return acc;
  }, [] as ViolationDetail[]);

  return missingFields;
};

//user friendly note validation error message
export const getNoteMissingFieldsMessage = (note: INote): string => {
  const missingFields: string[] = NOTE_REQUIREMENTS.reduce((acc, requirement) => {
    if (!requirement.function(note as IMotivityNote)) {
      acc.push(requirement.errorMessage);
    }
    return acc;
  }, [] as string[]);

  if (missingFields.length === 0) {
    return "All required fields are present. The note is complete and approvable!";
  } else {
    const missingFieldsMessage = missingFields.join("\n");
    return missingFieldsMessage;
  }
};

export const getNoteTypeFromAppointment = (
  appointment: IAppointment,
  payer: IPayer
): NoteTypes | null => {
  switch (appointment.billingCode) {
    case BillingCode.CODE_97153:
      if (payer.payerId === "TREST" || payer.payerId === "99726" || payer.payerId === "99727") {
        return NoteTypes.TRICARE_THERAPIST_SESSION;
      } else if (payer.payerId === "COCHA") {
        return NoteTypes.COLORADO_MEDICAID_THERAPIST_SESSION;
      }
      return NoteTypes.THERAPIST_SESSION;
    case BillingCode.CODE_97151:
    case BillingCode.CODE_97152:
    case BillingCode.CODE_H0031:
    case BillingCode.CODE_H0032:
    case BillingCode.CODE_90889:
      return NoteTypes.BCBA_ASSESSMENT;
    case BillingCode.CODE_97156:
    case BillingCode.CODE_H2014:
      return NoteTypes.PARENT_SESSION;
    case BillingCode.CODE_97157:
      return NoteTypes.GROUP_PARENT_SESSION;
    case BillingCode.CODE_97155:
    case BillingCode.CODE_H0046:
      return NoteTypes.BCBA_DIRECT_SUPERVISION;
    case BillingCode.CODE_T1026: {
      if (appointment.modifiers.includes(Modifier.UC)) {
        return NoteTypes.BCBA_CLINICAL_MANAGEMENT;
      } else if (appointment.modifiers.includes(Modifier.UD)) {
        // TODO: Check if appointment has supervising appointmentId.
        // If doesn't, it is RBT_SUPERVISION_FEEDBACK
        return NoteTypes.BCBA_DIRECT_SUPERVISION;
      }
      // For other cases of T1026 (ie in VA), it is another type of assessment and should use a BCBA_ASSESSMENT note type.
      return NoteTypes.BCBA_ASSESSMENT;
    }
    case BillingCode.CODE_97158:
      return NoteTypes.BCBA_SOCIAL_GROUP;
  }
  return null;
};

export const getNoteStatus = (note: INote): NoteStatus => {
  if (note.approved) {
    return NoteStatus.APPROVED;
  }
  if (note.providerSignature === null && note.guardianSignature === null) {
    return NoteStatus.DRAFT;
  }
  const isDirectService =
    note.noteType === NoteTypes.THERAPIST_SESSION ||
    note.noteType === NoteTypes.TRICARE_THERAPIST_SESSION ||
    note.noteType === NoteTypes.COLORADO_MEDICAID_THERAPIST_SESSION;
  if (isDirectService) {
    if (note.providerSignature && note.guardianSignature) {
      return NoteStatus.PENDING_APPROVAL;
    } else {
      return NoteStatus.PENDING_SIGNATURE;
    }
  }

  if (note.providerSignature) {
    return NoteStatus.PENDING_APPROVAL;
  }
  return NoteStatus.DRAFT;
};
