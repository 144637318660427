import { get } from "lodash";
import { z } from "zod";

const finniErrorResponseSchema = z.object({
  message: z.string(),
  context: z.record(z.string(), z.any()),
  cause: z.any(),
  stack: z.any(),
  type: z.literal("FinniError"),
});

export type FinniErrorResponse = z.infer<typeof finniErrorResponseSchema>;

export const finniErrorResponseSchemaMap = {
  400: finniErrorResponseSchema,
  401: finniErrorResponseSchema,
  404: finniErrorResponseSchema,
  409: finniErrorResponseSchema,
  500: finniErrorResponseSchema,
};

export const isFinniErrorResponse = (err: unknown): err is { body: FinniErrorResponse } => {
  return get(err, "body.type") === "FinniError";
};
