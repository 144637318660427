import { IUpdateClientEndpointRequest } from "@finni-health/shared";
import { Button, Card, Row, Space, Typography } from "antd";
import React, { ReactNode, useContext } from "react";

import { selected, unselected } from "../../../consts";
import { AuthContext } from "../../AuthProvider";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempMedicalHistory: any;
  setTempMedicalHistory: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterVisionHearingAbility: React.FC<IProps> = ({
  isMobile,
  tempMedicalHistory,
  setTempMedicalHistory,
  updateClient,
  backButton,
}: IProps) => {
  const { refresh: refreshContext, client } = useContext(AuthContext);
  return (
    <Card
      title={
        <Title
          level={5}
          style={{
            fontWeight: 400,
            whiteSpace: "pre-line",
          }}
        >
          Does your child have a history of, or current difficulties with, vision or hearing?
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempMedicalHistory.visionHearingImpairment === true ? selected : unselected}
          onClick={() => {
            setTempMedicalHistory({
              ...tempMedicalHistory,
              visionHearingImpairment: true,
            });
          }}
        >
          Yes
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={tempMedicalHistory.visionHearingImpairment === false ? selected : unselected}
          onClick={() => {
            setTempMedicalHistory({
              ...tempMedicalHistory,
              visionHearingImpairment: false,
            });
          }}
        >
          No
        </Button>
      </Space>
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={tempMedicalHistory.visionHearingImpairment === undefined}
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClient({
              id: client.id,
              medicalHistory: tempMedicalHistory,
            });
            refreshContext();
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
