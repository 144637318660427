import { FieldValue, Timestamp } from "firebase/firestore";
import { z } from "zod";

export enum CountryCode {
  CA = "CA",
  US = "US",
}

export enum USStateCode {
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  AS = "AS",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  DC = "DC",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
  UNKNOWN = "",
}

export const addressSchema = z.object({
  line1: z.string(),
  line2: z.string().optional(),
  city: z.string(),
  state: z.nativeEnum(USStateCode),
  country: z.nativeEnum(CountryCode),
  zipCode: z.string(),
});

export type IAddress = z.input<typeof addressSchema>;

export const phoneNumberSchema = z.object({
  primary: z.string(),
  home: z.string().optional(),
  work: z.string().optional(),
  cell: z.string().optional(),
});

export type IPhoneNumber = z.input<typeof phoneNumberSchema>;

// Custom schema to handle Firestore timestamps (read) and FieldValue (write)
// Need to manually define the type otherwise Zod assumes `unknown`
export const firestoreTimestampSchema: z.ZodUnion<
  [z.ZodType<Timestamp, z.ZodTypeDef, Timestamp>, z.ZodType<FieldValue, z.ZodTypeDef, FieldValue>]
> = z.instanceof(Timestamp).or(z.custom((val) => val instanceof FieldValue));

// Custom schema to handle Finni Health email addresses
export const finniHealthEmailSchema = z
  .string()
  .email()
  .refine((email) => email.endsWith("@finnihealth.com"));
