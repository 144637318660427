import { z } from "zod";

import { authCodeSchema } from "../types";

export const createAuthorizationEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  clientId: z.string().min(1),
  payerId: z.string().min(1),
  isNoAuthRequired: z.boolean(),
  authNumber: z.string(),
  startDate: z.string().date().nullable(),
  endDate: z.string().date().nullable(),
  authCodes: authCodeSchema,
});

export type ICreateAuthorizationEndpointRequest = z.input<
  typeof createAuthorizationEndpointRequestSchema
>;

export const getAuthorizationEndpointRequestSchema = z.object({
  clientFileId: z.string().min(1),
  id: z.string().min(1),
});

export type IGetAuthorizationEndpointRequest = z.input<
  typeof getAuthorizationEndpointRequestSchema
>;

export const getAllAuthorizationEndpointRequestSchema = z
  .object({
    clientId: z.string().min(1),
    payerId: z.string().min(1),
    timeMs: z.number().optional(),
  })
  .strict();

export type IGetAllAuthorizationsEndpointRequest = z.input<
  typeof getAllAuthorizationEndpointRequestSchema
>;

export const getActiveAuthorizationsEndpointRequestSchema = z.object({
  clientFileId: z.string().min(1),
});

export type IGetActiveAuthorizationsEndpointRequest = z.input<
  typeof getActiveAuthorizationsEndpointRequestSchema
>;

export const updateAuthorizationEndpointRequestSchema = z.object({
  id: z.string().min(1),
  clientId: z.string().min(1),
  clinicId: z.string().min(1),
  payerId: z.string().min(1).optional(),
  isNoAuthRequired: z.boolean().optional(),
  authNumber: z.string().optional(),
  startDate: z.string().date().nullable().optional(),
  endDate: z.string().date().nullable().optional(),
  authCodes: authCodeSchema.optional(),
});

export type IUpdateAuthorizationEndpointRequest = z.input<
  typeof updateAuthorizationEndpointRequestSchema
>;

export const deleteAuthorizationEndpointRequestSchema = z.object({
  clientId: z.string().min(1),
  clinicId: z.string().min(1),
  id: z.string().min(1),
});

export type IDeleteAuthorizationEndpointRequest = z.input<
  typeof deleteAuthorizationEndpointRequestSchema
>;

export const getAuthUtilizationByClientIdRequestSchema = z.object({
  clientId: z.string(),
});

export type GetAuthUtilizationByClientIdRequest = z.infer<
  typeof getAuthUtilizationByClientIdRequestSchema
>;

export interface AuthUtilization {
  total: number;
  completedUnits: number;
  remainingHours: number;
  completedPercentage: number;
  startDate: Date | null;
  endDate: Date | null;
  authNumber: string;
  isExpired: boolean;
  units: number;
  unitSize: number;
  isNoAuthRequired: boolean;
  payerId: string;
}

export interface AuthCodeUtilization {
  authCode: string;
  utilizations: AuthUtilization[];
}

export const authUtilizationSchema = z.object({
  total: z.number(),
  completedUnits: z.number(),
  remainingHours: z.number(),
  completedPercentage: z.number(),
  startDate: z.date().nullable(),
  endDate: z.date().nullable(),
  authNumber: z.string(),
  isExpired: z.boolean(),
  units: z.number(),
  unitSize: z.number(),
  isNoAuthRequired: z.boolean(),
  payerId: z.string(),
});

export const authCodeUtilizationSchema = z.object({
  authCode: z.string(),
  utilizations: z.array(authUtilizationSchema),
});

export const getAuthUtilizationByClientIdResponseSchema = z.object({
  authUtilizations: z.array(authCodeUtilizationSchema),
});
