import { z } from "zod";

import { IUser } from "./general";

/**
 * Onboarding step statuses
 */

export enum QuestionnaireStatus {
  TODO = "ToDo",
  SENT = "Sent",
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
}

export enum NpiStatus {
  TODO = "ToDo",
  NEEDS_NPI = "Needs NPI",
  COMPLETED = "Completed",
}

export enum BackgroundStatus {
  TODO = "ToDo",
  IN_PROGRESS = "In Progress",
  CLEARED = "Cleared",
}

export enum ConsentStatus {
  TODO = "ToDo",
  IN_PROGRESS = "In Progress",
  SIGNED = "Signed",
}

export enum I9Status {
  TODO = "ToDo",
  IN_PROGRESS = "In Progress",
  NEEDS_VERIFICATION = "Needs Verification",
  VERIFIED = "Verified",
}

export enum CredentialingStatus {
  TODO = "ToDo",
  IN_PROGRESS = "In Progress",
  NEEDS_VERIFICATION = "Needs Verification",
  VERIFIED = "Verified",
}

// TODO: Add other meta data fields as needed.
// For now we are including the status of each step
export const OnboardingStepsSchema = z.object({
  background: z.object({
    status: z.nativeEnum(BackgroundStatus),
  }),
  questionnaire: z.object({
    status: z.nativeEnum(QuestionnaireStatus),
  }),
  npi: z.object({
    status: z.nativeEnum(NpiStatus),
  }),
  consents: z.object({
    status: z.nativeEnum(ConsentStatus),
  }),
  i9: z.object({
    status: z.nativeEnum(I9Status),
  }),
  credentialing: z.object({
    status: z.nativeEnum(CredentialingStatus),
  }),
});

export type OnboardingSteps = z.infer<typeof OnboardingStepsSchema>;

export const onboardingStepKeys = z.enum(
  Object.keys(OnboardingStepsSchema.shape) as [keyof OnboardingSteps]
);

export const onboardingStepStatuses = z.union([
  z.nativeEnum(QuestionnaireStatus),
  z.nativeEnum(BackgroundStatus),
  z.nativeEnum(NpiStatus),
  z.nativeEnum(ConsentStatus),
  z.nativeEnum(I9Status),
  z.nativeEnum(CredentialingStatus),
]);

export type OnboardingStepDefinition = {
  key: keyof OnboardingSteps;
  label: string;
  requiredStatus:
    | QuestionnaireStatus
    | BackgroundStatus
    | NpiStatus
    | ConsentStatus
    | I9Status
    | CredentialingStatus;
};

export interface OnboardingStepSelectProps {
  row: IUser;
  stepKey: keyof OnboardingSteps;
  options: { value: OnboardingStepDefinition["requiredStatus"]; label: string; color: string }[];
  isDataLoading: boolean;
}
