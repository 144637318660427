import { InboxOutlined } from "@ant-design/icons";
import {
  AnalyticsEvent,
  AppTitle,
  ClientDocumentType,
  IClient,
  IClientDocument,
  IClientFile,
  WorkflowParentPortal,
} from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Card, message, Row, Typography } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { deleteObject, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import { ClientFileProviderStates, ClientOnboardingFlow } from "../../consts";
import { usePageViewTracking } from "../../hooks/usePageViewTracking";
import app from "../../services/firebase";
import * as FirestoreService from "../../services/firestore";
import { AuthContext } from "../AuthProvider";
import { useMixpanel } from "../MixpanelProvider";

const { Title, Text } = Typography;

const firebaseStorage = getStorage(app);

interface IProps {
  clientFile: IClientFile;
  setClientFile: (clientFile: IClientFile) => void;
}

export const Providers: React.FC<IProps> = ({ clientFile, setClientFile }: IProps) => {
  const { client } = useContext(AuthContext);

  const [clientFileProviderStatus, setClientFileProviderStatus] =
    useState<ClientFileProviderStates>(ClientFileProviderStates.SUBMIT_DOCUMENTS);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IClientDocument[]>(clientFile.documents);
  const [fileList, setFileList] = useState<any[]>([]);
  const [numDocumentsUploaded, setNumDocumentsUploaded] = useState<number>(0);
  const location = useLocation();
  const { isMobile } = useResponsive();
  const { trackEvent } = useMixpanel();

  usePageViewTracking(ClientOnboardingFlow.PROVIDERS);

  useEffect(() => {
    trackEvent({
      eventName: AnalyticsEvent.ADDING_CARE_DOCUMENTS,
      additionalProperties: {
        app: AppTitle.PARENT_PORTAL,
        workflow: WorkflowParentPortal.INTAKE_FUNNEL,
      },
    });
  }, []);

  useEffect(() => {
    setClientFileProviderStatus(ClientFileProviderStates.SUBMIT_DOCUMENTS);
  }, []);

  const updateClient = (req: Partial<IClient>) => {
    setClientFile({ ...clientFile, ...req });
    return FirestoreService.updateClient({ ...req, id: client.id });
  };

  const SubmitDocuments = () => {
    const draggerProps = {
      name: "file",
      multiple: false,
      showUploadList: true,
      onRemove: (file: any) => {
        setNumDocumentsUploaded(numDocumentsUploaded - 1);
        const tempFileList = fileList;
        const indexToRemove = tempFileList.findIndex(function (tempFile) {
          return tempFile.name === file.name;
        });
        tempFileList.splice(indexToRemove, 1);
        setFileList(tempFileList);

        const tempDocuments = documents;
        tempDocuments.splice(indexToRemove, 1);
        setDocuments(tempDocuments);

        return deleteObject(
          ref(firebaseStorage, `clientFiles/${clientFile.id}/documents/${file.name}`)
        );
      },
      beforeUpload: async (file: File) => {
        //file size larger than 10MB (10000000 Bytes)
        if (file.size > 10000000) {
          void message.error(
            `Your file: ${file.name} is too large and will not be uploaded. Only files under 10MB can be uploaded`
          );
          return false;
        }

        setIsUploading(true);

        if (numDocumentsUploaded >= 10) {
          void message.warning(
            "You can only upload a maximum of 10 documents. If you want to upload a different document, you must first remove one"
          );
          setFileList([...fileList]);
          setIsUploading(false);
          return false;
        }

        await uploadBytes(
          ref(firebaseStorage, `clientFiles/${clientFile.id}/documents/${file.name}`),
          file
        ).then((results) => {
          setFileList([...fileList, file]);
          setNumDocumentsUploaded(numDocumentsUploaded + 1);
          const path = results.ref.fullPath;
          const newDocument = {
            type: ClientDocumentType.DIAGNOSIS_REPORT,
            path,
          };
          setDocuments([...documents, newDocument]);
          void message.success(`Successfully Uploaded ${file.name}`);
          setIsUploading(false);
        });

        ReactGA.event("care_document_upload", {
          page_path: location.pathname + location.search + location.hash,
        });

        // Prevent remote upload
        return false;
      },
      fileList,
    };

    return (
      <Card
        title={
          <>
            <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
              Do you have the Diagnosis Report, ABA Therapy Recommendation, or any other documents
              to share?
            </Title>
            <Text type="secondary" style={{ fontSize: 14, whiteSpace: "pre-line" }}>
              If you have the diagnosis report or ABA recommendation, it will help speed up your
              onboarding. Entire PDFs are preferred.
            </Text>
          </>
        }
        style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
      >
        <>
          <Dragger
            {...draggerProps}
            style={{
              marginTop: 25,
            }}
          >
            <Text className="ant-upload-drag-icon">
              <InboxOutlined />
            </Text>
            <Text className="ant-upload-text">Click or Drag File to Upload</Text>
          </Dragger>
        </>
        <Row justify="space-between" style={{ marginTop: 25 }}>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              trackEvent({
                eventName: AnalyticsEvent.WORKFLOW_PREVIOUS_STEP,
                additionalProperties: {
                  app: AppTitle.PARENT_PORTAL,
                  workflow: WorkflowParentPortal.INTAKE_FUNNEL,
                  step: AnalyticsEvent.ADDING_CARE_DOCUMENTS,
                },
              });
              setClientFileProviderStatus(clientFileProviderStatus - 1);
            }}
          >
            Back
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            shape="round"
            loading={isUploading}
            disabled={documents.length === 0}
            onClick={() => {
              trackEvent({
                eventName: AnalyticsEvent.WORKFLOW_NEXT_STEP,
                additionalProperties: {
                  app: AppTitle.PARENT_PORTAL,
                  workflow: WorkflowParentPortal.INTAKE_FUNNEL,
                  step: AnalyticsEvent.ADDING_CARE_DOCUMENTS,
                },
              });
              return updateClient({
                documents,
              });
            }}
          >
            Next
          </Button>
        </Row>
      </Card>
    );
  };

  return (
    <>
      {clientFileProviderStatus === ClientFileProviderStates.SUBMIT_DOCUMENTS && (
        <SubmitDocuments />
      )}
    </>
  );
};
