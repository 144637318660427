import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { promptMeasurementHistorySchema, promptOptionSchema } from "../types";

const c = initContract();

export const measurementsContract = c.router({
  createPromptOption: {
    method: "POST",
    path: "/measurements/prompts/create",
    body: z.object({
      clinicId: z.string(),
      itemId: z.string(),
      targetIds: z.array(z.string()),
      type: z.enum(["program", "template"]),
      promptOption: promptOptionSchema.omit({
        id: true,
        createdAt: true,
        updatedAt: true,
        deletedAt: true,
      }),
    }),
    responses: {
      200: z.object({
        promptOptionId: z.string(),
      }),
      400: z.object({
        error: z.string(),
      }),
      500: z.object({
        error: z.string(),
      }),
    },
  },
  deletePromptOption: {
    method: "PATCH",
    path: "/measurements/prompts/delete",
    body: z.object({
      clinicId: z.string(),
      type: z.enum(["program", "template"]),
      itemId: z.string(),
      targetIds: z.array(z.string()),
      promptOptionId: z.string(),
    }),
    responses: {
      200: z.object({ id: z.string() }),
      500: z.object({
        error: z.string(),
      }),
    },
  },
  updatePromptOption: {
    method: "PATCH",
    path: "/measurements/prompts/update",
    body: z.object({
      clinicId: z.string(),
      type: z.enum(["program", "template"]),
      itemId: z.string(),
      targetIds: z.array(z.string()),
      promptOptionId: z.string(),
      promptOption: promptOptionSchema.omit({
        id: true,
        createdAt: true,
        updatedAt: true,
        deletedAt: true,
        createdBy: true,
        measurementDefinitionId: true,
      }),
    }),
    responses: {
      200: z.object({ id: z.string() }),
      500: z.object({
        error: z.string(),
      }),
    },
  },
  getPromptMeasurementHistory: {
    method: "GET",
    path: "/measurements/prompts/history",
    query: z.object({
      clinicId: z.string(),
      promptOptionId: z.string(),
      programId: z.string(),
      targetId: z.string(),
    }),
    responses: {
      200: z.object({ promptMeasurementHistory: z.array(promptMeasurementHistorySchema) }),
      400: z.object({
        error: z.string(),
      }),
      404: z.object({
        error: z.string(),
      }),
      500: z.object({
        error: z.string(),
      }),
    },
  },
  copyPromptOptionsToNewTarget: {
    method: "POST",
    path: "/measurements/prompts/copy",
    body: z.object({
      clinicId: z.string(),
      itemId: z.string(),
      sourceTargetId: z.string(),
      destinationTargetId: z.string(),
      type: z.enum(["program", "template"]),
      userId: z.string(),
      measurementDefinitionIds: z.array(z.string()),
    }),
    responses: {
      200: z.object({ id: z.string() }),
      400: z.object({
        error: z.string(),
      }),
      500: z.object({
        error: z.string(),
      }),
    },
  },
});
