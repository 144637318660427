import { z } from "zod";

// TODO: Rename this to SignableDocumentType
export enum DropboxDocumentType {
  CAREGIVER_CONSENT_FORM = "Caregiver Consent Form",
  MEDIA_CONSENT_FORM = "Media Consent Form",
  THIRD_PARTY_CONSENT_FORM = "Third Party Consent Form",
  SCHOOL_OR_AGENT_THIRD_PARTY_CONSENT_FORM = "Third Party Consent Form (School or Agent)",
  CAREGIVER_HANDBOOK = "Client / Caregiver Handbook",
  CLIENT_BILLING_ACKNOWLEDGEMENT_FORM = "Client Billing Acknowledgement Form",
}

// TODO: Rename this to signedDocumentSchema
export const signedDropboxDocumentSchema = z.object({
  signableDocumentId: z.string(),
  documentType: z.nativeEnum(DropboxDocumentType),
  signedAt: z.number(),
  signatureRequestId: z.string(),
  signerEmail: z.string(),
  signerName: z.string(),
  signerRole: z.string(),
});

// TODO: Rename this to SignedDocument
export type ISignedDropboxDocument = z.infer<typeof signedDropboxDocumentSchema>;

// TODO: Rename this to SignableDocumentSignerRole
export enum DropboxDocumentSignerRole {
  "Parent/Guardian" = "Parent / Guardian",
}
