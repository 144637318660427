import { Timestamp } from "firebase/firestore";
import { z } from "zod";

export const payrollEntrySchema = z.object({
  id: z.string(),
  employeeEmail: z.string(),
  clinicName: z.string(),
  directRateHours: z.number(),
  indirectRateHours: z.number(),
  driveTimeHours: z.number(),
  milesDriven: z.number(),
  directRate: z.number(),
  indirectRate: z.number(),
  ptoOrSeverance: z.number(),
  mileageCost: z.number(),
  cellPhoneCost: z.number(),
  baseSalary: z.number(),
  additionalPay: z.number(),
  offCyclePayout: z.number(),
  overtimeDirectPay: z.number(),
  overtimeIndirectPay: z.number(),
  benefitsCost: z.number(),
  payrollTaxes: z.number(),
  order: z.number(),
  createdAt: z.instanceof(Timestamp),
  deletedAt: z.instanceof(Timestamp).optional().nullable(),
});

export type IPayrollEntry = z.input<typeof payrollEntrySchema>;

export const clinicAdjustmentSchema = z.object({
  id: z.string(),
  clinicName: z.string(),
  clinicOwnerPayout: z.number(),
  brexExpenses: z.number(),
  brexExpensesReimbursement: z.number(),
  benefitsCoveredPercentage: z.number(),
  factoringRatePercentage: z.number(),
  finniPlatformRatePercentage: z.number(),
  minimumPayout: z.number(),
  rentLeaseStipend: z.number(),
  miscellaneous: z.number(),
  payrollTaxReimbursementPercentage: z.number(),
  createdAt: z.instanceof(Timestamp),
  deletedAt: z.instanceof(Timestamp).optional().nullable(),
  order: z.number(),
});

export type IClinicAdjustment = z.input<typeof clinicAdjustmentSchema>;

export const staffAdjustmentSchema = payrollEntrySchema;

export type IStaffAdjustment = z.input<typeof staffAdjustmentSchema>;

export const subcontractingAdjustmentSchema = payrollEntrySchema
  .omit({
    clinicName: true,
  })
  .extend({
    subcontractingClinicName: z.string(),
    subcontractedClinicName: z.string(),
  });

export type ISubcontractingAdjustment = z.input<typeof subcontractingAdjustmentSchema>;

export const clientAdjustmentSchema = z.object({
  id: z.string(),
  clinicName: z.string(),
  clientAlias: z.string(),
  additionalNetClaimsGeneratedRatePercentage: z.number(),
  adjustedFinniPlatformRatePercentage: z.number(),
  order: z.number(),
  deletedAt: z.instanceof(Timestamp).optional().nullable(),
  createdAt: z.instanceof(Timestamp),
  updatedAt: z.instanceof(Timestamp),
});

export type IClientAdjustment = z.input<typeof clientAdjustmentSchema>;

export const payPeriodInfoSchema = z.object({
  id: z.string(),
  financialYear: z.number(),
  payPeriodNumber: z.number(),
  startDate: z.instanceof(Timestamp),
  endDate: z.instanceof(Timestamp),
  completionWindowStartDateTime: z.instanceof(Timestamp),
  completionWindowEndDateTime: z.instanceof(Timestamp),
  isActive: z.boolean().optional(),
  isRealtime: z.boolean().optional(), // This is a computed field that is generated at runtime
  deletedAt: z.instanceof(Timestamp).optional().nullable(),
  createdAt: z.instanceof(Timestamp),
  updatedAt: z.instanceof(Timestamp),
  finalizedAt: z.instanceof(Timestamp).optional().nullable(),
});

export type IPayPeriodInfo = z.input<typeof payPeriodInfoSchema>;

export type IPayPeriodInputData =
  | IPayrollEntry
  | IClinicAdjustment
  | IStaffAdjustment
  | ISubcontractingAdjustment
  | IClientAdjustment;
