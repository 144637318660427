import { z } from "zod";

import { targetGroupDocumentSchema, targetIntervalSchema, targetSchema } from "../types";

export const createTargetEndpointRequestSchema = targetSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .strict();

export type ICreateTargetEndpointRequest = z.input<typeof createTargetEndpointRequestSchema>;

export const updateTargetIntervalEndpointRequestSchema = targetIntervalSchema.deepPartial();

export type IUpdateTargetIntervalEndpointRequest = z.input<
  typeof updateTargetIntervalEndpointRequestSchema
>;

export const updateTargetEndpointRequestSchema = targetSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    id: z.string(),
    userId: z.string(),
  })
  .strict();

export type IUpdateTargetEndpointRequest = z.input<typeof updateTargetEndpointRequestSchema>;

export const createTargetGroupTargetsEndpointRequestSchema = targetSchema
  .omit({
    name: true,
    id: true,
    groupId: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .extend({
    names: z.array(z.string()),
  })
  .strict();

/**
 * @deprecated
 * we are deprecating updateTargetGroupEndpointRequestSchema and will be using updateTargetGroupTargetEndpointRequestSchema instead
 */
export const updateTargetGroupEndpointRequestSchema = targetSchema
  .omit({
    id: true,
    groupId: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    groupId: z.string(),
    userId: z.string(),
  })
  .strict();

/**
 * @deprecated
 * we are deprecating IUpdateTargetGroupEndpointRequest and will be using IUpdateTargetGroupTargetEndpointRequest instead
 */
export type IUpdateTargetGroupEndpointRequest = z.input<
  typeof updateTargetGroupEndpointRequestSchema
>;

export const createTargetGroupDocumentEndpointRequestSchema = targetGroupDocumentSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .strict();

export type ICreateTargetGroupTargetsEndpointRequest = z.input<
  typeof createTargetGroupTargetsEndpointRequestSchema
>;

export type ICreateTargetGroupDocumentEndpointRequest = z.input<
  typeof createTargetGroupDocumentEndpointRequestSchema
>;

export const updateTargetGroupTargetEndpointRequestSchema = targetSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    userId: z.string(),
  })
  .strict();

export const updateTargetGroupDocumentEndpointRequestSchema = targetGroupDocumentSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .partial()
  .strict();

export type IUpdateTargetGroupTargetEndpointRequest = z.input<
  typeof updateTargetGroupTargetEndpointRequestSchema
>;

export type IUpdateTargetGroupDocumentEndpointRequest = z.input<
  typeof updateTargetGroupDocumentEndpointRequestSchema
>;
