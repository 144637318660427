import {
  AppointmentType,
  BillingCode,
  IAppointment,
  IAppointmentConfig,
  ICompletedAppointment,
  IPayer,
  ProviderType,
} from "../../types";
import { getBaseBillingModifiers } from "./helpers";

export const NEW_YORK_APPOINTMENTS: IAppointmentConfig[] = [
  {
    name: AppointmentType.CLINICAL_MANAGEMENT,
    billingCode: BillingCode.CODE_H0032,
    modifiers: [],
  },
];

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  providerType: ProviderType
) => {
  return getBaseBillingModifiers(appointment, payer, providerType);
};

export const newYorkBillingModifier = getStateBillingModifiers;
