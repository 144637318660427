import { IContact, ITherapyPreferences, IUpdateClientEndpointRequest } from "@finni-health/shared";
import { Button, Card, Form, Input, Row, Space, Typography } from "antd";
import React, { ReactNode, useContext } from "react";

import {
  ClientFileIntakeQuestionnaireStates,
  selected,
  unselected,
  VALID_EMAIL_REGEX,
} from "../../../consts";
import { AuthContext } from "../../AuthProvider";
import { PhoneNumberInput } from "../../PhoneNumberInput";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempTherapyPreferences: ITherapyPreferences;
  setTempTherapyPreferences: (value: ITherapyPreferences) => void;
  setClientFileIntakeQuestionnaireStatus: (value: ClientFileIntakeQuestionnaireStates) => void;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterOtherCaregiver: React.FC<IProps> = ({
  isMobile,
  tempTherapyPreferences,
  setTempTherapyPreferences,
  setClientFileIntakeQuestionnaireStatus,
  updateClient,
  backButton,
}: IProps) => {
  const { client } = useContext(AuthContext);

  const updateSecondaryContact = (req: Partial<IContact>) => {
    setTempTherapyPreferences({
      ...tempTherapyPreferences,
      secondaryContact: {
        ...tempTherapyPreferences.secondaryContact!,
        ...req,
      },
    });
  };

  return (
    <Card
      title={
        <Title
          level={5}
          style={{
            fontWeight: 400,
            whiteSpace: "pre-line",
          }}
        >
          {`Is there another parent or caregiver who should be listed on your child's account?`}
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempTherapyPreferences.secondaryContact !== undefined ? selected : unselected}
          onClick={() => {
            updateSecondaryContact({
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
            });
          }}
        >
          Yes
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={tempTherapyPreferences.secondaryContact === undefined ? selected : unselected}
          onClick={() => {
            const newTempTherapyPreferences = tempTherapyPreferences;
            delete newTempTherapyPreferences.secondaryContact;
            setTempTherapyPreferences({ ...newTempTherapyPreferences });
          }}
        >
          No
        </Button>
      </Space>

      <Form
        layout="vertical"
        labelCol={{ span: 24 }}
        onFinish={async () => {
          await updateClient({
            id: client.id,
            therapyPreferences: tempTherapyPreferences,
          });
          setClientFileIntakeQuestionnaireStatus(
            ClientFileIntakeQuestionnaireStates.ENTER_AVAILABILITY
          );
        }}
      >
        {tempTherapyPreferences.secondaryContact !== undefined && (
          <>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please enter your secondary contact's first name",
                },
              ]}
              initialValue={tempTherapyPreferences.secondaryContact?.firstName}
              style={{
                maxWidth: 450,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 25,
              }}
            >
              <Input
                onChange={(e) => {
                  updateSecondaryContact({
                    firstName: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please enter your secondary contact's last name",
                },
              ]}
              initialValue={tempTherapyPreferences.secondaryContact?.lastName}
              style={{
                maxWidth: 450,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 25,
              }}
            >
              <Input
                onChange={(e) => {
                  updateSecondaryContact({
                    lastName: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  pattern: VALID_EMAIL_REGEX,
                  message: "Please enter your secondary contact's email address",
                },
              ]}
              initialValue={tempTherapyPreferences.secondaryContact?.email}
              style={{
                maxWidth: 450,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 25,
              }}
            >
              <Input
                onChange={(e) => {
                  updateSecondaryContact({
                    email: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid phone number",
                },
              ]}
              initialValue={tempTherapyPreferences.secondaryContact?.phoneNumber}
              style={{
                maxWidth: 450,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 25,
              }}
            >
              <PhoneNumberInput
                onChange={(e: any) => {
                  updateSecondaryContact({
                    phoneNumber: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </>
        )}
        <Row justify="space-between">
          {backButton()}
          <Button htmlType="submit" type="primary" shape="round">
            Next
          </Button>
        </Row>
      </Form>
    </Card>
  );
};
