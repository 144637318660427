import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { yyyymmddSchema } from "../helpers/schema-helpers";

const c = initContract();

export const programsContract = c.router({
  getAggregateProgramData: {
    method: "GET",
    path: "/clinics/:clinicId/programs/:programId/aggregate-data",
    query: z.object({
      interval: z.enum(["week", "month"]),
      startDate: yyyymmddSchema,
      endDate: yyyymmddSchema,
    }),
    pathParams: z.object({
      clinicId: z.string(),
      programId: z.string(),
    }),
    responses: {
      200: z.array(
        z.object({
          date: z.string(),
          masteredThisPeriod: z.object({
            targets: z.array(z.string()),
            count: z.number(),
          }),
          masteredBefore: z.object({
            targets: z.array(z.string()),
            count: z.number(),
          }),
          totalMastered: z.number(),
          masteredSinceStartOfWindow: z.number(),
        })
      ),
      400: z.object({
        error: z.string(),
      }),
      500: z.object({
        error: z.string(),
      }),
    },
  },
});
