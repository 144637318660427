import { Timestamp } from "firebase/firestore";
import { z } from "zod";

export const payrollDirectHoursSchema = z.object({
  // Identifiers
  appointmentId: z.string(),

  // Clinic & Location
  clinicName: z.string(),
  location: z.string(),

  // Client Info
  clientAlias: z.string(),
  clientState: z.string(),

  // Provider Info
  renderingProviderFirstName: z.string(),
  renderingProviderLastName: z.string(),
  renderingProviderEmail: z.string(),
  renderingProviderType: z.string(),
  renderingProviderRipplingId: z.string().optional(),
  renderingProviderDirectRateCents: z.number(),
  renderingProviderDirectPayCents: z.number(),

  // Appointment Details
  startMs: z.number(),
  approvedAt: z.instanceof(Timestamp),
  durationHours: z.number(),
  summary: z.string(),
  payPeriodNumber: z.number().optional(),

  // Billing Info
  billingCode: z.string(),
  units: z.number(),
  chargeDollars: z.number(),
  isBilled: z.boolean(),

  // Payer Info
  primaryPayerId: z.string().optional(),
  primaryPayerName: z.string().optional(),
  primaryPayerInsuranceType: z.string().optional(),
  secondaryPayerName: z.string().optional(),
  secondaryPayerInsuranceType: z.string().optional(),
  payerName: z.string().optional(),
});

export type IPayrollDirectHours = z.input<typeof payrollDirectHoursSchema>;

export const payrollIndirectHoursSchema = z.object({
  // Identifiers
  indirectId: z.string(),

  // Clinic & Location
  clinicName: z.string(),

  // Provider Info
  renderingProviderFirstName: z.string(),
  renderingProviderLastName: z.string(),
  renderingProviderEmail: z.string(),
  renderingProviderType: z.string(),
  renderingProviderRipplingId: z.string().optional(),
  renderingProviderIndirectRateCents: z.number(),
  renderingProviderIndirectPayCents: z.number(),

  // Appointment Details
  startMs: z.number(),
  durationHours: z.number(),
  indirectReason: z.string(),
  payPeriodNumber: z.number().optional(),
});

export type IPayrollIndirectHours = z.input<typeof payrollIndirectHoursSchema>;

export const payrollDriveTimeSchema = z.object({
  // Identifiers
  indirectId: z.string(),

  // Clinic & Location
  clinicName: z.string(),

  // Provider Info
  renderingProviderFirstName: z.string(),
  renderingProviderLastName: z.string(),
  renderingProviderEmail: z.string(),
  renderingProviderType: z.string(),
  renderingProviderRipplingId: z.string().optional(),
  renderingProviderMileageRateCents: z.number(),
  renderingProviderMileagePayCents: z.number(),

  // Appointment Details
  startMs: z.number(),
  durationHours: z.number(),
  indirectReason: z.string(),
  payPeriodNumber: z.number().optional(),
  mileage: z.number(),
});

export type IPayrollDriveTime = z.input<typeof payrollDriveTimeSchema>;

export const payrollExportSchema = z.object({
  directHours: z.array(payrollDirectHoursSchema),
  indirectHours: z.array(payrollIndirectHoursSchema),
  driveTime: z.array(payrollDriveTimeSchema),
  directHolidayHours: z.array(payrollIndirectHoursSchema),
  indirectHolidayHours: z.array(payrollIndirectHoursSchema),
});

export type IPayrollExport = z.input<typeof payrollExportSchema>;
