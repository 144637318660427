export const IncidentFormDescription =
  "This form is intended for reporting any incidents that may impact the safety, well-being, or progress of clients, staff, or others within the practice environment. An incident is any unplanned event that results in harm or risk of harm to a client, employee, or property. Please complete this form immediately after an incident occurs and then also alert your immediate supervisor that it was completed. Information provided here will assist in ensuring timely follow-up and support.";

export const MedicalTreatmentDeclaration =
  "Please indicate if you plan to seek medical treatment for the injury sustained or if you are declining medical treatment at this time.";

export const AcknowledgmentOfDecliningMedicalTreatment =
  "By checking the box below, I acknowledge that I am declining medical treatment at this time. I understand that declining medical treatment means I am choosing not to seek medical evaluation or care for my injury immediately. This does not prevent me from seeking medical treatment at a later date if I experience any symptoms or complications related to the injury. I am responsible for informing my employer if my condition changes or if I decide to seek medical care in the future.";

export const IncidentPreventionSuggestions =
  "How can similar incidents be avoided in the future? Please provide any suggestions or steps that can be taken to prevent this type of incident and ensure a safe environment for everyone involved.";

export const SignatureAcknowledgment =
  "I acknowledge that the information provided is accurate to the best of my knowledge and my above name serves as my electronic signature.";

export const MedicalTreatmentDeclarationQuestion =
  "If this incident resulted in an injury, do you plan to seek medical treatment for your injury?";

export const IncidentDescription =
  "Please describe what occurred, observed behaviors, location and description of injuries, and context.";

export const ActionsTaken =
  "Please describe any immediate actions or measures taken in response to the incident.";

export const IncidentTypeTitle = {
  EMPLOYEE: "Employee Incident/Injury",
  CLIENT: "Client Incident/Injury",
};

export const TypeOfIncident = [
  { value: IncidentTypeTitle.EMPLOYEE, label: IncidentTypeTitle.EMPLOYEE },
  { value: IncidentTypeTitle.CLIENT, label: IncidentTypeTitle.CLIENT },
];

export const EmployeeIncidentTypes = [
  {
    label: "Physical Injury",
    text: "Any physical harm sustained by employee during a session or at the workplace.",
  },
  {
    label: "Environmental Hazard Exposure",
    text: "Situations where employee encounters hazards in the environment, such as exposure to chemicals, broken furniture, or unsafe equipment.",
  },
  {
    label: "Workplace Violence or Harassment",
    text: "Any instance of violence, bullying, or harassment between employees or from external individuals, including verbal or physical altercations.",
  },
  {
    label: "Property Damage",
    text: "Any damage to personal or workplace property caused during a session or as a result of an employee interaction with clients or equipment.",
  },
  { label: "Other", text: "" },
];

export const ClientIncidentTypes = [
  {
    label: "Medical Emergencies & Physical Injury",
    text: "Encompasses any physical harm to the client and/or health emergencies.",
  },
  {
    label: "Property Damage",
    text: "Involves any damage to property.",
  },
  {
    label: "Client Risk & Welfare Concerns",
    text: "Covers concerns for the client's well-being outside therapy, including suspected abuse, neglect, or behaviors that pose serious risks.",
  },
  {
    label: "Injury to Another",
    text: "Client injured another person in the environment.",
  },
  { label: "Other", text: "" },
];

export const MedicalTreatmentDeclarationOptions = [
  {
    value: "Yes, I plan to seek medical treatment.",
    label: "Yes, I plan to seek medical treatment.",
  },
  {
    value: "No, I am declining medical treatment at this time.",
    label: "No, I am declining medical treatment at this time.",
  },
  {
    value: "No injury occurred.",
    label: "No injury occurred.",
  },
];

export type SortOptionType =
  | "incidentDate_asc"
  | "incidentDate_desc"
  | "createdAt_asc"
  | "createdAt_desc";

export const SortOptions = [
  { label: "Incident Date  |  asc", value: "incidentDate_asc" },
  { label: "Incident Date  |  desc", value: "incidentDate_desc" },
  { label: "Report Creation  |  asc", value: "createdAt_asc" },
  { label: "Report Creation  |  desc", value: "createdAt_desc" },
];

export enum Participants {
  USER = "user",
  CLIENT = "client",
}
