import { IUpdateClientEndpointRequest, MedicalServices } from "@finni-health/shared";
import { Button, Card, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import React, { ReactNode, useContext } from "react";

import { selectedToggle, unselectedToggle } from "../../../consts";
import { AuthContext } from "../../AuthProvider";
const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempMedicalHistory: any;
  setTempMedicalHistory: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterPreviousServices: React.FC<IProps> = ({
  isMobile,
  tempMedicalHistory,
  setTempMedicalHistory,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClient,
  backButton,
}: IProps) => {
  const { client } = useContext(AuthContext);

  const togglePastService = (pastService: MedicalServices) => {
    const newTempMedicalHistory = tempMedicalHistory;
    let tempPastServices = tempMedicalHistory.pastServices || [];

    if (tempMedicalHistory.pastServices?.includes(pastService)) {
      tempPastServices = tempPastServices.filter((tempPastService: MedicalServices) => {
        return tempPastService !== pastService;
      });
    } else {
      tempPastServices.push(pastService);
    }

    if (_.isEmpty(tempPastServices)) {
      delete newTempMedicalHistory.pastServices;
    } else {
      newTempMedicalHistory.pastServices = tempPastServices;
    }

    setTempMedicalHistory({ ...newTempMedicalHistory });
  };

  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          Please select any services that your child has previously received.
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.pastServices?.includes(MedicalServices.ABA_THERAPY)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            togglePastService(MedicalServices.ABA_THERAPY);
          }}
        >
          ABA Therapy
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.pastServices?.includes(MedicalServices.PSYCHOLOGICAL_SERVICES)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            togglePastService(MedicalServices.PSYCHOLOGICAL_SERVICES);
          }}
        >
          Psychological Services
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.pastServices?.includes(MedicalServices.SPEECH_THERAPY)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            togglePastService(MedicalServices.SPEECH_THERAPY);
          }}
        >
          Speech Therapy
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.pastServices?.includes(MedicalServices.OCCUPATIONAL_THERAPY)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            togglePastService(MedicalServices.OCCUPATIONAL_THERAPY);
          }}
        >
          Occupational Therapy
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.pastServices?.includes(MedicalServices.PHYSICAL_THERAPY)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            togglePastService(MedicalServices.PHYSICAL_THERAPY);
          }}
        >
          Physical Therapy
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.pastServices?.includes(MedicalServices.PSYCHIATRIC_SERVICES)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            togglePastService(MedicalServices.PSYCHIATRIC_SERVICES);
          }}
        >
          Psychiatric Services
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.pastServices?.includes(MedicalServices.NEUROLOGICAL_SERVICES)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            togglePastService(MedicalServices.NEUROLOGICAL_SERVICES);
          }}
        >
          Neurological Services
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.pastServices?.includes(MedicalServices.SPECIAL_EDUCATION)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            togglePastService(MedicalServices.SPECIAL_EDUCATION);
          }}
        >
          Special Education/IEP
        </Button>
      </Row>

      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.otherPastServices !== undefined ? selectedToggle : unselectedToggle
          }
          onClick={() => {
            if (tempMedicalHistory.otherPastServices === undefined) {
              setTempMedicalHistory({
                ...tempMedicalHistory,
                otherPastServices: "",
              });
            } else {
              const newTempMedicalHistory = tempMedicalHistory;
              delete newTempMedicalHistory.otherPastServices;
              setTempMedicalHistory({ ...newTempMedicalHistory });
            }
          }}
        >
          Other
        </Button>
      </Row>
      {tempMedicalHistory.otherPastServices !== undefined && (
        <Row
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <TextArea
            autoSize
            defaultValue={tempMedicalHistory.otherPastServices}
            placeholder="Let us know what other services your child has previously received."
            style={{ marginBottom: 25, maxWidth: 400 }}
            onChange={(e) => {
              setTempMedicalHistory({
                ...tempMedicalHistory,
                otherPastServices: e.target.value,
              });
            }}
          />
        </Row>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={tempMedicalHistory.otherPastServices === ""}
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClient({
              id: client.id,
              medicalHistory: tempMedicalHistory,
            });
            setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
