import {
  BehaviorFrequency,
  BehaviorSeverity,
  IUpdateClientEndpointRequest,
} from "@finni-health/shared";
import { Button, Card, Radio, Row, Slider, Space, Typography } from "antd";
import React, { ReactNode, useContext } from "react";

import { frequencyMarks, selected, unselected } from "../../../consts";
import { getBehaviorFrequency } from "../../../helpers/get-behavior-frequency";
import { getBehaviorFrequencyValue } from "../../../helpers/get-behavior-frequency-value";
import { AuthContext } from "../../AuthProvider";
const { Title, Text } = Typography;

type IProps = {
  isMobile: boolean;
  tempBehaviors: any;
  setTempBehaviors: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterRunningAwayFrequency: React.FC<IProps> = ({
  isMobile,
  tempBehaviors,
  setTempBehaviors,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClient,
  backButton,
}: IProps) => {
  const { client } = useContext(AuthContext);

  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          Does your child run away from situations or areas?
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.runAway !== undefined ? selected : unselected}
          onClick={() => {
            setTempBehaviors({
              ...tempBehaviors,
              runAway: {
                ...tempBehaviors.runAway!,
                frequency: tempBehaviors.runAway?.frequency || BehaviorFrequency.MONTHLY,
              },
            });
          }}
        >
          Yes
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.runAway === undefined ? selected : unselected}
          onClick={() => {
            const newTempBehaviors = tempBehaviors;
            delete newTempBehaviors.runAway;
            setTempBehaviors({ ...newTempBehaviors });
          }}
        >
          No
        </Button>
      </Space>
      {tempBehaviors.runAway !== undefined && (
        <>
          Severity
          <Radio.Group
            style={{ marginBottom: 25 }}
            onChange={(e) => {
              setTempBehaviors({
                ...tempBehaviors,
                runAway: {
                  ...tempBehaviors.runAway!,
                  severity: e.target.value,
                },
              });
            }}
            value={tempBehaviors.runAway.severity}
          >
            <Space direction="vertical">
              <Radio value={BehaviorSeverity.LOW}>
                {`Moves more than 3 feet away from caregiver without consent, but responds to "stop"
                and "come here"`}
              </Radio>
              <Radio value={BehaviorSeverity.MEDIUM}>
                {`Moves more than 3 feet away from caregiver without consent, but remains within
                eyesight of caregiver. Does not respond to "stop and come here"`}
              </Radio>
              <Radio value={BehaviorSeverity.HIGH}>
                {`Moves to another room of the house and does not respond to "stop and come here"`}
              </Radio>
              <Radio value={BehaviorSeverity.EXTREME}>
                {`Results in child in an unknown location for greater than 30 seconds and does not
                respond when called`}
              </Radio>
            </Space>
          </Radio.Group>
          Frequency
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Slider
              tooltipVisible={false}
              max={4}
              marks={frequencyMarks}
              step={null}
              onAfterChange={(e) => {
                setTempBehaviors({
                  ...tempBehaviors,
                  runAway: {
                    ...tempBehaviors.runAway!,
                    frequency: getBehaviorFrequency(e),
                  },
                });
              }}
              style={{ width: "75%", marginBottom: 50, alignSelf: "center" }}
              value={getBehaviorFrequencyValue(tempBehaviors.runAway.frequency)}
            />
          </div>
          <Text>Does your child attempt to leave home?</Text>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 25,
            }}
          >
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.runAway?.leaveHome ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  runAway: {
                    ...tempBehaviors.runAway!,
                    leaveHome: true,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.runAway?.leaveHome === false ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  runAway: {
                    ...tempBehaviors.runAway!,
                    leaveHome: false,
                  },
                });
              }}
            >
              No
            </Button>
          </Space>
        </>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={
            tempBehaviors.runAway !== undefined &&
            (tempBehaviors.runAway?.leaveHome === undefined ||
              tempBehaviors.runAway?.severity === undefined)
          }
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClient({
              id: client.id,
              behaviors: tempBehaviors,
            });
            setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
