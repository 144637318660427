import { IUpdateClientEndpointRequest } from "@finni-health/shared";
import { Button, Card, Form, Input, Row, Space, Typography } from "antd";
import React, { ReactNode, useContext } from "react";

import { selected, unselected } from "../../../consts";
import { AuthContext } from "../../AuthProvider";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempMedicalHistory: any;
  setTempMedicalHistory: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClient: (req: IUpdateClientEndpointRequest) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterMedicalConditions: React.FC<IProps> = ({
  isMobile,
  tempMedicalHistory,
  setTempMedicalHistory,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClient,
  backButton,
}: IProps) => {
  const { client } = useContext(AuthContext);

  return (
    <Card
      title={
        <Title
          level={5}
          style={{
            fontWeight: 400,
            whiteSpace: "pre-line",
          }}
        >
          Does your child have other relevant diagnosis or medical conditions that you would like us
          to know about?
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempMedicalHistory.existingDiagnosis !== undefined ? selected : unselected}
          onClick={() => {
            setTempMedicalHistory({
              ...tempMedicalHistory,
              existingDiagnosis: "",
            });
          }}
        >
          Yes
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={tempMedicalHistory.existingDiagnosis === undefined ? selected : unselected}
          onClick={() => {
            const newTempMedicalHistory = tempMedicalHistory;
            delete newTempMedicalHistory.existingDiagnosis;
            setTempMedicalHistory({ ...newTempMedicalHistory });
          }}
        >
          No
        </Button>
      </Space>
      {tempMedicalHistory.existingDiagnosis !== undefined && (
        <Form layout="vertical" labelCol={{ span: 24 }}>
          <Form.Item
            label="Relevant Diagnosis"
            name="existingDiagnosis"
            rules={[
              {
                required: true,
                message: "Please enter your child's other relevant diagnoses",
              },
            ]}
            initialValue={tempMedicalHistory.existingDiagnosis}
            style={{
              maxWidth: 450,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 25,
            }}
          >
            <Input
              onChange={(e) => {
                setTempMedicalHistory({
                  ...tempMedicalHistory,
                  existingDiagnosis: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Form>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={tempMedicalHistory.existingDiagnosis === ""}
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClient({
              id: client.id,
              medicalHistory: tempMedicalHistory,
            });
            setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
