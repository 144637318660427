import { z } from "zod";

import { AuthMethod, BillingCode, PendingAuthorization } from "../types";

const baseAuthValidator = z.object({
  authNumber: z
    .string({
      message: "Authorization number is missing",
    })
    .min(1, { message: "Authorization number is missing" }),
  startDate: z.string({
    message: "Start date is missing",
  }),
  endDate: z.string({
    message: "End date is missing",
  }),
  authCodes: z
    .array(
      z
        .object({
          billingCode: z.nativeEnum(BillingCode, {
            errorMap: (_issue, ctx) => {
              return { message: `Invalid billing code: ${ctx.data}` };
            },
          }),
          units: z.any(),
        })
        .refine(
          (code): code is { billingCode: BillingCode; units: number } => {
            return code.units && code.units > 0;
          },
          (code) => ({
            message: `${code.billingCode} - units are required`,
          })
        )
    )
    .min(1, { message: "At least one procedure code is required" }),
});

// Validation run against pending authorizations when they are created
export const validatePendingAuthorizationForReview = (
  pendingAuthorization: PendingAuthorization
) => {
  const authMethod = pendingAuthorization.authMethod;
  if (!authMethod || authMethod === AuthMethod.SILNA) {
    return null;
  }

  // Manual auths from MC just need to include at least one document
  const manualAuthValidatorMc = z.object({
    documents: z.array(z.any()).min(1, { message: "At least one document is required" }),
  });

  const phoneAuthValidator = baseAuthValidator.extend({
    phoneReferenceNumber: z
      .string({
        required_error: "Phone reference number is missing",
        invalid_type_error: "Phone reference number is missing",
      })
      .min(1, { message: "Phone reference number is missing" }),
  });

  const tempAuthValidator = baseAuthValidator;
  let authValidator:
    | typeof phoneAuthValidator
    | typeof tempAuthValidator
    | typeof manualAuthValidatorMc;

  switch (authMethod) {
    case AuthMethod.MANUAL:
      authValidator = manualAuthValidatorMc;
      break;
    case AuthMethod.PHONE:
      authValidator = phoneAuthValidator;
      break;
    case AuthMethod.TEMP:
      authValidator = tempAuthValidator;
      break;
  }

  const result = authValidator.safeParse(pendingAuthorization);

  if (result.success) {
    return null;
  }
  return result.error.flatten().fieldErrors;
};

// Validation run against pending authorizations when they are approved from den.
export const validatePendingAuthorizationForApproval = (
  pendingAuthorization: PendingAuthorization
) => {
  const validator = baseAuthValidator.extend({
    payerId: z.string({
      message: "Payer ID is missing",
    }),
  });
  return validator.safeParse(pendingAuthorization);
};
