import { initContract } from "@ts-rest/core";

import { aiContract } from "./ai";
import { appointmentsContract } from "./appointments";
import { approvalsContract } from "./approvals";
import { authorizationsContract } from "./authorizations";
import { benefitsChecksContract } from "./benefitsChecks";
import { billingContract } from "./billing";
import { calendarContract } from "./calendar";
import { cigmmoContract } from "./cigmmo";
import { clientAvailabilitiesContract } from "./clientAvailabilities";
import { clientsContract } from "./clients";
import { clinicsContract } from "./clinics";
import { enrollmentsContract } from "./enrollments";
import { indirectsContract } from "./indirects";
import { insightsContract } from "./insights";
import { insurancePlansContract } from "./insurancePlans";
import { measurementsContract } from "./measurements";
import { notesContract } from "./notes";
import { pendingAuthorizationContract } from "./pendingAuthorizations";
import { programsContract } from "./programs";
import { pushNotificationsContract } from "./pushNotifications";
import { sessionContract } from "./sessions";
import { signableDocumentsContract } from "./signableDocuments";
import { userAvailabilitiesContract } from "./userAvailabilities";
import { usersContract } from "./users";

const c = initContract();

export const contract = c.router({
  ai: aiContract,
  approvals: approvalsContract,
  appointments: appointmentsContract,
  authorizations: authorizationsContract,
  billing: billingContract,
  calendar: calendarContract,
  clientAvailabilities: clientAvailabilitiesContract,
  insurancePlans: insurancePlansContract,
  clients: clientsContract,
  clinics: clinicsContract,
  enrollments: enrollmentsContract,
  indirects: indirectsContract,
  programs: programsContract,
  measurements: measurementsContract,
  pendingAuthorizations: pendingAuthorizationContract,
  sessions: sessionContract,
  userAvailabilities: userAvailabilitiesContract,
  users: usersContract,
  insights: insightsContract,
  benefitsChecks: benefitsChecksContract,
  pushNotifications: pushNotificationsContract,
  signableDocuments: signableDocumentsContract,
  notes: notesContract,
  cigmmo: cigmmoContract,
});
