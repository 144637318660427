import { z } from "zod";

import { contactSchema, PolicyHolderRelationship } from "./general";

export enum PayerLevel {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}

export const insurancePlanSchema = z.object({
  id: z.string(),
  payerId: z.string(),
  clientId: z.string(),
  clinicId: z.string(),
  silnaPatientPlanId: z.string().nullable(),
  payerLevel: z.nativeEnum(PayerLevel),
  memberNumber: z.string().nullable(),
  groupNumber: z.string().nullable(),
  policyHolder: contactSchema.nullable(),
  policyHolderRelationship: z.nativeEnum(PolicyHolderRelationship).nullable(),
  photoURLs: z.array(z.string()).nullable(),
  startMs: z.number(),
  endMs: z.number().nullable(),
  createdAt: z.number(),
  updatedAt: z.number().optional(),
  deletedAt: z.number().nullable(),
});

export type IInsurancePlan = z.infer<typeof insurancePlanSchema>;
