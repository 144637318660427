import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { enrollmentSchema, USStateCode } from "../types";

const c = initContract();
export const enrollmentsContract = c.router({
  syncAll: {
    method: "POST",
    path: "/enrollments/sync-all",
    body: c.noBody(),
    responses: {
      200: z.string(),
    },
  },
  getAllByState: {
    method: "GET",
    path: "/enrollments/get-all-by-state",
    query: z.object({
      state: z.nativeEnum(USStateCode),
    }),
    responses: {
      200: z.array(enrollmentSchema),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getAllByPayer: {
    method: "GET",
    path: "/enrollments/get-all-by-payer",
    query: z.object({
      payerDocId: z.string(),
    }),
    responses: {
      200: z.array(enrollmentSchema),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  linkEnrollmentsAndPayer: {
    method: "POST",
    path: "/enrollments/link-enrollments-and-payer",
    body: z.object({
      payerDocId: z.string(),
      plyPayerNames: z.array(z.string()),
      state: z.nativeEnum(USStateCode),
    }),
    responses: {
      200: z.string(),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
